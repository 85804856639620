import React, { useState, useEffect, useRef } from 'react';
import { format, set } from 'date-fns';
import { nl } from 'date-fns/locale';
import { AccessTime, AttachFile } from '@mui/icons-material';
import { Button, TextField, Checkbox, Chip, Tooltip } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Player } from '@lordicon/react';
import { timeSince } from '../GeneralFunctions';
import '../../assets/styles/details/TaskDetail.css';

// Import your Lordicon JSON files
const CHECK_CIRCLE = require('../../assets/system-regular-31-check.json');
const CLOSE_CIRCLE = require('../../assets/system-regular-29-cross.json');
const CANCEL = require('../../assets/system-regular-55-error.json');
const HOURGLASS = require('../../assets/system-regular-67-clock.json');
const HISTORY_ICON = require('../../assets/system-regular-141-history.json');
const ATTACHMENT_ICON = require('../../assets/system-regular-49-upload-file.json');
const COMMENT_ICON = require('../../assets/system-regular-47-chat.json');

const TaskDetail = ({ task, inCard, doClose }) => {
    const [comment, setComment] = useState('');
    const [newSubtask, setNewSubtask] = useState('');
    const accessToken = useSelector((state) => state.main.accessToken);
    const [currentTask, setCurrentTask] = useState(null);
    const tasks = useSelector((state) => state.tasks.tasks);
    const [hoveredAction, setHoveredAction] = useState(null);
    const [showHistory, setShowHistory] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [showAttachments, setShowAttachments] = useState(false);

    const handleMouseEnter = (action) => {
        setHoveredAction(action);
    };

    const handleMouseLeave = () => {
        setHoveredAction(null);
    };

    const toggleHistory = () => {
        setShowHistory(!showHistory);
        setShowComments(false);
        setShowAttachments(false);
        historyRef.current?.playFromBeginning();
    };

    const toggleComments = () => {
        setShowComments(!showComments);
        setShowHistory(false);
        setShowAttachments(false);
        commentRef.current?.playFromBeginning();
    };


    const toggleAttachments = () => {
        setShowAttachments(!showAttachments);
        setShowHistory(false);
        setShowComments(false);
        attachmentRef.current?.playFromBeginning();
    };


    // Refs for Lordicon players
    const checkCircleRef = useRef(null);
    const closeCircleRef = useRef(null);
    const cancelRef = useRef(null);
    const hourglassRef = useRef(null);
    const attachmentRef = useRef(null);
    const historyRef = useRef(null);
    const commentRef = useRef(null);

    useEffect(() => {
        if (tasks) {
            const latestTask = tasks?.find((t) => t.task_id === task.task_id);
            setCurrentTask(latestTask || task);
        }
        
    }, [task, tasks]);

    useEffect(() => {
        // Play the appropriate animation when the task state changes
        if (currentTask) {
            switch (currentTask.task_state) {
                case 'approved':
                    checkCircleRef.current?.playFromBeginning();
                    break;
                case 'rejected':
                    closeCircleRef.current?.playFromBeginning();
                    break;
                case 'cancelled':
                    cancelRef.current?.playFromBeginning();
                    break;
                default:
                    hourglassRef.current?.playFromBeginning();
            }
        }
    }, [currentTask?.task_state]);

    const onUpdateTask = (updatedTask) => {
        axios.put(`/api/tasks/${updatedTask.task_id}`, updatedTask, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
        setCurrentTask(updatedTask);
    };

    const handleApproval = (state) => {
        onUpdateTask({
            ...currentTask,
            task_state: state ? 'approved' : 'rejected',
            approvals: [
                ...currentTask.approvals,
                {
                    approved: state,
                    upn: 'Current User', // Replace with actual user info
                    timestamp: new Date().toISOString()
                }
            ]
        });

        if (doClose) doClose();
    };

    const handleCommentSubmit = () => {
        if (comment.trim()) {
            const newComment = {
                content: comment,
                created_at: new Date().toISOString(),
                upn: 'Current User', // Replace with actual user info
            };
            onUpdateTask({
                ...currentTask,
                comments: [...(currentTask.comments || []), newComment],
            });
            setComment('');
        }
    };

    const handleSubtaskSubmit = () => {
        if (newSubtask.trim()) {
            const newSubtaskItem = {
                id: Date.now(),
                content: newSubtask,
                completed: false,
            };
            onUpdateTask({
                ...currentTask,
                subtasks: [...(currentTask.subtasks || []), newSubtaskItem],
            });
            setNewSubtask('');
        }
    };

    const handleSubtaskToggle = (subtaskId) => {
        const updatedSubtasks = (currentTask.subtasks || []).map(subtask =>
            subtask.id === subtaskId ? { ...subtask, completed: !subtask.completed } : subtask
        );
        onUpdateTask({ ...currentTask, subtasks: updatedSubtasks });
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case 'approved':
                return <Player ref={checkCircleRef} icon={CHECK_CIRCLE} colorize="#4caf50" />;
            case 'rejected':
                return <Player ref={closeCircleRef} icon={CLOSE_CIRCLE} colorize="#f44336" />;
            case 'cancelled':
                return <Player ref={cancelRef} icon={CANCEL} colorize="#9e9e9e" />;
            default:
                return <Player ref={hourglassRef} icon={HOURGLASS} colorize="#2196f3" />;
        }
    };

    const getStatusChip = (status) => {
        switch (status) {
            case 'approved':
                return <Chip label={status} color="success" />;
            case 'rejected':
                return <Chip label={status} color="error" />;
            case 'cancelled':
                return <Chip label={status} color="default" />;
            default:
                return <Chip label={status} color="info" />;
        }
    }

    const getTypeChip = (type) => {
        switch (type) {
            case 'todo':
                return <Chip label={"te doen"} color="primary" />;
            case 'approval':
                return <Chip label={"goedkeuring"} color="info" />;
            default:
                return <Chip label={type} color="default" />;
        }
    }

    const getPriorityChip = (priority) => {
        switch (priority) {
            case 'low':
                return <Chip label={"laag"} color="default" />;
            case 'medium':
                return <Chip label={"gemiddeld"} color="warning" />;
            case 'high':
                return <Chip label={"hoog"} color="error" />;
            default:
                return <Chip label={priority} color="default" />;
        }
    }

    if (inCard && currentTask) {
        return (
            <div className="task-content">
                <div className="card task-info">
                    <span>{currentTask?.title}</span>
                    <span>{currentTask?.description}</span>
                </div>
                <div className="task-actions">
                    {(currentTask?.task_type === 'approval' && currentTask.task_state === 'active') && (
                        <React.Fragment>
                            <div
                                className="task-action approve"
                                onClick={() => handleApproval(true)}
                                onMouseEnter={() => handleMouseEnter('approve')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Player
                                    icon={CHECK_CIRCLE}
                                    colorize={hoveredAction === 'approve' ? "#00FF00" : "#ffffff"}
                                    size={24}
                                    style={{ marginRight: '8px' }}
                                    autoplay={hoveredAction === 'approve'}
                                /> Ja, ik ben akkoord
                            </div>
                            <div
                                className="task-action reject"
                                onClick={() => handleApproval(false)}
                                onMouseEnter={() => handleMouseEnter('disapprove')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Player
                                    icon={CLOSE_CIRCLE}
                                    colorize={hoveredAction === 'disapprove' ? "#d32f2f" : "#ffffff"}
                                    size={24}
                                    style={{ marginRight: '8px' }}
                                    autoplay={hoveredAction === 'disapprove'}
                                /> Nee, ik ben niet akkoord
                            </div>
                        </React.Fragment>
                    )}
                    {currentTask?.task_type === 'to_do' && (
                        <div>
                            {currentTask?.subtasks?.map((subtask, index) => (
                                <div key={index}>{subtask.title}</div>
                            ))}
                        </div>
                    )}
                    {/* <button onClick={toggleHistory}>History</button>
                    <button onClick={toggleComments}>Comments</button>
                    <button onClick={toggleAttachments}>Attachments</button> */}
                </div>
                <div className="task-info-bar">
                    <span>{getTypeChip(currentTask?.task_type)}</span>
                    <span>{getPriorityChip(currentTask?.priority)}</span>

                    {getStatusIcon(currentTask?.task_state)}
                    {getStatusChip(currentTask?.task_state)}

                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {currentTask && (
                <div className={`task-detail`}>
                    <div className="card task-header">
                        <h2>{currentTask.title}</h2>
                        <div className="task-status">
                            {getStatusIcon(currentTask.task_state)}
                            {getStatusChip(currentTask.task_state)}
                        </div>
                    </div>

                    <p className="card task-description">{currentTask.description}</p>

                    {currentTask.task_state === 'active' && currentTask.task_type == "approval" && (
                        <div className="task-actions">
                            <div
                                className="task-action approve"
                                onClick={() => handleApproval(true)}
                                onMouseEnter={() => handleMouseEnter('approve')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Player
                                    icon={CHECK_CIRCLE}
                                    colorize={hoveredAction === 'approve' ? "#00FF00" : "#ffffff"}
                                    size={24}
                                    style={{ marginRight: '8px' }}
                                    autoplay={hoveredAction === 'approve'}
                                /> Ja, ik ben akkoord
                            </div>
                            <div
                                className="task-action reject"
                                onClick={() => handleApproval(false)}
                                onMouseEnter={() => handleMouseEnter('disapprove')}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Player
                                    icon={CLOSE_CIRCLE}
                                    colorize={hoveredAction === 'disapprove' ? "#d32f2f" : "#ffffff"}
                                    size={24}
                                    style={{ marginRight: '8px' }}
                                    autoplay={hoveredAction === 'disapprove'}
                                /> Nee, ik ben niet akkoord
                            </div>
                        </div>
                    )}

                    {currentTask.task_state === 'active' && currentTask.task_type == "to_do" && (
                        <div className="task-subtasks">
                            {/* <h3><List /> Taken toevoegen</h3> */}
                            {currentTask.subtasks?.map(subtask => (
                                <div key={subtask.id} className="subtask-item">
                                    <Checkbox
                                        checked={subtask.completed}
                                        onChange={() => handleSubtaskToggle(subtask.id)}
                                        disabled={currentTask.task_state !== 'active'}
                                        sx={{
                                            'svg': {
                                                color: subtask.completed ? 'green' : '#CCC',
                                            },
                                        }}
                                    />
                                    <span className={subtask.completed ? 'completed' : ''}>{subtask.content}</span>
                                </div>
                            ))}
                            {currentTask.task_state === 'active' && (
                                <div className="add-subtask">
                                    <TextField
                                        value={newSubtask}
                                        onChange={(e) => setNewSubtask(e.target.value)}
                                        placeholder="Voeg een taak toe"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSubtaskSubmit();
                                            }
                                        }}
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            input: {
                                                color: '#ffffff', // Light text color
                                            },
                                            width: 'calc(100% - 100px)',
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderColor: 'transparent', // No border
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: 'transparent', // No border on hover
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: 'transparent', // No border when focused
                                                },
                                            },
                                        }}
                                    />
                                    {newSubtask && (<Button onClick={handleSubtaskSubmit} color="primary" variant="contained">Toevoegen</Button>)}
                                </div>
                            )}
                        </div>
                    )}

                    <div className="task-meta-and-buttons">
                        <div className="task-meta">
                            <Chip
                                icon={<AccessTime />}
                                label={`Due: ${format(new Date(currentTask.due_date), 'PPP')}`}
                                sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', color: '#fff' }}
                            />
                            {getPriorityChip(currentTask.priority)}
                            {getTypeChip(currentTask.task_type)}
                        </div>
                        <div className="task-buttons">
                            <Tooltip title="Toon bijlages">
                                <div className={`icon-button ${showAttachments ? "button-active" : ""}`} onClick={toggleAttachments}>
                                    <Player
                                        icon={ATTACHMENT_ICON}
                                        ref={attachmentRef}
                                        size={24}
                                        colorize={showAttachments ? "#D94773" : "#CCC"
                                        }
                                    />
                                    <span>{currentTask.attachments.length}</span>
                                    <span className={`button-label`}>bijlages</span>
                                </div>
                            </Tooltip>
                            <Tooltip title="Toon geschiedenis">
                                <div className={`icon-button ${showHistory ? "button-active" : ""}`} onClick={toggleHistory}>
                                    <Player
                                        icon={HISTORY_ICON}
                                        ref={historyRef}
                                        size={24}
                                        colorize={showHistory ? "#D94773" : "#CCC"}
                                    // onComplete={() => playerRef.current?.playFromBeginning()}
                                    />
                                    <span>{currentTask.history.length}</span>
                                    <span className={`button-label`}>geschiedenis</span>
                                </div>
                            </Tooltip>
                            <Tooltip title="Toon opmerkingen">
                                <div className={`icon-button ${showComments ? "button-active" : ""}`} onClick={toggleComments}>

                                    <Player
                                        icon={COMMENT_ICON}
                                        ref={commentRef}
                                        size={24}
                                        colorize={showComments ? "#D94773" : "#CCC"}
                                    />
                                    <span>{currentTask.comments.length}</span>
                                    <span className={`button-label`}>opmerkingen</span>
                                </div>
                            </Tooltip>
                        </div>
                    </div>

                    {showAttachments && (
                        <div className="task-attachments">
                            <h4>Taak bijlages</h4>
                            <ul>
                                {currentTask.attachments.map((attachment, index) => (
                                    <li key={index}>{attachment}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {showHistory && (
                        <div className="task-history">
                            <h4>Taak geschiedenis</h4>
                            {currentTask.history.map((event, index) => (
                                <div key={index} className="history-item">
                                    <Tooltip title={event.ai_analysis || ''}>
                                        <div>
                                            <p>{event.action}</p>
                                            <small>{format(new Date(event.timestamp), "d MMMM yyyy 'om' HH:mm", { locale: nl }) + " | " + timeSince(new Date(event.timestamp))}</small>
                                        </div>
                                    </Tooltip>
                                </div>
                            ))}
                        </div>
                    )}

                    {showComments && (
                        <div className="task-comments">
                            <h4>Taak opmerkingen</h4>
                            {currentTask.comments.map((comment, index) => (
                                <div key={index} className="comment">
                                    <p>{comment.content}</p>
                                    <small>{format(new Date(comment.created_at), "d MMMM yyyy 'om' HH:mm", { locale: nl }) + " | " + timeSince(new Date(comment.created_at))} | door {comment.upn}</small>
                                </div>
                            ))}
                            <div className="add-comment">
                                <TextField
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    placeholder="Voeg een opmerking toe"
                                    variant="outlined"
                                    multiline
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSubtaskSubmit();
                                        }
                                    }}
                                    size="small"
                                    sx={{
                                        input: {
                                            color: '#ffffff', // Light text color
                                        },
                                        width: 'calc(100% - 100px)',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: 'transparent', // No border
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'transparent', // No border on hover
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'transparent', // No border when focused
                                            },
                                        },
                                    }}
                                />
                                {comment && (<Button onClick={handleCommentSubmit} variant="contained">Opslaan</Button>)}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default TaskDetail;