import { call, put, take, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
    GET_KNOWLEDGE_REQUEST, getKnowledgeSuccess, getKnowledgeFailure,
    SAVE_KNOWLEDGE_REQUEST, saveKnowledgeSuccess, saveKnowledgeFailure,
    DELETE_KNOWLEDGE_REQUEST, deleteKnowledgeFailure, deleteKnowledgeSuccess
} from './actions';
import { getSocket } from '../../components/Socket';
import { all } from 'redux-saga/effects';

function createSocketChannel(socket) {
    return eventChannel(emit => {
        const handler = (data) => {
            emit(data);
        };
        socket.on('knowledge', handler);
        return () => {
            socket.off('knowledge', handler);
        };
    });
}

function* fetchKnowledgeSaga(action) {
    try {
        const socket = getSocket();
        if (!socket) {
            console.log('Socket is not initialized');
            return;
        }

        const socketChannel = yield call(createSocketChannel, socket);
        const organizationId = action.payload.organizationId; // Access organizationId from action payload
        socket.emit('get_knowledge', organizationId);

        while (true) {
            const knowledge = yield take(socketChannel);
            yield put(getKnowledgeSuccess(knowledge));
        }

    } catch (error) {
        yield put(getKnowledgeFailure(error.toString()));
    }
}

function* saveKnowledgeSaga(action) {
    try {
        const socket = getSocket();
        if (!socket) {
            console.log('Socket is not initialized');
            return;
        }

        socket.emit('save_knowledge', action.payload);
        yield put(saveKnowledgeSuccess());

    } catch (error) {
        yield put(saveKnowledgeFailure(error.toString()));
    }
}

function* deleteKnowledgeSaga(action) {
    try {
        const socket = getSocket();
        if (!socket) {
            console.log('Socket is not initialized');
            return;
        }

        socket.emit('delete_knowledge', action.payload);
        yield put(deleteKnowledgeSuccess());

    } catch (error) {
        yield put(deleteKnowledgeFailure(error.toString()));
    }
}


export function* watchKnowledgeSagas() {
    yield takeEvery(GET_KNOWLEDGE_REQUEST, fetchKnowledgeSaga);
    yield takeEvery(SAVE_KNOWLEDGE_REQUEST, saveKnowledgeSaga);
    yield takeEvery(DELETE_KNOWLEDGE_REQUEST, deleteKnowledgeSaga);
}