import { GET_AGENTS_REQUEST, GET_AGENTS_SUCCESS, GET_AGENTS_FAILURE, SAVE_AGENTS_REQUEST, SAVE_AGENTS_SUCCESS, SAVE_AGENTS_FAILURE } from './actions';

const initialState = {
    agents: [],
    loading: false,
    error: null,
};

export function agentsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_AGENTS_REQUEST:
            return { ...state, loading: true, error: null };
        case GET_AGENTS_SUCCESS:
            return { ...state, loading: false, agents: action.payload };
        case GET_AGENTS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case SAVE_AGENTS_REQUEST:
            return { ...state, loading: true, error: null };
        case SAVE_AGENTS_SUCCESS:
            return { ...state, loading: false };
        case SAVE_AGENTS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}