import { call, put, take, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { GET_AGENTS_REQUEST, getAgentsSuccess, getAgentsFailure, SAVE_AGENTS_REQUEST, saveAgentsSuccess, saveAgentsFailure } from './actions';
import { getSocket } from '../../components/Socket';
import { all } from 'redux-saga/effects';

function createSocketChannel(socket) {
    return eventChannel(emit => {
        const handler = (data) => {
            emit(data);
        };
        socket.on('agents', handler);
        return () => {
            socket.off('agents', handler);
        };
    });
}

function* fetchAgentsSaga() {
    try {
        const socket = getSocket();
        if (!socket) {
            console.log('Socket is not initialized');
            return;
        }

        const socketChannel = yield call(createSocketChannel, socket);
        socket.emit('get_agents');

        while (true) {
            const agents = yield take(socketChannel);
            yield put(getAgentsSuccess(agents));
        }

    } catch (error) {
        yield put(getAgentsFailure(error.toString()));
    }
}

function* saveAgentsSaga(action) {
    try {
        const socket = getSocket();
        if (!socket) {
            console.log('Socket is not initialized');
            return;
        }

        socket.emit('save_agents', action.payload);
        yield put(saveAgentsSuccess());

    } catch (error) {
        yield put(saveAgentsFailure(error.toString()));
    }
}

export function* watchFetchAgentsSaga() {
    yield takeEvery(GET_AGENTS_REQUEST, fetchAgentsSaga);
}

export function* watchSaveAgentsSaga() {
    yield takeEvery(SAVE_AGENTS_REQUEST, saveAgentsSaga);
}

export function* watchAgentsSagas() {
    yield all([
        watchFetchAgentsSaga(),
        watchSaveAgentsSaga()
    ]);
}