import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FlowManagement from './FlowManagement';
import FlowRunsOverview from './FlowRunsOverview';
import CodeEditor from './CodeEditor';

const Flows = () => {
    const [activeTab, setActiveTab] = useState('flows');
    const selectedFlow = useSelector(state => state.flows.selectedFlow);

    return (
        <div className="flow-management-main">
            <div className="tabs">
                <button
                    className={activeTab === 'flows' ? 'active' : ''}
                    onClick={() => setActiveTab('flows')}
                >
                    Flows
                </button>
                <button
                    className={activeTab === 'runs' ? 'active' : ''}
                    onClick={() => setActiveTab('runs')}
                >
                    Runs
                </button>
            </div>

            {activeTab === 'flows' && (
                <div className="flows-container">
                    <FlowManagement />
                    {selectedFlow && (
                        <div className="code-editor-container">
                            <h3>Flow Code</h3>
                            <CodeEditor
                                value={selectedFlow.code}
                                onChange={() => { }}
                                readOnly={true}
                            />
                        </div>
                    )}
                </div>
            )}

            {activeTab === 'runs' && <FlowRunsOverview />}
        </div>
    );
};

export default Flows;
