// reducer.js
import {
    GET_FLOWS_REQUEST, GET_FLOWS_SUCCESS, GET_FLOWS_FAILURE,
    SAVE_FLOW_REQUEST, SAVE_FLOW_SUCCESS, SAVE_FLOW_FAILURE,
    DELETE_FLOW_REQUEST, DELETE_FLOW_SUCCESS, DELETE_FLOW_FAILURE,
    RUN_FLOW_REQUEST, RUN_FLOW_SUCCESS, RUN_FLOW_FAILURE,
    DEBUG_FLOW_REQUEST, DEBUG_FLOW_SUCCESS, DEBUG_FLOW_FAILURE,
    GET_RUNS_REQUEST, GET_RUNS_SUCCESS, GET_RUNS_FAILURE
} from './actions';


const initialState = {
    flows: [],
    runs: [],
    loading: false,
    error: null,
};

const flowReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_FLOWS_REQUEST:
        case SAVE_FLOW_REQUEST:
        case DELETE_FLOW_REQUEST:
        case RUN_FLOW_REQUEST:
        case DEBUG_FLOW_REQUEST:
        case GET_RUNS_REQUEST:
            return { ...state, loading: true, error: null };
        case GET_FLOWS_SUCCESS:
            return { ...state, loading: false, flows: action.payload };
        case SAVE_FLOW_SUCCESS:
            return {
                ...state,
                loading: false,
                flows: state.flows.map(flow =>
                    flow._id === action.payload._id ? action.payload : flow
                )
            };
        case DELETE_FLOW_SUCCESS:
            return {
                ...state,
                loading: false,
                flows: state.flows.filter(flow => flow._id !== action.payload)
            };
        case RUN_FLOW_SUCCESS:
        case DEBUG_FLOW_SUCCESS:
            return { ...state, loading: false };
        case GET_RUNS_SUCCESS:
            return { ...state, loading: false, runs: action.payload };
        case GET_FLOWS_FAILURE:
        case SAVE_FLOW_FAILURE:
        case DELETE_FLOW_FAILURE:
        case RUN_FLOW_FAILURE:
        case DEBUG_FLOW_FAILURE:
        case GET_RUNS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default flowReducer;