import { takeEvery, put, select, call, delay, all, take, race, fork } from 'redux-saga/effects';
import { buffers, eventChannel } from 'redux-saga';
import { connectSocket, getSocket } from './Socket';
import { watchFetchTicketsSaga } from '../sagas/tickets/sagas';
import { getTicketsSuccess, getTicketsRequest } from '../sagas/tickets/actions';
import { getTask } from '../sagas/tasks/actions';
import { getActivity } from '../sagas/activities/actions';
import { watchAssistantsSagas } from '../sagas/assistants/sagas';
import { watchAgentsSagas } from '../sagas/agents/sagas';
import { watchKnowledgeSagas } from '../sagas/knowledge/sagas';
import { watchInstructionSagas } from '../sagas/instructions/sagas';
import { watchFetchActivitiesSaga } from '../sagas/activities/sagas';
import { watchFetchTasksSaga } from '../sagas/tasks/sagas';
import { debounce } from 'lodash';
import { flowSaga } from '../sagas/flows/saga';
import BrowserDetector from 'browser-dtector';
import { processAudioChunk, resetAudioPlayback, initAudioContext } from './AudioUtils';

let accessToken; // Variabele om de instance op te slaan
let userToken;
let reconnectAttempts = 0;
const maxReconnectAttempts = 10;
let reconnectDelay = 5000;

function* handleSetMSAL(action) {
  // Sla de instance op wanneer de SET_INSTANCE actie wordt gedispached
  //let response = action.payload;
  accessToken = action.payload.api;
  userToken = action.payload.graph;

  yield put({ type: 'SET_ACCESS_TOKEN', payload: accessToken });
}

// function* getThreadStatus() {
//   const thread_status = yield select(state => state.main.thread_status);
//   return thread_status;
// }

function* handleSocket() {
  if (!accessToken) {
    yield take('SET_MSAL');
  }

  // Get user profile from Microsoft Graph API
  const userProfile = yield call(getUserProfile, userToken);

  const socket = connectSocket(accessToken);
  const debouncedPut = debounce(yield put, 300);


  function reconnect() {
    if (socket.disconnected && reconnectAttempts < maxReconnectAttempts) {
      console.log('Attempting reconnect...');
      socket.connect();
      reconnectAttempts++;
    }
  }

  let deltaBuffer = [];
  let isBufferProcessingScheduled = false;

  const processDeltaBuffer = function* () {
    const accumulatedDelta = deltaBuffer.reduce((acc, current) => {
      // Accumulate deltas here. This is a simplistic example.
      // You might need a more complex logic depending on your delta structure.
      return acc + current;
    }, "");

    yield put({ type: 'SET_DELTA', payload: accumulatedDelta });

    // Reset buffer and flag after processing
    //deltaBuffer = [];
    isBufferProcessingScheduled = false;
  };

  const socketChannel = eventChannel(emitter => {
    socket.on('connect', () => {
      console.log('Socket connected');
      emitter({ type: 'SET_CONNECTION_STATUS', payload: 'connected' });

      console.log('User profile:', userProfile);

      // Send user profile to server through socket
      socket.emit('set_user_profile', userProfile);

      socket.emit('get_ai_options');
      socket.emit('get_vapid_public_key')
      //emitter({ type: 'SET_SOCKET', payload: socket }); // Emitteer de SET_SOCKET actie
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
      emitter({ type: 'SET_CONNECTION_STATUS', payload: 'disconnect' });

      // Listen for the focus event on the window
      window.addEventListener('focus', reconnect);

      if (reconnectAttempts < maxReconnectAttempts) {
        setTimeout(() => {
          console.log('Attempting fallback reconnect...');
          socket.connect();
          reconnectAttempts++;
          //reconnectDelay *= 2; // Verdubbel de vertraging voor de volgende poging
        }, reconnectDelay);
      } else {
        console.log('Max reconnect attempts reached. Not attempting to reconnect.');
      }
    });

    socket.on('connect_error', (error) => {
      console.log('Socket error');
      console.log(error);
      emitter({ type: 'SET_CONNECTION_STATUS', payload: 'connect_error' });
    });

    socket.on('connect_timeout', () => {
      console.log('Socket error');
      emitter({ type: 'SET_CONNECTION_STATUS', payload: 'connect_timeout' });
    });

    socket.on('error', (error) => {
      console.log('Socket error');


      if (error == 'invalid token') {
        console.log('Invalid token received');
        emitter({ type: 'SET_CONNECTION_STATUS', payload: 'invalid_token' });
      } else {
        console.log('Socket error');
        emitter({ type: 'SET_CONNECTION_STATUS', payload: 'error' });
      }

      //emitter({ type: 'SET_CONNECTION_STATUS', payload: 'connecting_error' });
    });

    socket.on('reconnect_attempt', () => {
      emitter({ type: 'SET_CONNECTION_STATUS', payload: 'reconnect_attempt' });
    });

    socket.on('reconnecting', (attemptNumber) => {
      emitter({ type: 'SET_CONNECTION_STATUS', payload: `reconnecting` });
    });

    socket.on('vapid_public_key', (data) => {
      console.log('Vapid public key received');
      emitter({ type: 'SET_VAPID_PUBLIC_KEY', payload: data });
      emitter({ type: 'SEND_SUBSCRIPTION' });

    });

    socket.on('notification', (data) => {
      console.log('Notification received: ', data);
      let notification = { type: data.type, payload: data };
      emitter({ type: 'NOTIFICATION_RECEIVED', payload: notification });
    });

    socket.on('messages', (data) => {
      console.log('Messages received');

      data = JSON.parse(data);
      // Convert date strings to Date objects and sort
      data.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });

      emitter({ type: 'SET_MESSAGES', payload: data });
    });

    socket.on('audio', (audio) => {
      // Convert hex string back to ArrayBuffer

      audio = JSON.parse(audio);

      emitter({ type: 'AUDIO_MESSAGE_RECEIVED', payload: audio });

      // if (audio.type === 'audio_chunk') {
      //   const audioArrayBuffer = new Uint8Array(audioData.data.match(/.{1,2}/g).map(byte => parseInt(byte, 16))).buffer;
      //   yield call(playAudioChunk, audioArrayBuffer);
      // }

      // if (audio.type === 'audio_end') {
      //   yield call(resetAudioPlayback);
      // }
    });

    socket.on('delta', (data) => {
      data = JSON.parse(data);



      if (data.status == "delta_start") {
        //emitter({ type: 'SET_THREAD_STATUS', payload: "starting" });
        deltaBuffer = [];
        emitter({ type: 'SET_DELTA', payload: "" });
      }

      if (data.status == "delta_add") {
        //emitter({ type: 'ADD_DELTA', payload: data.delta });

        //console.log(data.delta);

        // Add the incoming delta to the buffer
        deltaBuffer.push(data.delta);

        if (!isBufferProcessingScheduled) {
          isBufferProcessingScheduled = true;

          // Schedule buffer processing after a delay (e.g., 300ms)
          setTimeout(() => {
            emitter({ type: 'PROCESS_DELTA_BUFFER' });
          }, 25);
        }
      }

      if (data.status == "delta_end") {
        emitter({ type: 'SET_THREAD_STATUS', payload: "active" });
        isBufferProcessingScheduled = true;
        //emitter({ type: 'PROCESS_DELTA_BUFFER' });
        emitter({ type: 'END_DELTA' });
        //emitter({ type: 'SET_DELTA', payload: "" });
      }

    });


    socket.on('run', (data) => {
      console.log('Run received');

      // add new run to the list
      if (typeof data === 'string')
        data = JSON.parse(data);

      emitter({ type: 'ADD_RUN', payload: data });
    });

    socket.on('task', (data) => {
      console.log('Task received');

      if (typeof data === 'string')
        data = JSON.parse(data);
      if (typeof data.thread_data === 'string')
        data.thread_data = JSON.parse(data.thread_data);

      emitter({ type: 'ADD_TASK', payload: data });
    });

    socket.on('running_threads', (data) => {
      console.log('running threads received');

      if (typeof data === 'string')
        data = JSON.parse(data);

      if (data && data.find(thread => thread.is_active === true)) {
        emitter({ type: 'SET_THREAD_STATUS', payload: "active" });
        console.log('setting thread from running: ' + data.find(thread => thread.is_active === true).thread_id);
        emitter({ type: 'SET_THREAD', payload: data.find(thread => thread.is_active === true).thread_id });
      }

      emitter({ type: 'SET_RUNNING_THREADS', payload: data });
    });


    return () => {
      console.log('Socket closed!');
      socket.off('connect');
      socket.off('disconnect');
      socket.off('connect_error');
      socket.off('connect_timeout');
      socket.off('error');
      socket.off('reconnect_attempt');
      socket.off('reconnecting');
      socket.disconnect();
    };
  }, buffers.expanding(100));



  while (true) {
    const payload = yield take(socketChannel);

    if (payload) {

      if (payload.type === 'ADD_TASK') {

        const current_state = yield select(state => state.main.status);
        const data = payload.payload;

        if (data?.status) {
          if (data.status == 'in_progress' || data.status == "continuing" || data.status == "run_started") {
            console.log('setting thread: ' + data.thread_id);
            yield put({ type: 'SET_THREAD', payload: data.thread_id });
            yield put({ type: 'SET_THREAD_STATUS', payload: "running" });
            // if (data.status == "continuing") {
            //   yield put({ type: 'SET_DELTA', payload: "" });
            // } else {
            yield put({ type: 'SET_DELTA', payload: "" });
            // }
            console.log('thread started')

          } else if (data?.is_active === false) {
            console.log("thread is not active");
            yield put({ type: 'SET_THREAD_STATUS', payload: "idle" });

          } else if (current_state != "idle") {
            yield put({ type: 'SET_THREAD_STATUS', payload: "active" });
            console.log('setting thread: ' + data.thread_id);
            yield put({ type: 'SET_THREAD', payload: data.thread_id });

          }

          if (data.thread_data) {
            let new_running_threads = yield updateRunningThreads(data.thread_data);
            yield put({ type: 'SET_RUNNING_THREADS', payload: new_running_threads });
          }
        }

        if (data?.status == "routing" && current_state == "running") {
          let new_running_threads = yield updateRunningThreads(data);
          yield put({ type: 'SET_RUNNING_THREADS', payload: new_running_threads });
        }

        if (data?.status == 'run_completed') {
          try {
            let thread_data = data.thread_data;
            if (typeof thread_data === 'string') {
              thread_data = JSON.parse(thread_data);
            }

            if (thread_data.is_active) {
              yield put({ type: 'SET_THREAD_STATUS', payload: "active" });
            }

            let new_running_threads = yield updateRunningThreads(thread_data);
            yield put({ type: 'SET_RUNNING_THREADS', payload: new_running_threads });

          } catch (error) {
            console.log('Error parsing thread data:', error);
          }
        }

        if (data?.status == 'stopped') {
          yield put({ type: 'SET_THREAD_STATUS', payload: "idle" });
          yield put({ type: 'SET_DELTA', payload: "" });
          yield put({ type: 'SET_THREAD', payload: null });
          yield put({ type: 'SET_LAST_TASK', payload: null });
          yield put({ type: 'SET_TASKS', payload: [] });
          deltaBuffer = [];
        }
      }


      if (payload.type === 'UPDATE_RUNNING_THREADS') {

      }

      else if (payload.type === 'ADD_DELTA') {

        const thread_status = yield select(state => state.main.status);
        if (thread_status == "starting") {
          yield put({ type: 'SET_THREAD_STATUS', payload: "running" });
          yield put({ type: 'SET_DELTA', payload: payload.payload });
        } else {
          const delta = yield select(state => state.main.delta);
          yield put({ type: 'SET_DELTA', payload: delta + payload.payload });

        }

        // console.log(payload.payload);
        //console.log('delta received' + payload.payload);
        //debouncedPut({ type: 'SET_DELTA', payload: delta + payload.payload });

      } else if (payload.type === 'PROCESS_DELTA_BUFFER') {
        yield* processDeltaBuffer();

      } else if (payload.type === 'END_DELTA') {
        yield* processDeltaBuffer();
        yield put({ type: 'END_DELTA' });

      } else {
        yield put(payload);
      }
    } else {
      console.log('Socket timeout');
      socketChannel.close();
    }
  }
}

function* updateRunningThreads(thread_data) {
  let running_threads = yield select(state => state.main.running_threads);
  let new_running_threads;

  if (running_threads && thread_data) {
    if (!thread_data.messages) thread_data.messages = [];
    const index = running_threads.findIndex(thread => thread.thread_id === thread_data?.thread_id);
    if (index !== -1) {
      new_running_threads = [...running_threads];
      new_running_threads[index] = { ...thread_data }
    } else {
      new_running_threads = [...running_threads, thread_data];
    }

  } else {
    new_running_threads = [thread_data];
  }

  return new_running_threads;
}


// Function to get user profile from Microsoft Graph API
function* getUserProfile(token) {
  try {
    const response = yield fetch('https://graph.microsoft.com/v1.0/me', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const data = yield response.json();
    if (data['@odata.context']) {
      delete data['@odata.context'];
    }
    return data;
  } catch (error) {
    console.error('Failed to get user profile:', error);
    return null;
  }
}

function* handleSetThread(action) {
  console.log('SET_THREAD ID: ', action?.payload);
  const state = yield select();

  //check current thread id and check if it is the same as the new thread id  
  const current_thread = state.main.thread;
  if (current_thread === action.payload) return;

  // get the thread from state
  const thread = state.main.running_threads.find(thread => thread.thread_id === action.payload);

  if (thread) {
    yield put({ type: 'SET_THREAD_STATUS', payload: "active" });
    yield put({ type: 'SET_THREAD', payload: action.payload });

    if (thread.tasks?.length > 0) {
      yield put({ type: 'SET_TASKS', payload: thread.tasks });
      yield put({ type: 'SET_LAST_TASK', payload: thread.tasks[thread.tasks?.length - 1] });
    }
  }


}

function* handleStopChat(message) {
  console.log('STOP_CHAT: ', message?.payload)
  yield put({ type: 'SET_THREAD_STATUS', payload: "idle" });
  yield put({ type: 'SET_DELTA', payload: '' });
  console.log('setting thread to null');
  yield put({ type: 'SET_THREAD', payload: null });
  yield put({ type: 'SET_LAST_TASK', payload: null });
  yield put({ type: 'SET_TASKS', payload: [] });
}

function* handleClearChat(message) {
  const socket = getSocket();
  if (!socket) {
    console.log('Socket is not initialized');
    return;
  }

  console.log('CLEAR_CHAT: ', message?.payload)

  yield put({ type: 'SET_TASKS', payload: [] });
  console.log('setting thread to null');
  yield put({ type: 'SET_THREAD', payload: null });
  yield put({ type: 'SET_DELTA', payload: '' });
  yield put({ type: 'SET_THREAD_STATUS', payload: "idle" });
  yield put({ type: 'SET_LAST_TASK', payload: null });

  socket.emit('clear_chat', message?.payload);
}

function* handleSendSubscription() {
  const socket = getSocket();
  if (!socket) {
    console.log('Socket is not initialized');
    return;
  }

  const vapidPublicKey = yield select(state => state.main.vapidPublicKey);
  if (!vapidPublicKey) {
    console.log('Vapid public key is not initialized');
    return;
  }

  // Generate a unique device id if it doesn't exist
  let deviceId = localStorage.getItem('device_id');
  if (!deviceId) {
    deviceId = 'device_' + Math.random().toString(36).substr(2, 9);
    localStorage.setItem('device_id', deviceId);
  }

  console.log('Device ID:', deviceId)

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/serviceWorker.js')
      .then(() => {
        console.log('Service Worker registered');
        return navigator.serviceWorker.ready;
      })
      .then(registration => {
        // Check if a subscription already exists
        return registration.pushManager.getSubscription()
          .then(existingSubscription => {
            if (existingSubscription) {
              // If a subscription exists, log it and return it
              console.log('Existing subscription found:', existingSubscription);
              let data = JSON.parse(JSON.stringify(existingSubscription));
              data.device_id = deviceId;
              // Send the new object to the server
              socket.emit('set_subscription', data);
              return existingSubscription;

            } else {
              // If no subscription exists, create a new one
              console.log('No existing subscription found. Subscribing...');
              return registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
              })
                .then(newSubscription => {
                  console.log('New subscription:', newSubscription);
                  // Create a new object with all properties of the subscription
                  let data = JSON.parse(JSON.stringify(newSubscription));
                  // Add the device id to the new object
                  data.device_id = deviceId;
                  // Send the new object to the server
                  socket.emit('set_subscription', data);
                  return newSubscription;
                });
            }
          });
      })
      .catch(err => {
        console.log('Service Worker registration failed: ', err);
      });
  }
}

function* handleNotificationReceived(action) {
  // Handle the notification...

  console.log('Notification received:', action.payload);

  // Get the latest notification ID and date from localStorage
  const latestNotificationId = localStorage.getItem('latestNotificationId');
  const latestNotificationDate = localStorage.getItem('latestNotificationDate');

  // Check if the received notification is new
  if (action.payload.id !== latestNotificationId || action.payload.date !== latestNotificationDate) {
    // If it's new, store the new ID and date in localStorage
    localStorage.setItem('latestNotificationId', action.payload.id);
    localStorage.setItem('latestNotificationDate', action.payload.date);

    if (action.payload.type === 'message') {
      // If the notification is a message, dispatch the NEW_MESSAGE_RECEIVED action
      yield put({ type: 'NEW_MESSAGE_RECEIVED', payload: action.payload });

      let messages = yield select(state => state.main.messages);
      if (!messages) messages = [];
      messages = [...messages, action.payload];

      yield put({ type: 'SET_MESSAGES', payload: messages });
    }

    if (action.payload.type === 'activity') {
      yield put({ type: 'NOTIFICATION_RECEIVED', payload: action.payload });
      yield put(getActivity(action.payload.activity_id));

      // let messages = yield select(state => state.main.messages);
      // if (!messages) messages = [];
      // messages = [...messages, action.payload];

      //yield put({ type: 'SET_MESSAGES', payload: messages });

      if (action.payload.payload_type == "ticket") {
        yield put(getTicketsRequest());
      }

      if (action.payload.payload_type == "task") {
        yield put(getTask(action.payload.object_id));
      }
    }

    //when type is notification, dispatch the NOTIFICATION_RECEIVED action
    if (action.payload.type === 'notification') {
      yield put({ type: 'NOTIFICATION_RECEIVED', payload: action.payload });

      let messages = yield select(state => state.main.messages);
      if (!messages) messages = [];
      messages = [...messages, action.payload];

      yield put({ type: 'SET_MESSAGES', payload: messages });
    }


    // //when type is a ticket notification, dispatch the TICKET_NOTIFICATION_RECEIVED action
    // if (action.payload.payload_type === 'ticket') {
    //   yield put({ type: 'TICKET_NOTIFICATION_RECEIVED', payload: action.payload });

    //   const ticket = action.payload.payload;

    //   if (ticket && ticket.id) {
    //     let tickets = yield select(state => state.tickets.tickets);
    //     if (!tickets) tickets = [];
    //     tickets = [...tickets, ticket];
    //     yield put(getTicketsSuccess(tickets));

    //     yield put({ type: 'NOTIFICATION_RECEIVED', payload: { content: action.payload.title, action: action.payload.action, payload: ticket.id } });


    //   }
    // }

    // //when type is a ticket notification, dispatch the TICKET_NOTIFICATION_RECEIVED action
    // if (action.payload.payload_type === 'task') {
    //   //yield put({ type: 'TASK_NOTIFICATION_RECEIVED', payload: action.payload });

    //   yield put(getTask(action.payload.object_id));


    //   // if (task && task.id) {
    //   //   let tasks = yield select(state => state.tasks.tasks);
    //   //   if (!tasks) tasks = [];
    //   //   tasks = [...tasks, task];
    //   //   yield put(getTicketsSuccess(tasks));

    //   //   yield put({ type: 'NOTIFICATION_RECEIVED', payload: { content: action.payload.title, action: action.payload.action, payload: ticket.id } });


    //   // }
    // }



    // if(action.payload)

    // // And dispatch the NEW_MESSAGE_RECEIVED action
    // yield put({ type: 'NEW_MESSAGE_RECEIVED', payload: action.payload });
  }
}


function* handleFetchMessages() {
  const socket = getSocket();
  if (!socket) {
    console.log('Socket is not initialized');
    return;
  }

  socket.emit('fetch_messages');
}


function* handleSendMessage(action) {
  const socket = getSocket();
  if (!socket) {
    console.log('Socket is not initialized');
    return;
  }

  console.log('SEND_MESSAGE: ', action.payload)
  let deviceId = localStorage.getItem('device_id');

  if (!deviceId) {
    deviceId = 'device_' + Math.random().toString(36).substr(2, 9);
    localStorage.setItem('device_id', deviceId);
  }

  if (Array.isArray(action.payload)) {
    socket.emit(action.payload[0], action.payload[1], deviceId);

  } else {


    const thread_status = yield select(state => state.main.status);

    if (thread_status == "idle" || thread_status == "active" || action?.payload?.override) {

      const thread_id = yield select(state => state.main.thread);

      if (!thread_id) {
        yield put({ type: 'SET_THREAD_STATUS', payload: "starting" });

      } else {
        const running_threads = yield select(state => state.main.running_threads);
        const updatedThreads = running_threads.map(t =>
          t.thread_id === thread_id ? { ...t, status: "running" } : t
        );

        yield put({ type: 'SET_RUNNING_THREADS', payload: updatedThreads })
        yield put({ type: 'SET_THREAD_STATUS', payload: "starting" });
      }

      // const activity = yield select(state => state.main.activity);
      // if (activity && activity._id && activity._id != "new" && action.payload?.message?.indexOf(activity._id) == -1) {
      //   //let payload = JSON.parse(action.payload);
      //   action.payload["message"] += ` frontend: user has activity: ${activity._id} open and visible`;
      //   //action.payload = JSON.stringify(payload);
      // }

      // gather client info; which browser, which version, which OS, on mobile or desktop
      const browser = new BrowserDetector(window.navigator.userAgent);
      const clientInfo = browser.parseUserAgent();

      socket.emit('chat_message', { from_dashboard: true, ...action.payload }, deviceId, clientInfo);
    } else {
      console.log('Thread is already running');
    }
  }
}



function* handleSendMessageReply(action) {
  const socket = getSocket();
  if (!socket) {
    console.log('Socket is not initialized');
    return;
  }

  let messages = yield select(state => state.main.messages);
  if (!messages) messages = [];

  //update the message status to replied
  const updatedMessages = messages.map(m =>
    m.id === action.payload.id ? { ...m, status: 'done' } : m
  );

  yield put({ type: 'SET_MESSAGES', payload: updatedMessages });

  socket.emit('message_reply', action.payload);
}

function* handleSetTask(action) {
  if (!action) return;

  // Voeg de nieuwe taak toe aan de lijst
  //yield put({ type: 'ADD_TASK', payload: action.payload });

  // Selecteer de huidige staat
  const state = yield select();

  // Haal de laatste taak uit de lijst
  const lastTask = state.main.tasks[state.main.tasks.length - 1];

  // Sla de laatste taak op in de staat
  yield put({ type: 'SET_LAST_TASK', payload: lastTask });

}

// In your saga
function* handleAudioMessage(action) {
  const audioData = action.payload;
  if (audioData.type === 'audio_chunk') {
    try {
      console.log('Received audio chunk, size:', audioData.data.length, 'sequence number:', audioData.sequence_number);
      const audioArrayBuffer = new Uint8Array(audioData.data.match(/.{1,2}/g).map(byte => parseInt(byte, 16))).buffer;
      yield call(processAudioChunk, audioArrayBuffer, audioData.sequence_number, audioData.sample_rate);
    } catch (error) {
      console.error('Error processing audio chunk:', error);
    }
  } else if (audioData.type === 'audio_end') {
    console.log('Audio stream ended');
    //yield call(resetAudioPlayback);
  }
}


// Monitor and log buffer state
// setInterval(() => {
//   console.log(`Buffer state: ${audioBufferQueue.length} chunks, next scheduled time: ${nextScheduledTime.toFixed(2)}s, current time: ${audioContext ? audioContext.currentTime.toFixed(2) : 'N/A'}s`);
// }, 1000);

function* rootSaga() {
  yield all([
    takeEvery('SET_MSAL', handleSetMSAL),
    handleSocket(),
    handleSetTask(),
    takeEvery('ADD_TASK', handleSetTask),
    takeEvery('SEND_MESSAGE', handleSendMessage),
    takeEvery('SEND_MESSAGE_REPLY', handleSendMessageReply),
    takeEvery('CLEAR_CHAT', handleClearChat),
    takeEvery('STOP_CHAT', handleStopChat),
    takeEvery('FETCH_MESSAGES', handleFetchMessages),
    takeEvery('SEND_SUBSCRIPTION', handleSendSubscription),
    takeEvery('SET_RUNNING_THREAD', handleSetThread),
    takeEvery('NOTIFICATION_RECEIVED', handleNotificationReceived),
    fork(watchFetchTicketsSaga),
    fork(watchAssistantsSagas),
    fork(watchAgentsSagas),
    fork(watchKnowledgeSagas),
    fork(watchInstructionSagas),
    fork(watchFetchActivitiesSaga),
    fork(watchFetchTasksSaga),
    fork(flowSaga),
    takeEvery('AUDIO_MESSAGE_RECEIVED', handleAudioMessage)
  ]);
}

export default rootSaga;


function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  console.log('outputArray: ', outputArray);
  return outputArray;
}