import {
  GET_ASSISTANTS_REQUEST, GET_ASSISTANTS_SUCCESS, GET_ASSISTANTS_FAILURE,
  SAVE_ASSISTANTS_FAILURE, SAVE_ASSISTANTS_REQUEST, SAVE_ASSISTANTS_SUCCESS,
  DELETE_ASSISTANT_REQUEST, DELETE_ASSISTANT_SUCCESS, DELETE_ASSISTANT_FAILURE
} from './actions';

const initialState = {
  assistants: [],
  loading: false,
  error: null,
};

export function assistantsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ASSISTANTS_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_ASSISTANTS_SUCCESS:
      return { ...state, loading: false, assistants: action.payload };
    case GET_ASSISTANTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SAVE_ASSISTANTS_REQUEST:
      return { ...state, loading: true, error: null };
    case SAVE_ASSISTANTS_SUCCESS:
      return { ...state, loading: false };
    case SAVE_ASSISTANTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case DELETE_ASSISTANT_REQUEST:
      return { ...state, loading: true, error: null };
    case DELETE_ASSISTANT_SUCCESS:
      return { ...state, loading: false };
    case DELETE_ASSISTANT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}