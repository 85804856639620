// reducer.js
const initialState = {
    tasks: [],
    connectionStatus: null,
    thread: null,
    running_threads: [],
    lastTask: null,
    preOptions: null,
    socket: null,
    vapidPublicKey: null,
    messages: [],
    message: null,
    status: "idle",
    delta: null,
    notifications: [],
    ticketNotifications: [],
    runs: [],
    activity: null,
    accessToken: null,
    delta_end: false
};

function reducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_CONNECTION_STATUS':
            // Sla de payload van de actie op in de staat
            return { ...state, connectionStatus: action.payload };

        case 'SET_TASKS':
            return { ...state, tasks: action.payload };

        case 'RESET_TASKS':
            return { ...state, tasks: [] };

        case 'SET_SOCKET':
            return { ...state, socket: action.payload };

        case 'ADD_TASK':
            // Voeg de nieuwe taak toe aan de lijst
            return { ...state, tasks: [...state.tasks, action.payload] };

        case 'ADD_RUN':
            // Voeg de nieuwe run toe aan de lijst
            return { ...state, runs: [...state.runs, action.payload] };

        case 'RESET_RUNS':
            return { ...state, runs: [] };

        case 'SET_DELTA':
            return { ...state, delta: action.payload, delta_end: false };

        case 'END_DELTA':
            return { ...state, delta_end: true };

        case 'SET_ACTIVITY':
            return { ...state, activity: action.payload };

        case 'SET_LAST_TASK':
            return { ...state, lastTask: action.payload };

        case 'SET_THREAD':
            return { ...state, thread: action.payload };

        case 'SET_RUNNING_THREADS':
            return { ...state, running_threads: action.payload };

        case 'SET_THREAD_STATUS':
            return { ...state, status: action.payload };

        case 'SET_PRE_OPTIONS':
            return { ...state, preOptions: action.payload };

        case 'SET_VAPID_PUBLIC_KEY':
            return { ...state, vapidPublicKey: action.payload };

        case 'SET_MESSAGES':
            return { ...state, messages: action.payload };

        case 'NEW_MESSAGE_RECEIVED':
            return { ...state, message: action.payload };

        case 'NOTIFICATION_RECEIVED':
            return { ...state, notifications: [...state.notifications, action.payload] };

        case 'TICKET_NOTIFICATION_RECEIVED':
            return { ...state, ticketNotifications: [...state.ticketNotifications, action.payload] };

        case 'CLEAR_NOTIFICATION':
            return { ...state, notifications: state.notifications.filter(notification => notification.id !== action.payload) };

        case 'CLEAR_TICKET_NOTIFICATION':
            return { ...state, ticketNotifications: state.ticketNotifications.filter(notification => notification.id !== action.payload) };

        case 'SET_ACCESS_TOKEN':
            return { ...state, accessToken: action.payload };

        default:
            return state;
    }
}

export default reducer;