// TicketCard.js
import React from 'react';
import '../../assets/styles/cards/TicketCard.css';
import { timeSince } from '../GeneralFunctions';

function TicketCard({ ticketInfo, isSelected }) {
  return (
    <div className={`card clickable ${isSelected ? 'selected' : ''}`}>
      <div className="ticket-info">
        <h2>{ticketInfo.short_title}</h2>
        <span className="date">{timeSince(new Date(ticketInfo.modificationDate))}</span>
        <span className={`action_status ${ticketInfo.status?.isImportant ? 'is-important' : ''}`}>
          {ticketInfo.action_status ? ticketInfo.action_status : ticketInfo.status?.content}
        </span>
      </div>

      <div className="ticket-footer-container">
        <ul>
          <li className={ticketInfo.priority?.isImportant ? 'is-important' : ''} title="Prioriteit">
            {ticketInfo.priority?.content}
          </li>
          {/* <li title="Verwachte duur">{ticketInfo.duration}</li>
          <li className={ticketInfo.priority?.isImportant && 'is-important'} title="Prioriteit">
            {ticketInfo.priority?.content}
          </li>
          <li className={ticketInfo.impact.isImportant && 'is-important'} title="Impact">
            {ticketInfo.impact.content}
          </li> */}
          <li title="Type oproep">{ticketInfo.type.call}</li>
          <li title="meldingnummer">#{ticketInfo.number}</li>
        </ul>
      </div>
    </div>
  );
}
export default TicketCard;
