import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTicketsRequest } from '../sagas/tickets/actions';
import { getActivitiesRequest } from '../sagas/activities/actions';
import '../assets/styles/Tickets.css';
import TicketCard from './cards/TicketCard';
import TicketDetail from './details/TicketDetail';
import { useNavigate, useParams } from 'react-router-dom';

const Tickets = ({ socket }) => {
  const { ticket_id } = useParams();
  const activities = useSelector(state => state.activities.activities);
  const navigate = useNavigate();
  const [selectedTicket, setSelectedTicket] = useState(null);
  const dispatch = useDispatch();
  const connectionStatus = useSelector((state) => state.main.connectionStatus);
  const tickets = useSelector((state) => state.tickets.tickets);
  const loading = useSelector((state) => state.loading);
  const [fadeClass, setFadeClass] = useState('');
  const [displayedTicket, setDisplayedTicket] = useState(null);
  const [clickedTicket, setClickedTicket] = useState(null);

  useEffect(() => {
    if (connectionStatus === 'connected') {
      dispatch(getTicketsRequest());
      dispatch(getActivitiesRequest());
    }
  }, [dispatch, connectionStatus]);

  useEffect(() => {
    if (selectedTicket) {
      setFadeClass('fade-in');
    } else {
      setFadeClass('fade-out');
    }
  }, [selectedTicket]);

  const onTicketClick = (ticket) => {
    if (selectedTicket && selectedTicket.id !== ticket.id) {
      setClickedTicket(ticket);
      setFadeClass('fade-out');
    } else {
      setSelectedTicket(ticket);
      setDisplayedTicket(ticket);
    }
  };

  useEffect(() => {
    if (ticket_id && tickets) {
      const ticket = tickets.find((ticket) => ticket.id === ticket_id);
      if (ticket && (!selectedTicket || selectedTicket && selectedTicket.id !== ticket.id)) {
        if (selectedTicket) setFadeClass('fade-out');
        const timer = setTimeout(() => {
          setSelectedTicket(ticket);
          setDisplayedTicket(ticket);
          setFadeClass('fade-in');
        }, 300); // 300ms is the duration of your fade-out animation

        return () => clearTimeout(timer); // This will clear the timeout if the component unmounts before the timeout finishes
      }
    }
  }, [ticket_id, tickets]);

  useEffect(() => {
    if (fadeClass === 'fade-out' && clickedTicket) {
      const timer = setTimeout(() => {
        setSelectedTicket(clickedTicket);
        setDisplayedTicket(clickedTicket);
        setFadeClass('fade-in');
        navigate(`/meldingen/${clickedTicket.id}`);
      }, 300); // 300ms is the duration of your fade-out animation

      return () => clearTimeout(timer); // This will clear the timeout if the component unmounts before the timeout finishes
    }
  }, [fadeClass]);

  const ticketMap = tickets?.map((ticket, index) => {
    return (
      <div onClick={() => onTicketClick(ticket)}>
        <TicketCard
          key={index}
          ticketInfo={ticket}
          isSelected={selectedTicket && ticket.id === selectedTicket.id}
        />
      </div>
    );
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="tickets">
      <div className="space-separator">
        <div className="ticket-spacer border">
          <div className="card message-container">
            <div className="message-amount">
              <h2>Desk</h2>
              <h2 className="amount">{tickets?.length} Meldingen</h2>
            </div>
            <span>Laatste update gister</span>
          </div>

          <div className="ticket-container">{ticketMap}</div>
        </div>
      </div>

      <div className={`space-separator ${fadeClass}`}>
        <div className="ticket">
          <TicketDetail ticket={displayedTicket} />
        </div>
      </div>
    </div>
  );
};

export default Tickets;
