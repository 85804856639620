import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendMessage, resetTasks, clearChat } from '../Actions';
import '../../assets/styles/cards/StartCard.css';
import Modal from '../Modal'; // Importeer uw Modal component
import Chatbot from '../Chatbot'; // Importeer uw Chatbot component
import { ReactComponent as Elevate } from '../../assets/icons/elevate_symbol_brightcare.svg';
import TextareaAutosize from 'react-textarea-autosize';
import { AttachFile, PhoneInTalkOutlined, PhotoCamera, StartOutlined } from '@mui/icons-material';
import { input } from '@testing-library/user-event/dist/cjs/event/input.js';


function StartCard({ style, title, buttons, modal = false }) {
    const thread_status = useSelector(state => state.main.status);
    const dispatch = useDispatch();
    const thread = useSelector(state => state.main.thread);
    const running_threads = useSelector(state => state.main.running_threads);
    const navigate = useNavigate();
    const [loadingButtonIndex, setLoadingButtonIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // Nieuwe state voor het beheren van de modal
    const [inputValue, setInputValue] = useState(''); // Nieuwe state voor het beheren van de input waarde
    const [isCameraClosing, setIsCameraClosing] = useState(false);
    const [isImageClosing, setIsImageClosing] = useState(false);
    const [extraMessage, setExtraMessage] = useState('');
    const [busy, setBusy] = useState(false);

    // Camera state variables
    const [videoStream, setVideoStream] = useState(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [isCameraActive, setIsCameraActive] = useState(false);
    const [useFrontCamera, setUseFrontCamera] = useState(true);

    const handleCameraClick = async () => {
        setIsCameraButtonClicked(true);
        setTimeout(() => {
            setIsCameraButtonClicked(false);
        }, 2000); // 2000ms = 2s

        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: { facingMode: useFrontCamera ? 'user' : 'environment' }
            });
            setVideoStream(stream);
            setIsCameraActive(true);
        } catch (err) {
            console.error("Failed to access camera:", err);
        }
    };


    const switchCamera = () => {
        setUseFrontCamera(!useFrontCamera);

        // Stop the current video stream
        if (videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
            setVideoStream(null);
        }

        // Start the new video stream
        handleCameraClick();
    };

    // Add a new function to take the picture
    const takePicture = async () => {
        if (!videoStream) {
            console.error("No video stream available");
            return;
        }

        const video = document.createElement('video');
        video.srcObject = videoStream;
        await video.play();

        // Create a canvas and draw the current frame from the video onto it
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0);

        // Now you have an image as a data URL
        const imageUrl = canvas.toDataURL('image/png');

        // Set the captured image
        setCapturedImage(imageUrl);
    };

    const sendPicture = () => {
        if (!capturedImage) {
            console.error("No captured image available");
            return;
        }

        // Send the image URL and the extra message as a message
        let message = {
            type: 'user',
            message: "image: " + capturedImage + "\n" + extraMessage,
            thread_id: thread,
            route: 'default'
        }

        setBusy(true);
        dispatch(sendMessage(JSON.stringify(message)));

        // Reset the camera, captured image, and extra message
        setCapturedImage(null);
        setIsCameraActive(false);
        setExtraMessage('');
        if (videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
            setVideoStream(null);
        }
    };

    // Add a new function to stop the camera
    const stopCamera = () => {
        if (videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
            setVideoStream(null);
        }
        setIsCameraActive(false);
    };


    // Modify the closeOverlay function to set isCameraClosing or isImageClosing to true
    const closeOverlay = () => {
        if (isCameraActive) {
            setIsCameraClosing(true);
            setTimeout(() => {
                console.log('videoStream: ', videoStream);
                if (videoStream) {
                    videoStream.getTracks().forEach(track => track.stop());
                    setVideoStream(null);
                }
                setIsCameraActive(false);
                setIsCameraClosing(false);
            }, 500);
        }
        if (capturedImage) {
            setIsImageClosing(true);
            setTimeout(() => {
                setCapturedImage(null);
                setIsImageClosing(false);
            }, 500);
        }
    };

    const [isCameraButtonClicked, setIsCameraButtonClicked] = useState(false);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!inputValue || inputValue.trim() === '') {
            return;
        }

        let message = { "type": "user", "message": inputValue, "thread_id": null, "route": null }
        dispatch(sendMessage(message));
    };

    if (modal) {
        title = "Waarmee kan ik je helpen?";
        buttons = [
            {
                "text": "Zoek info",
                "action": "zoek info",
                "type": "primary"
            },
            {
                "text": "Heb hulp nodig",
                "action": "heb hulp nodig",
                "type": "primary"
            },
            {
                "text": "Ik wil graag..",
                "action": "ik wil graag",
                "type": "primary"
            },
            {
                "text": "Iets aanpassen",
                "action": "iets aanpassen",
                "type": "primary"
            }
        ];
    }

    const handleClick = (action, index) => {
        if (action === "open" && thread) {
            //nog meer te doen? navigeren..?

        } else {
            setLoadingButtonIndex(index);
            let message = { "type": "user", "message": "gebruiker heeft op actie geklikt: " + action, "thread_id": null, "route": null }
            dispatch(sendMessage(message));
        }
        setIsModalOpen(true); // Open de modal in plaats van te navigeren
    };


    const current_thread = thread && running_threads?.find(t => t && t.id === thread.id);

    // Create a ref for the video element
    const videoRef = useRef(null);

    // Use an effect to assign the video stream to the video element
    useEffect(() => {
        if (videoRef.current && videoStream) {
            videoRef.current.srcObject = videoStream;
        }
    }, [videoStream]);

    // useEffect(() => {
    //     if (loadingButtonIndex !== null) {
    //         const timer = setTimeout(() => {
    //             navigate('/gesprek');
    //         }, 2000); // Change this to the number of seconds you want

    //         return () => clearTimeout(timer); // This will clear the timeout if the component unmounts before the timeout finishes
    //     }
    // }, [loadingButtonIndex, navigate]);

    return (
        <div className="card no-background start-card" style={{ ...style }}>
            {(isCameraActive || isCameraClosing) && (
                <div className={`overlay ${isCameraClosing ? 'closing' : ''}`} onClick={closeOverlay}>
                    <div className="modal" onClick={e => e.stopPropagation()}>
                        <video ref={videoRef} autoPlay></video>
                        <button onClick={takePicture}>Take Picture</button>
                        <button onClick={switchCamera}>Switch Camera</button>
                        <button onClick={stopCamera}>Stop Camera</button>
                    </div>
                </div>
            )}
            {(capturedImage || isImageClosing) && (
                <div className={`overlay ${isImageClosing ? 'closing' : ''}`} onClick={closeOverlay}>
                    <div className="modal" onClick={e => e.stopPropagation()}>
                        <img src={capturedImage} alt="Captured" />
                        <textarea
                            value={extraMessage}
                            onChange={e => setExtraMessage(e.target.value)}
                            placeholder="Add an extra message (optional)"
                        />
                        <button onClick={sendPicture}>Send Picture</button>
                        <button onClick={() => { setCapturedImage(null); setIsCameraActive(true); }}>Take Another Picture</button>
                    </div>
                </div>
            )}

            {(modal && !isCameraActive) && (
                <div className={`chatbot-top elevate ${thread && (thread_status == "running" || thread_status == "started" || thread_status == "starting") ? 'elevate-shadow-animating-large' : (thread ? 'elevate-shadow-large' : '')}`} onClick={() => navigate('/gesprek')}>
                    <Elevate style={{ marginTop: "-50px" }} />
                </div>
            )}
            {(thread_status == "active" || thread_status == "running" || thread_status == "started" || thread_status == "starting") ?
                (!modal && (
                    <div className="start-card-active" onClick={() => handleClick("open")}>
                        <h2 className="gradient-text">Terug naar het gesprek</h2>
                        <div>{current_thread && current_thread.metadata?.user_summary}</div>
                    </div>))
                :
                <React.Fragment>
                    <h2 className="gradient-text">{title}</h2>
                    {modal && (
                        <div className="start-card-actions">
                            <button onClick={() => { handleCameraClick(); }}><PhotoCamera />Maak een foto</button>
                            <button><AttachFile />Upload een document</button>
                            <button><PhoneInTalkOutlined />Spreek je uit</button>
                        </div>
                    )}
                    {buttons.map((button, index) => (
                        <button
                            key={index}
                            onClick={() => handleClick(button.action, index)}
                            className={button.type}
                            // disabled={loadingButtonIndex !== null}
                        >
                            {/* {loadingButtonIndex === index ? 'Loading...' : button.text} */}
                            {button.text}
                        </button>
                    ))}
                    {modal && (
                        <form onSubmit={handleSubmit}>
                            <TextareaAutosize
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' && !event.shiftKey) {
                                        event.preventDefault();
                                        handleSubmit(event);
                                    }
                                }}
                                placeholder={"Of typ hier je reactie"}
                            />
                            {/* <button type="submit">{busy ? <Loader /> : <Play />}</button> */}
                        </form>
                    )}
                </React.Fragment>
            }
            {!modal && (
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <Chatbot modal={true} />
                </Modal>
            )}
        </div >
    );
}
export default StartCard;