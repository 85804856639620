import {
  GET_INSTRUCTION_REQUEST, GET_INSTRUCTION_SUCCESS, GET_INSTRUCTION_FAILURE,
  SAVE_INSTRUCTION_REQUEST, SAVE_INSTRUCTION_SUCCESS, SAVE_INSTRUCTION_FAILURE,
  DELETE_INSTRUCTION_REQUEST, DELETE_INSTRUCTION_SUCCESS, DELETE_INSTRUCTION_FAILURE
} from './actions';

const initialState = {
  instructions: [],
  loading: false,
  error: null,
};

export function instructionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INSTRUCTION_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_INSTRUCTION_SUCCESS:
      return { ...state, loading: false, instructions: action.payload };
    case GET_INSTRUCTION_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SAVE_INSTRUCTION_REQUEST:
      return { ...state, loading: true, error: null };
    case SAVE_INSTRUCTION_SUCCESS:
      return { ...state, loading: false };
    case SAVE_INSTRUCTION_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case DELETE_INSTRUCTION_REQUEST:
      return { ...state, loading: true, error: null };
    case DELETE_INSTRUCTION_SUCCESS:
      return { ...state, loading: false };
    case DELETE_INSTRUCTION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
}