export const GET_KNOWLEDGE_REQUEST = 'GET_KNOWLEDGE_REQUEST';
export const GET_KNOWLEDGE_SUCCESS = 'GET_KNOWLEDGE_SUCCESS';
export const GET_KNOWLEDGE_FAILURE = 'GET_KNOWLEDGE_FAILURE';

export const SAVE_KNOWLEDGE_REQUEST = 'SAVE_KNOWLEDGE_REQUEST';
export const SAVE_KNOWLEDGE_SUCCESS = 'SAVE_KNOWLEDGE_SUCCESS';
export const SAVE_KNOWLEDGE_FAILURE = 'SAVE_KNOWLEDGE_FAILURE';

export const DELETE_KNOWLEDGE_REQUEST = 'DELETE_KNOWLEDGE_REQUEST';
export const DELETE_KNOWLEDGE_SUCCESS = 'DELETE_KNOWLEDGE_SUCCESS';
export const DELETE_KNOWLEDGE_FAILURE = 'DELETE_KNOWLEDGE_FAILURE';

export const getKnowledgeRequest = (organizationId) => ({ 
    type: GET_KNOWLEDGE_REQUEST, 
    payload: { organizationId } 
});

export const getKnowledgeSuccess = knowledge => ({ type: GET_KNOWLEDGE_SUCCESS, payload: knowledge });
export const getKnowledgeFailure = error => ({ type: GET_KNOWLEDGE_FAILURE, payload: error });

export const saveKnowledgeRequest = knowledge => ({ type: SAVE_KNOWLEDGE_REQUEST, payload: knowledge });
export const saveKnowledgeSuccess = () => ({ type: SAVE_KNOWLEDGE_SUCCESS });
export const saveKnowledgeFailure = error => ({ type: SAVE_KNOWLEDGE_FAILURE, payload: error });

export const deleteKnowledgeRequest = knowledge => ({ type: DELETE_KNOWLEDGE_REQUEST, payload: knowledge });
export const deleteKnowledgeSuccess = () => ({ type: DELETE_KNOWLEDGE_SUCCESS });
export const deleteKnowledgeFailure = error => ({ type: DELETE_KNOWLEDGE_FAILURE, payload: error });