import { call, put, take, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
  GET_ASSISTANTS_REQUEST, getAssistantsSuccess, getAssistantsFailure,
  SAVE_ASSISTANTS_REQUEST, saveAssistantsSuccess, saveAssistantsFailure,
  DELETE_ASSISTANT_REQUEST, deleteAssistantSuccess, deleteAssistantFailure
} from './actions';
import { getSocket } from '../../components/Socket';
import { all } from 'redux-saga/effects';

function createSocketChannel(socket) {
  return eventChannel(emit => {
    const handler = (data) => {
      emit(data);
    };
    socket.on('assistants', handler);
    return () => {
      socket.off('assistants', handler);
    };
  });
}

function* fetchAssistantsSaga() {
  try {
    const socket = getSocket();
    if (!socket) {
      console.log('Socket is not initialized');
      return;
    }

    const socketChannel = yield call(createSocketChannel, socket);
    socket.emit('get_assistants');

    while (true) {
      const assistants = yield take(socketChannel);
      yield put(getAssistantsSuccess(assistants));
    }

  } catch (error) {
    yield put(getAssistantsFailure(error.toString()));
  }
}

function* saveAssistantsSaga(action) {
  try {
    const socket = getSocket();
    if (!socket) {
      console.log('Socket is not initialized');
      return;
    }

    socket.emit('save_assistant', action.payload);
    yield put(saveAssistantsSuccess());

  } catch (error) {
    yield put(saveAssistantsFailure(error.toString()));
  }
}

function* deleteAssistantSaga(action) {
  try {
    const socket = getSocket();
    if (!socket) {
      console.log('Socket is not initialized');
      return;
    }

    socket.emit('delete_assistant', action.payload);
    yield put(deleteAssistantSuccess());

  } catch (error) {
    yield put(deleteAssistantFailure(error.toString()));
  }
}

export function* watchAssistantsSagas() {
  yield takeEvery(GET_ASSISTANTS_REQUEST, fetchAssistantsSaga);
  yield takeEvery(SAVE_ASSISTANTS_REQUEST, saveAssistantsSaga);
  yield takeEvery(DELETE_ASSISTANT_REQUEST, deleteAssistantSaga);
}

// export function* watchSaveAssistantsSaga() {
//   yield takeEvery(SAVE_ASSISTANTS_REQUEST, saveAssistantsSaga);
// }

// // export function* watchAssistantsSagas() {
// //   yield all([
// //     watchFetchAssistantsSaga(),
// //     watchSaveAssistantsSaga()
// //   ]);
// // }