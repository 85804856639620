// ActivityDetail.js
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../../assets/styles/details/ActivityDetail.css';
import { timeSince } from '../GeneralFunctions';
import { marked } from 'marked';
import EVAinput from '../EVAinput';


function ActivityDetail({ activity, inCard }) {
  const [selectedActivity, setSelectedActivity] = useState();
  const [selectedPayload, setSelectedPayload] = useState();
  const [actions, setActions] = useState();
  const tickets = useSelector(state => state.tickets.tickets);

  useEffect(() => {
    setSelectedActivity(activity);

    if (activity?.type == "ticket") {
      const ticket = tickets.find((t) => t.id == activity?.object_id);
      setSelectedPayload(ticket);
    }

    // setActions(
    //   activity?.objects?.map((obj, index) => {
    //     return (
    //       <div key={index} className="card action">
    //         <p>{obj.object_type}: {obj.object_id}</p>
    //         <span>{timeSince(new Date(obj.entryDate))}</span>
    //       </div>
    //     );
    //   })
    // );
  }, [activity]);

  if (selectedActivity && selectedActivity.type == "info") {
    const content = marked(selectedActivity.payload?.description ? selectedActivity.payload?.description : selectedActivity.content)
    return (
      <div className="card activity-content">

        {selectedActivity.content && <div dangerouslySetInnerHTML={{ __html: content }} />}
        <div className="activity-info">
          <div>{timeSince(new Date(selectedActivity.updated_at))}</div>
          {selectedActivity.status && (<div>{selectedActivity.status}</div>)}
        </div>

      </div>
    );
  }

  if (selectedActivity && selectedActivity.type == "message") {
    return (
      <div className="card activity-content">
        <div>{selectedActivity.content}</div>
        <div className="activity-info">
          <div>{timeSince(new Date(selectedActivity.updated_at))}</div>
          <div>door {selectedActivity.from ? selectedActivity.from : 'onbekend'}</div>
        </div>
      </div>
    );
  }


  if (selectedActivity && selectedActivity.type == "ticket") {
    const content = marked(selectedActivity.payload?.description ? selectedActivity.payload?.description : selectedActivity.payload?.content)


    return (
      <React.Fragment>
        <div className="card activity-content">
          {selectedActivity.content && <div dangerouslySetInnerHTML={{ __html: content }} />}
          <div className="activity-info">
            <span>#{selectedPayload?.number}</span>
            <span>{selectedPayload?.action_status}</span>

            {/* {selectedActivity.status && (<span>{selectedActivity.status}</span>)} */}
            <span>{selectedPayload?.priority?.content}</span>
            <span>{selectedPayload?.type.call}</span>
          </div>
        </div>

        {selectedPayload?.actions && selectedPayload?.actions.slice(0, inCard ? 1 : selectedPayload?.actions.length).map((action, index) => {
          return (
            <div key={index} className={`card activity-action ${inCard ? 'inCard' : ''}`}>
              <div>
                <span className="activity-action-title">{(action.who.indexOf('AI') != -1 || action.who.indexOf('EVA') != -1) ? "EVA" : action.who}</span>
                {/* <span>{action.source}</span> */}
                <span>{action.content}</span>
              </div>
              <span className="activity-action-info textAlignRight">{timeSince(new Date(action.entryDate))}</span>
            </div>
          );
        })}

        {!inCard && (<EVAinput placeholder="Heb je een update of toevoeging? Geef dat hier door.." />)}

      </React.Fragment>
    );
  }


  return null;
}

export default ActivityDetail;
