import React from 'react';
import { useDispatch } from 'react-redux';
import { sendMessage } from '../Actions';

function BannerWidget({ title, data }) {
    const dispatch = useDispatch();

    const handleAction = (action) => {
        switch (action.type) {
            case 'chat':
                dispatch(sendMessage({
                    type: "user",
                    message: action.payload.message,
                    thread_id: null,
                    route: action.payload.route
                }));
                break;
            case 'open':
                window.open(action.payload.url, '_blank');
                break;
            default:
                console.warn('Unknown action type:', action.type);
        }
    };

    return (
        <div className="card banner-card" style={{ ...data.style, backgroundImage: `url(${data.backgroundImage})` }}>
            <h2>{title}</h2>
            <p>{data.description}</p>
            <div className="actions-buttons">
                {data.actions.map((action, index) => (
                    <button key={index} onClick={() => handleAction(action)}>{action.label}</button>
                ))}
            </div>
        </div>
    );
}

export default BannerWidget;