export const GET_TASKS_REQUEST = 'GET_TASKS_REQUEST';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_FAILURE = 'GET_TASKS_FAILURE';
export const GET_TASK = 'GET_TASK';
export const SAVE_TASK = 'SAVE_TASK';

export const getTasksRequest = () => ({ type: GET_TASKS_REQUEST });
export const getTasksSuccess = tasks => ({ type: GET_TASKS_SUCCESS, payload: tasks });
export const getTasksFailure = error => ({ type: GET_TASKS_FAILURE, payload: error });

export const getTask = (task_id) => ({ type: GET_TASK, payload: task_id });

export const saveTask = (task) => ({
    type: SAVE_TASK,
    payload: task,
});
