import React from 'react';
import ReactDOM from 'react-dom';
import '../assets/styles/Modal.css'; // Import your CSS file

const Modal = ({ isOpen, children, onClose }) => {
    if (!isOpen) {
        return null;
    }

    return ReactDOM.createPortal(

        <div className={`modal-overlay ${isOpen ? 'open' : ''}`} onClick={onClose}>
            <div className="modal-container">
                <div className='modal-content' onClick={(e) => e.stopPropagation()}>
                    {children}
                    {/* <button className='modal-close-button' onClick={onClose}>Close</button> */}
                </div>
            </div>
        </div>,
        document.getElementById('modal-root')
    );
};

export default Modal;