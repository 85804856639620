import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFlows, saveFlow, deleteFlow, runFlow, debugFlow } from '../../../sagas/flows/actions';
import CodeEditor from './CodeEditor';
import './flowManagement.css';

const FlowManagement = () => {
    const dispatch = useDispatch();
    const flows = useSelector(state => state.flows.flows);
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [runResult, setRunResult] = useState(null);

    useEffect(() => {
        dispatch(getFlows());
    }, [dispatch]);

    const handleSelectFlow = (flow) => {
        setSelectedFlow(flow);
        setIsEditing(false);
    };



    const handleCreateFlow = () => {
        setSelectedFlow({ action_name: '', type: 'crewai', config: { agents: [], tasks: [] } });
        setIsEditing(true);
    };

    const handleSaveFlow = () => {
        if (selectedFlow._id) {
            dispatch(saveFlow(selectedFlow));
        } else {
            dispatch(saveFlow(selectedFlow));
        }
        setIsEditing(false);
    };

    const handleDeleteFlow = () => {
        dispatch(deleteFlow(selectedFlow._id));
        setSelectedFlow(null);
    };

    const handleRunFlow = async () => {
        const result = await dispatch(runFlow(selectedFlow._id));
        setRunResult(result);
    };

    const handleAddAgent = () => {
        setSelectedFlow({
            ...selectedFlow,
            config: {
                ...selectedFlow.config,
                agents: [...selectedFlow.config.agents, { role: '', goal: '', backstory: '', verbose: true }]
            }
        });
    };

    const handleUpdateAgent = (index, field, value) => {
        const updatedAgents = [...selectedFlow.config.agents];
        updatedAgents[index][field] = value;
        setSelectedFlow({
            ...selectedFlow,
            config: { ...selectedFlow.config, agents: updatedAgents }
        });
    };

    const handleDeleteAgent = (index) => {
        const updatedAgents = selectedFlow.config.agents.filter((_, i) => i !== index);
        setSelectedFlow({
            ...selectedFlow,
            config: { ...selectedFlow.config, agents: updatedAgents }
        });
    };

    const handleAddTask = () => {
        setSelectedFlow({
            ...selectedFlow,
            config: {
                ...selectedFlow.config,
                tasks: [...selectedFlow.config.tasks, { description: '', expected_output: '', agent_role: '', context: [] }]
            }
        });
    };

    const handleUpdateTask = (index, field, value) => {
        const updatedTasks = [...selectedFlow.config.tasks];
        updatedTasks[index][field] = value;
        setSelectedFlow({
            ...selectedFlow,
            config: { ...selectedFlow.config, tasks: updatedTasks }
        });
    };

    const handleDeleteTask = (index) => {
        const updatedTasks = selectedFlow.config.tasks.filter((_, i) => i !== index);
        setSelectedFlow({
            ...selectedFlow,
            config: { ...selectedFlow.config, tasks: updatedTasks }
        });
    };

    return (
        <div className="flow-management">
            <div className="flow-list">
                <h2>Flows</h2>
                <button onClick={handleCreateFlow}>Create New Flow</button>
                {flows?.map(flow => (
                    <div key={flow._id} onClick={() => handleSelectFlow(flow)}>
                        {flow.action_name}
                    </div>
                ))}
            </div>
            {selectedFlow && (
                <div className="flow-details">
                    <h2>{isEditing ? 'Edit Flow' : 'Flow Details'}</h2>
                    <input
                        value={selectedFlow.action_name}
                        onChange={(e) => setSelectedFlow({ ...selectedFlow, action_name: e.target.value })}
                        disabled={!isEditing}
                    />
                    <select
                        value={selectedFlow.type}
                        onChange={(e) => setSelectedFlow({ ...selectedFlow, type: e.target.value })}
                        disabled={!isEditing}
                    >
                        <option value="crewai">CrewAI</option>
                        <option value="nodered">Node-RED</option>
                    </select>

                    {selectedFlow.type === 'crewai' && (
                        <>
                            <h3>Agents</h3>
                            {selectedFlow.config?.agents?.map((agent, index) => (
                                <div key={index} className="agent">
                                    <input
                                        value={agent.role}
                                        onChange={(e) => handleUpdateAgent(index, 'role', e.target.value)}
                                        placeholder="Role"
                                        disabled={!isEditing}
                                    />
                                    <input
                                        value={agent.goal}
                                        onChange={(e) => handleUpdateAgent(index, 'goal', e.target.value)}
                                        placeholder="Goal"
                                        disabled={!isEditing}
                                    />
                                    <textarea
                                        value={agent.backstory}
                                        onChange={(e) => handleUpdateAgent(index, 'backstory', e.target.value)}
                                        placeholder="Backstory"
                                        disabled={!isEditing}
                                    />
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={agent.verbose}
                                            onChange={(e) => handleUpdateAgent(index, 'verbose', e.target.checked)}
                                            disabled={!isEditing}
                                        />
                                        Verbose
                                    </label>
                                    {isEditing && (
                                        <button onClick={() => handleDeleteAgent(index)}>Delete Agent</button>
                                    )}
                                </div>
                            ))}
                            {isEditing && (
                                <button onClick={handleAddAgent}>Add Agent</button>
                            )}

                            <h3>Tasks</h3>
                            {selectedFlow.config?.tasks?.map((task, index) => (
                                <div key={index} className="task">
                                    <input
                                        value={task.description}
                                        onChange={(e) => handleUpdateTask(index, 'description', e.target.value)}
                                        placeholder="Description"
                                        disabled={!isEditing}
                                    />
                                    <input
                                        value={task.expected_output}
                                        onChange={(e) => handleUpdateTask(index, 'expected_output', e.target.value)}
                                        placeholder="Expected Output"
                                        disabled={!isEditing}
                                    />
                                    <select
                                        value={task.agent_role}
                                        onChange={(e) => handleUpdateTask(index, 'agent_role', e.target.value)}
                                        disabled={!isEditing}
                                    >
                                        <option value="">Select Agent</option>
                                        {selectedFlow.config?.agents?.map(agent => (
                                            <option key={agent.role} value={agent.role}>{agent.role}</option>
                                        ))}
                                    </select>
                                    <select
                                        multiple
                                        value={task.context}
                                        onChange={(e) => handleUpdateTask(index, 'context', Array.from(e.target.selectedOptions, option => option.value))}
                                        disabled={!isEditing}
                                    >
                                        {selectedFlow.config?.tasks?.filter((_, i) => i !== index).map(otherTask => (
                                            <option key={otherTask.description} value={otherTask.description}>{otherTask.description}</option>
                                        ))}
                                    </select>
                                    {isEditing && (
                                        <button onClick={() => handleDeleteTask(index)}>Delete Task</button>
                                    )}
                                </div>
                            ))}
                            {isEditing && (
                                <button onClick={handleAddTask}>Add Task</button>
                            )}
                        </>
                    )}

                    {isEditing ? (
                        <button onClick={handleSaveFlow}>Save</button>
                    ) : (
                        <>
                            <button onClick={() => setIsEditing(true)}>Edit</button>
                            <button onClick={handleDeleteFlow}>Delete</button>
                            <button onClick={handleRunFlow}>Run</button>
                        </>
                    )}
                </div>
            )}

            {runResult && (
                <div className="run-result">
                    <h3>Run Result</h3>
                    {runResult.status === "completed" ? (
                        <div>
                            <p>Status: {runResult.status}</p>
                            <pre>{JSON.stringify(runResult.result, null, 2)}</pre>
                        </div>
                    ) : (
                        <div>
                            <p>Status: {runResult.status}</p>
                            <p>Error: {runResult.error.message}</p>
                            <details>
                                <summary>Error Details</summary>
                                <pre>{runResult.error.traceback}</pre>
                            </details>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default FlowManagement;