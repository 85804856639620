import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { marked, use } from 'marked';
import '../assets/styles/AiResponseParser.css';
import { setDelta, setActivity, sendMessage } from './Actions';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextareaAutosize from 'react-textarea-autosize';
import { CameraAlt, PhotoOutlined } from '@mui/icons-material';
import { Player } from '@lordicon/react';

const CAMERA = require('../assets/system-regular-32-videocam.json');
const PLAY = require('../assets/system-regular-26-play.json');

const StreamingAIResponse = ({ onSubmit, onStop, onAction, doLoading, inLine = false }) => {
  const dispatch = useDispatch();
  const delta = useSelector((state) => state.main.delta);
  const delta_end = useSelector((state) => state.main.delta_end);
  const threadStatus = useSelector((state) => state.main.status);
  const thread = useSelector((state) => state.main.thread);
  const running_threads = useSelector((state) => state.main.running_threads);
  const activity = useSelector((state) => state.main.activity);
  const activities = useSelector((state) => state.activities.activities);

  const [response, setResponse] = useState('');
  const [elements, setElements] = useState([]);
  const [isStopped, setIsStopped] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({});
  const [selectedSingleChoice, setSelectedSingleChoice] = useState([]);
  const [selectedMultiChoices, setSelectedMultiChoices] = useState([]);
  const [buttonElements, setButtonElements] = useState([]);
  const [inputElements, setInputElements] = useState([]);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [isSubmitVisible, setIsSubmitVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [insideElement, setInsideElement] = useState(false);
  const [insideActivity, setInsideActivity] = useState(false);
  const [activityElements, setActivityElements] = useState([]);
  const [currentActivity, setCurrentActivity] = useState(0);
  const [newActivity, setActiveActivity] = useState(null);
  const [stopTimeout, setStopTimeout] = useState(null);
  const [threadEnded, setThreadEnded] = useState(false);
  const [busy, setBusy] = useState(false);
  const buffer = useRef('');
  const tempBuffer = useRef('');
  const skipBuffer = useRef(0);
  const VideoPlayerRef = useRef(null);
  const PlayPlayerRef = useRef(null);


  useEffect(() => {
    if (threadStatus === 'idle') {
      resetComponent();
    } else if (threadStatus === 'active') {
      setIsStopped(true);

      if ((!delta || delta == '') && thread) {
        console.log('existing thread? ', thread);
        let t = running_threads.find((t) => t.thread_id == thread);
        if (t) {
          console.log('found thread: ', t);
          const message = t.messages[t.messages.length - 1];
          if (!message || message.role != 'assistant') return;

          console.log('found message: ', message.content);

          dispatch(setDelta(message.content));

        }
      }
    }
  }, [threadStatus]);

  useEffect(() => {
    if (delta_end) {
      setBusy(false);
    }
  }, [delta_end]);

  useEffect(() => {
    if (delta) {

      if (delta.length < buffer.current.length) {
        resetComponent();
      }
      if (!busy && threadStatus != "active") setBusy(true);
      processBuffer();
    }
  }, [delta, activities]);

  const getActivity = (id) => {
    if (id && id != '' && id != 'new') {
      return activities?.find((activity) => activity._id == id);
    }
  }

  // useEffect(() => {
  //   setIsSubmitVisible(Object.keys(inputs).length > 0);
  // }, [inputs]);


  const resetComponent = () => {
    console.log("resetting streaming");
    //dispatch(setDelta(''));
    setResponse('');
    setElements([]);
    setInputElements([]);
    doLoading(false);
    setActivityElements([]);
    setCurrentActivity(0);
    setIsStopped(false);
    setActiveActivity(null);
    setSelectedFile(null);
    buffer.current = '';
    setButtonElements([]);
    tempBuffer.current = '';
    skipBuffer.current = 0;
  };

  const processBuffer = async () => {
    const tempDelta = delta;
    const currentIndex = buffer.current.length + tempBuffer.current.length + skipBuffer.current;
    const newContent = tempDelta.substring(currentIndex);

    let tempInsideActivity = insideActivity;
    let tempInsideElement = insideElement;
    let tempBufferIndex = 0;

    for (let [index, char] of [...newContent].entries()) {
      if (char == '>') {
        //const lastPart = tempDelta.slice(currentIndex + tempBufferIndex, index + 1).trim();
        const lastPart = tempDelta.slice(tempDelta.lastIndexOf('<')).trim();

        //const activityStartMatch = lastPart.match(/<ACTIVITY\|(.*)\|(.*)>/);
        const infoStartMatch = lastPart.match(/<INFO>/);

        if (infoStartMatch) {
          console.log("INFO START");
          tempBuffer.current += char;
          tempBufferIndex = index;
          setInsideActivity(true);
          setInsideElement(false);
          tempInsideActivity = true;
          tempInsideElement = false;
          continue;
        }

        if (lastPart.indexOf('</INFO>') != -1) {
          console.log("INFO ENDED");
          setInsideActivity(false);
          setInsideElement(false);
          tempBufferIndex = index;
          tempInsideActivity = false;
          tempInsideElement = false;
          tempBuffer.current += char;

          console.log("ACTIVITY BUFFER", tempBuffer.current);

          const newActivityElements = [...activityElements];
          const newElement = parseAndRenderElements(tempBuffer.current);
          newActivityElements[currentActivity] = newElement;
          setActivityElements(newActivityElements);

          skipBuffer.current += tempBuffer.current.length;
          tempBuffer.current = '';
          setCurrentActivity((prevCurrent) => { return prevCurrent + 1 });
          continue;
        }

        if (lastPart.split(/(<textarea:.*?>|<ROUTE:.*?>|<singlechoice:.*?>|<box:.*?>|<photoupload:.*?>|<link:.*?>|<multichoice:.*?>|<ANOTHERROUND>|<STOP>|<button:.*?>)/gsi).filter(Boolean)) {
          // console.log("YES!");
          const newElements = parseAndRenderElements(lastPart);
          setElements(prevElements => [...prevElements, ...newElements]);
          //continue;
        }

      }

      if (!tempInsideActivity && (char == '<')) {
        tempInsideElement = true;
        setInsideElement(true);
        tempBuffer.current = '';
      }

      if (tempInsideActivity) {
        tempBuffer.current += char;

        const newActivityElements = [...activityElements];
        const newElement = parseAndRenderElements(tempBuffer.current);
        newActivityElements[currentActivity] = newElement;
        setActivityElements(newActivityElements);

      } else if (tempInsideElement) {
        tempBuffer.current += char;

      } else {
        buffer.current += char;
      }

      if (tempInsideElement && !tempInsideActivity && (char == '>')) {
        tempInsideElement = false;
        setInsideElement(false);
        buffer.current += tempBuffer.current;
        tempBuffer.current = '';

      } else if (!tempInsideElement && !tempInsideActivity) {
        const newElements = parseAndRenderElements(buffer.current);
        setElements(newElements);
      }
    }

    if (!tempInsideElement && !tempInsideActivity) {
      const newElements = parseAndRenderElements(buffer.current);
      setElements(newElements);
    }
  };

  const parseAndRenderElements = (response) => {
    if (!response) return [];

    const parts = response.split(/(<INFO>.*?<\/INFO>|<SHOW:.*?>|<textarea:.*?>|<ROUTE:.*?>|<singlechoice:.*?>|<box:.*?>|<photoupload:.*?>|<link:.*?>|<multichoice:.*?>|<ANOTHERROUND>|<WAIT>|<STOP>|<button:.*?>)/gsi).filter(Boolean);

    let inputIndex = 0;
    let tempButtonElements = [];
    let tempElements = [];
    let tempInputElements = [];

    const elements = parts.map((part, index) => {
      const match = part.match(/<(SHOW|INFO|textarea|singlechoice|ROUTE|ANOTHERROUND|photoupload|box|multichoice|button|WAIT|STOP|link)((:|\|)(.*?)>|>)/si);

      if (!match) {
        return generateMarkDown(part, index);
      }

      const [fullMatch, elementType, , content] = match[1] ? match : [match[0], 'STOP', ''];
      const lowerCaseElement = elementType.toLowerCase();
      
      switch (lowerCaseElement) {
        case 'info':
          const infoMatch = part.match(/<INFO>(.*?)<\/INFO>/s);
          if (infoMatch) {
            const [, infoContent] = infoMatch;
            return generateMarkDown(infoContent, index);
          }

          const tempInfoMatch = part.match(/INFO\>([\s\S]*)/);
          if (tempInfoMatch) {

            const [, activityContent] = tempInfoMatch;
            return generateMarkDown(activityContent, index);
          }

        case 'show':
          const activityMatch = part.match(/SHOW\|(.*?)\>/s);
          if (activityMatch) {

            const [, activityId, activityType] = activityMatch;
            if (activityId && activityId != 'new') {
              let activity = getActivity(activityId)
              setActiveActivity(activity);
              dispatch(setActivity(activity));
            }
          }

        case 'route':
          // Handle the ROUTE tag here.
          return <div>
            <div key={`loading-${index}`} className="loading-response" />
            <span>bezig met routeren</span>
          </div>;
          break;

        case 'anotherround':
          // Handle the ANOTHERROUND tag here.
          return <div key={`loading-${index}`} className="loading-response" />;
          break;

        case 'wait':
          // Handle the WAIT tag here.
          return <div key={`loading-${index}`} className="loading-response" />;
          break;

        case 'stop':
          console.log("STOPPING");
          if (!activity) {

            setThreadEnded(true);
            // Wis de bestaande timeout als deze bestaat
            if (stopTimeout) {
              clearTimeout(stopTimeout);
              setStopTimeout(null);
            }

            // Stel een nieuwe timeout in
            const newTimeout = setTimeout(() => {
              console.log("STOPPING NOW");
              if (!activity) {
                onStop();
                setThreadEnded(false);
              }
            }, 20000);

            // Sla de referentie naar de nieuwe timeout op
            setStopTimeout(newTimeout);
          }
          break;

        // Handle the other tags here...
        case 'button':
          const buttonAction = fullMatch.match(/<button:(.*?)>/i);
          if (buttonAction && buttonAction[1]) {
            const button = <button key={`button-${index}`}
              onClick={(e) => { handleSubmit(e, buttonAction[1]); }}>
              {buttonAction[1]}
            </button>;
            tempButtonElements.push(button);
          }
          break;

        case 'multichoice':
          const multiChoiceMatch = fullMatch.match(/multichoice:(.*)>/);
          break;

        case 'box':
          break;

        case 'photoupload':
          tempInputElements.push(
            <div key={fullMatch} className="ai-upload">
              <input id="file-upload" key={`input-${inputIndex}`} type="file" name={`input-${inputIndex++}`} placeholder={content} onChange={handleUpload} />
              <label htmlFor="file-upload"><PhotoOutlined />Kies bestand</label>
            </div>
          );
          break;

        case 'textarea':
          const matchResult = fullMatch.match(/textarea:(.*)>/);
          const textareaAction = matchResult ? matchResult[1] : '';
          if (textareaAction === '') return;

          const element = <TextareaAutosize key={`textarea-${index}`} name={`input-${inputIndex++}`} placeholder={textareaAction} onChange={handleInputChange}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {

                event.preventDefault();
                handleSubmit(event, event.target.value);
                event.target.value = '';
              }
            }
            }
          />;
          tempInputElements.push(element);

          // return (
          //   <TextareaAutosize
          //     key={`textarea-${index}`}
          //     name={`input-${inputIndex++}`}
          //     placeholder={content}
          //     onChange={handleInputChange}
          //   />
          // );
          break;

        default:
          return generateMarkDown(part, index);
      }

    }).filter(Boolean);

    setButtonElements(tempButtonElements);
    setInputElements(tempInputElements);
    setElements(tempElements);
    return elements;
  };

  const generateMarkDown = (part, index) => {

    if (!part) return;

    //const cleaned = removeEmptyMarkdown(part);
    let adjustedMarkdown = part.replace(/\n/g, '\n\n');

    // Find JSON objects in the text
    const jsonMatch = adjustedMarkdown.match(/```json([\s\S]*?)```/g);

    if (jsonMatch) {
      // For each JSON object found, parse and pretty-print it
      jsonMatch.forEach((jsonString) => {
        const jsonContent = jsonString.replace(/```json|```/g, '').trim();
        try {
          const parsedJson = JSON.parse(jsonContent);
          let gridOutput = '';
          for (const key in parsedJson) {
            if (parsedJson.hasOwnProperty(key)) {
              gridOutput += `${key}: ${parsedJson[key]}\n`;
            }
          }
          adjustedMarkdown = adjustedMarkdown.replace(jsonContent, gridOutput);

        } catch (error) {
          console.error('Invalid JSON:', error);
        }
      });
    }

    const markdownHtml = marked(adjustedMarkdown);

    if (markdownHtml !== '') {
      return <div key={`markdown-${index}`} dangerouslySetInnerHTML={{ __html: markdownHtml }} />;
    }
    return null;
  }

  const textAreaRef = useRef(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleTextareaChange = (e) => {
    //if (isLoading == true) return;
    setTextAreaValue(e.target.value);

    if (textAreaRef?.current?.scrollHeight > textAreaRef?.current?.offsetHeight) {
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }

    setInputs((prevInputs) => ({
      ...prevInputs,
      ['textarea']: e.target.value,
    }));
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function () {
      setInputs((prevInputs) => ({
        ...prevInputs,
        [file.name]: reader.result,
      }));

      setSelectedFile({
        file: file,
        dataUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = (e, value) => {
    if (e) e.preventDefault();

    if (!value) {
      value = textAreaValue;
    }

    if (e != null) e.preventDefault();
    console.log('submitting');
    //setIsLoading(true);

    // if (!tempInputs) {
    //     tempInputs = inputs;
    // }

    let formattedData = Object.entries(inputs)
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ');

    if (formattedData === '' && !value) {
      formattedData = "Geen invoer, probeer verder te gaan zonder invoer."
    } else if (value) {
      formattedData = value;
    }

    setBusy(true);
    onSubmit(formattedData);
    //onSubmit(value);
    setTextAreaValue('');
    setInputs({});
    setSelectedFile(null);
    setButtonElements([]);
    setInputElements([]);
  };

  const removeEmptyMarkdown = (text) => {
    // text = text.replace(/\n-\s*(\n|$)/g, '\n');
    // text = text.replace(/\n#+\s*(\n|$)/g, '\n');
    // text = text.replace(/\*\*\s*\*\*/g, '');
    // text = text.replace(/\*\s*\*/g, '');
    // text = text.replace(/~~\s*~~/g, '');
    // text = text.replace(/```\s*```/g, '');
    // text = text.replace(/`\s*`/g, '');
    // text = text.replace(/\n\s*\n/g, '\n');
    return text;
  };

  return (
    <React.Fragment>
      {elements?.length > 0 && (
        <div className='assistant-response'>
          <div className="ai-response-container">
            {/* <form onSubmit={handleSubmit}> */}
            <div className="ai-response">
              {elements.map((element, index) => (
                <div key={index} className="ai-response-element">
                  {element}
                </div>
              ))}
            </div>
          </div>

          {threadEnded && (
            <div className="ai-response-message">
              <div className={`activity-loading-info`} style={{ left: '25px' }}>
                <span>sluit automatisch.. </span>
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { clearTimeout(stopTimeout); setStopTimeout(stopTimeout); }}>annuleer</span>
              </div>
              <div className="activity-loading-bar" style={{ width: 'calc(100% + 0px)', animation: 'fillBar 20s linear forwards' }}></div>
            </div>
          )}

        </div>
      )}

      {activityElements?.length > 0 && activity == null && (
        <div className='assistant-info'>
          <div className="ai-response-container">
            <div className="ai-close" onClick={() => { onSubmit("user closed the information tag"); }}>x</div>
            <div className="ai-response">
              {activityElements.map((element, index) => (
                <div key={index} className="ai-response-element">
                  {element}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}


      {/* {activity && !inLine && (
        <div className={`assistant-${activity.type}`}>
          <div className="ai-response-container">
            <div className="ai-close" onClick={() => { dispatch(setActivity(null)); }}>x</div>
            <div className="ai-response">
              <div className="ai-response-element">
                <div dangerouslySetInnerHTML={{ __html: marked(activity.payload?.description ? activity.payload?.description : (activity.payload?.content ? activity.payload?.content : "")) }} />
              </div>
            </div>
          </div>
        </div>
      )} */}

      {buttonElements.length > 0 && (
        <div className="ai-response-buttons">
          {buttonElements.map((button, index) => (
            button
          ))}
        </div>
      )}

      {inputElements.length > 0 && (
        <div className="ai-response-input">
          {inputElements.map((input, index) => (
            input
          ))}
        </div>
      )}

      {(!busy && (inLine || (elements.length > 0 && inputElements.filter(input => { return input.props.type !== "file"; }).length == 0 && threadStatus == "active"))) && (
        <div className='ai-options-auto'>
          <TextareaAutosize
            type="text"
            ref={textAreaRef}
            value={textAreaValue}
            onChange={handleTextareaChange}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                handleSubmit(event, textAreaValue);
              }
            }}
            placeholder={"Typ hier je reactie"}
          />
          <div className="icon-wrapper">
            <div onClick={() => { onAction('camera'); }} onMouseEnter={() => { VideoPlayerRef.current?.playFromBeginning() }}>
              <Player
                ref={VideoPlayerRef}
                size={28}
                icon={CAMERA}
                colorize='#DD91D5'
              />
            </div>
            <div onClick={() => { handleSubmit(); }} onMouseEnter={() => { PlayPlayerRef.current?.playFromBeginning() }}>
              <Player
                ref={PlayPlayerRef}
                size={28}
                icon={PLAY}
                colorize='#DD91D5'
              />
            </div>
          </div>
        </div>

      )}

      {selectedFile && (
        <React.Fragment>
          <div className='ai-information'>
            <div className="ai-image-message">
              <Tooltip title={selectedFile.file.name} arrow>
                <img src={selectedFile.dataUrl} alt="Geüploade afbeelding" />
              </Tooltip>
              <IconButton onClick={() => setSelectedFile(null)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="ai-response-buttons">
            <button onClick={handleSubmit}>Verstuur</button>
          </div>
        </React.Fragment>
      )
      }
      {/* <div className="ai-response-buttons">
          {buttonElements.map((button, index) => (
            <div key={index} className="ai-response-button">
              {button}
            </div>
          ))}
        </div> */}
      {/* </form> */}

      {/* <div className="ai-response-buttons">
          {buttonElements.map((button, index) => (
            <div key={index} className="ai-response-button">
              {button}
            </div>
          ))}
        </div> */}
      {/* </form> */}
    </React.Fragment >


  );
};

export default StreamingAIResponse;
