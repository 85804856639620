import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAssistantsRequest, getAssistantsRequest, deleteAssistantRequest } from '../../sagas/assistants/actions';
import '../../assets/styles/management/assistants.css';
import TextareaAutosize from 'react-textarea-autosize';
import Modal from '../Modal'; // Adjust the path to match your file structure

const Assistants = () => {
    const dispatch = useDispatch();
    const assistants = useSelector(state => state.assistants.assistants); // replace with actual selector
    const notifications = useSelector(state => state.main.notifications);

    const [selectedAssistant, setSelectedAssistant] = useState(null);
    const [selectedFunctionIndex, setSelectedFunctionIndex] = useState(null);

    const [jsonError, setJsonError] = useState(null);
    const [jsonInput, setJsonInput] = useState('');
    const [highlightedJson, setHighlightedJson] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteAssistantModalOpen, setIsDeleteAssistantModalOpen] = useState(false);
    const [isNewFunctionModalOpen, setIsNewFunctionModalOpen] = useState(false);
    const [utterancesInput, setUtterancesInput] = useState('');
    const [statusMessages, setStatusMessages] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isAddingFunction, setIsAddingFunction] = useState(false);

    const handleSelectFunction = (index) => {
        setSelectedFunctionIndex(index);
        const json = JSON.stringify(selectedAssistant.functions[index].function, null, 2);
        setJsonInput(json);
        setHighlightedJson(syntaxHighlight(json));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSaving(true);
        const utterancesArray = utterancesInput.split('\n');
        const updatedAssistant = { ...selectedAssistant, utterances: utterancesArray };
        dispatch(saveAssistantsRequest(updatedAssistant)); // Dispatch the action here
    };

    const handleSelectAssistant = (assistant) => {
        setSelectedAssistant(assistant);
        setSelectedFunctionIndex(null);
        setStatusMessages([]);
    };

    const handleAddAssistant = () => {
        setSelectedAssistant({ name: '', goal: '', description: '', utterances: [], prompt: '', functions: [], model: '' });
        setSelectedFunctionIndex(null);
    };

    const handleDeleteAssistant = () => {
        setIsDeleteAssistantModalOpen(true);
        setSelectedFunctionIndex(null);
    };

    const handleAddFunction = () => {
        const jsonTemplate = {
            name: "function_name",
            parameters: {
                type: "object",
                properties: {
                    property_name: {
                        type: "string",
                        description: "the description of the property, to be used by the assistant"
                    }
                },
                required: []
            },
            description: "description of the function, to be used by the assistant"
        };
        setJsonInput(JSON.stringify(jsonTemplate, null, 2));
        setJsonError(null);
        setIsNewFunctionModalOpen(true);
    };

    const handleRemoveFunction = (index) => {
        setSelectedAssistant({ ...selectedAssistant, functions: selectedAssistant.functions.filter((_, i) => i !== index) });
    };

    const handleFunctionChange = (index, value) => {
        const newFunctions = [...selectedAssistant.functions];
        newFunctions[index] = { type: 'function', function: value };
        setSelectedAssistant({ ...selectedAssistant, functions: newFunctions });
    };

    const handleDeleteFunction = (e, index) => {
        e.stopPropagation(); // Prevent triggering handleSelectFunction
        setIsModalOpen(true);
    };

    useEffect(() => {
        setHighlightedJson(syntaxHighlight(jsonInput));
    }, [jsonInput]);

    useEffect(() => {
        setUtterancesInput(selectedAssistant?.utterances.join('\n'));
    }, [selectedAssistant]);

    useEffect(() => {
        notifications.forEach(notification => {
            if (notification.payload.source === 'assistant'
                && notification.payload.type === 'status'
                && notification.payload.status == "success") {

                const assistantId = notification.payload.id;
                const message = notification.payload.message;

                dispatch(getAssistantsRequest());

                setTimeout(() => {
                    // Reset loading states based on notification
                    setIsSaving(false);
                    setIsDeleting(false);
                    setIsAddingFunction(false);

                    setStatusMessages(prevState => ({
                        ...prevState,
                        [assistantId]: message
                    }));

                    setTimeout(() => {
                        setStatusMessages(prevState => {
                            const { [assistantId]: _, ...rest } = prevState;
                            return rest;
                        });
                    }, 10000);

                }, 1500);
            } else {
                // handle error here 

            }
        });
    }, [notifications]);

    const handleJsonChange = (e) => {
        setJsonInput(e.target.value);
        try {
            const value = JSON.parse(e.target.value);
            handleFunctionChange(selectedFunctionIndex, value);
            setJsonError(null);
        } catch (err) {
            setJsonError('Invalid JSON');
        }
    };

    return (
        <div>
            <div className="assistants">
                <div className="assistants-list">
                    <h1>Assistants</h1>
                    <div className="assistant-item" onClick={handleAddAssistant}>Add New Assistant</div>
                    {assistants.map(assistant => (
                        <div key={assistant._id} className="assistant-item" onClick={() => handleSelectAssistant(assistant)}>
                            {assistant.name}
                            {statusMessages[assistant._id] && <span className="status-message"> - {statusMessages[assistant._id]}</span>}
                        </div>
                    ))}
                </div>
                {selectedAssistant && (
                    <div className="assistant-preview">
                        <div className="assistant-preview-header">
                            <h1>{selectedAssistant._id ? `Wijzig ${selectedAssistant.name} Assistant` : 'Maak een nieuwe assistent'}</h1>
                            <div className="assistant-preview-header-actions">
                                {statusMessages[selectedAssistant._id] && <span className="status-message">{statusMessages[selectedAssistant._id]}</span>}
                                {selectedAssistant._id && <button type="button" onClick={handleDeleteAssistant}>{isDeleting ? 'Deleting...' : 'Delete Assistant'}</button>}
                                <button type="submit" onClick={handleSubmit}>{isSaving ? 'Saving...' : 'Save'}</button>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="assistant-form">
                            <label>
                                <span>Naam</span>
                                <input type="text" value={selectedAssistant.name} onChange={e => setSelectedAssistant({ ...selectedAssistant, name: e.target.value })} />
                            </label>
                            <label>
                                <span>Doel</span>
                                <input type="text" value={selectedAssistant.goal} onChange={e => setSelectedAssistant({ ...selectedAssistant, goal: e.target.value })} />
                            </label>
                            <label>
                                <span>Omschrijving</span>
                                <TextareaAutosize value={selectedAssistant.description} onChange={e => setSelectedAssistant({ ...selectedAssistant, description: e.target.value })} />
                            </label>
                            <label>
                                <span>Prompt</span>
                                <TextareaAutosize value={selectedAssistant.prompt} onChange={e => setSelectedAssistant({ ...selectedAssistant, prompt: e.target.value })} />
                            </label>
                            <label>
                                <span>Activaties <br />(route)</span>
                                <TextareaAutosize value={utterancesInput} onChange={e => setUtterancesInput(e.target.value)} />
                            </label>
                            <div className="functions">
                                <div className="functions-list">
                                    Functions:
                                    {selectedAssistant.functions?.map((f, index) => (
                                        <div key={index} className={`function-item ${index === selectedFunctionIndex ? 'selected' : ''}`} onClick={() => handleSelectFunction(index)}>
                                            {f && f.function && f.function.name}
                                        </div>
                                    ))}
                                    <button type="button" onClick={() => { setIsAddingFunction(true); handleAddFunction(); }}>{isAddingFunction ? 'Adding...' : 'Voeg toe'}</button>
                                </div>
                                {selectedFunctionIndex !== null && (
                                    <div className='function-editor-container'>
                                        <span>{jsonError ? <div style={{ color: 'red' }}>{jsonError}</div> : <div style={{ color: 'green' }}>Valid JSON</div>}</span>
                                        <div className="function-editor-buttons">
                                            <button type="button" onClick={(e) => handleDeleteFunction(e, selectedFunctionIndex)}>Delete</button>
                                        </div>
                                        {isEditing ? (
                                            <TextareaAutosize
                                                className="function-editor"
                                                value={jsonInput}
                                                onChange={handleJsonChange}
                                                onBlur={() => setIsEditing(false)}
                                                style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', padding: '10px', borderRadius: '14px', wordWrap: 'break-word' }}
                                            />
                                        ) : (
                                            <div
                                                className="function-editor"
                                                dangerouslySetInnerHTML={{ __html: highlightedJson }}
                                                onClick={() => setIsEditing(true)}
                                                style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', padding: '10px', borderRadius: '14px', wordWrap: 'break-word', cursor: 'text' }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                )}
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <h2>Confirm Delete</h2>
                <p>Are you sure you want to delete this function?</p>
                <button onClick={() => {
                    const newFunctions = [...selectedAssistant.functions];
                    newFunctions.splice(selectedFunctionIndex, 1);
                    setSelectedAssistant({ ...selectedAssistant, functions: newFunctions });
                    setSelectedFunctionIndex(null);
                    setIsModalOpen(false);
                }}>Yes</button>
                <button onClick={() => setIsModalOpen(false)}>No</button>
            </Modal>
            <Modal isOpen={isDeleteAssistantModalOpen} onClose={() => setIsDeleteAssistantModalOpen(false)}>
                <h2>Confirm Delete</h2>
                <p>Are you sure you want to delete this assistant?</p>
                <button onClick={() => {
                    dispatch(deleteAssistantRequest(selectedAssistant._id)); // replace with actual action
                    setSelectedAssistant(null);
                    setIsDeleteAssistantModalOpen(false);
                    dispatch(getAssistantsRequest()); // replace with actual action
                }}>Yes</button>
                <button onClick={() => setIsDeleteAssistantModalOpen(false)}>No</button>
            </Modal>
            <Modal isOpen={isNewFunctionModalOpen} onClose={() => setIsNewFunctionModalOpen(false)}>
                <h2>Add New Function</h2>
                <span>{jsonError ? <div style={{ color: 'red' }}>{jsonError}</div> : <div style={{ color: 'green' }}>Valid JSON</div>}</span>
                <TextareaAutosize
                    className="function-editor"
                    value={jsonInput}
                    onChange={handleJsonChange}
                    style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', padding: '10px', borderRadius: '14px', wordWrap: 'break-word' }}
                />
                <button onClick={() => {
                    try {
                        let newFunction = JSON.parse(jsonInput);
                        if (!newFunction.name || !newFunction.parameters || !newFunction.description) {
                            setJsonError('Invalid JSON: missing required fields');
                            return;
                        }
                        if (/\s/.test(newFunction.name)) {
                            setJsonError('Function name cannot contain spaces');
                            return;
                        }
                        if (selectedAssistant && selectedAssistant.functions) {
                            if (selectedAssistant.functions.some(f => f.function.name === newFunction.name)) {
                                setJsonError('Function name already exists');
                                return;
                            }
                            newFunction = { function: newFunction };
                            setSelectedAssistant({
                                ...selectedAssistant,
                                functions: [...selectedAssistant.functions, newFunction]
                            });
                            setIsAddingFunction(false);
                        } else {
                            console.error('selectedAssistant or selectedAssistant.functions is undefined');
                        }
                        setIsNewFunctionModalOpen(false);
                    } catch (err) {
                        setJsonError('Invalid JSON');
                    }
                }}>Voeg toe</button>
                <button onClick={() => { setIsNewFunctionModalOpen(false); setIsAddingFunction(false); }}>Annuleer</button>
            </Modal>
        </div>
    );
};

function syntaxHighlight(json) {
    if (typeof json !== 'string') {
        json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        let cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}

export default Assistants;
