import React from 'react';
import { useDispatch } from 'react-redux';
import { sendMessage } from '../Actions';

function ActionWidget({ title, data }) {
  const dispatch = useDispatch();

  const handleAction = (action) => {
    switch (action.type) {
      case 'chat':
        dispatch(sendMessage({
          type: "user",
          message: action.payload.message,
          thread_id: null,
          route: 'default' //action.payload.route
        }));
        break;
      case 'open':
        window.open(action.payload.url, '_blank');
        break;
      case 'approve':
        // Implement approve action logic
        console.log('Approving task:', action.payload.task_id);
        break;
      default:
        console.warn('Unknown action type:', action.type);
    }
  };

  return (
    <div className="action-widget">
      {/* <h2 className="text-lg font-semibold mb-2">{title}</h2> */}

        {data.actions.map((action, index) => (
          <button
            key={index}
            className="other-item action-item"
            onClick={() => handleAction(action)} G
          >
            <img src="e-icon-1.png" alt="" />
            {action.label}
          </button>
        ))}

    </div>
  );
}

export default ActionWidget;