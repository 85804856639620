import { call, put, takeEvery, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { GET_TICKETS_REQUEST, getTicketsSuccess, getTicketsFailure } from './actions';
import { connectSocket, getSocket } from '../../components/Socket';

function createSocketChannel(socket) {
  return eventChannel(emit => {
    const handler = (data) => {
      emit(data);
    };
    socket.on('tickets', handler);
    return () => {
      socket.off('tickets', handler);
    };
  });
}

function* fetchTicketsSaga() {
  try {
    const socket = getSocket();
    if (!socket) {
      console.log('Socket is not initialized');
      return;
    }

    const socketChannel = yield call(createSocketChannel, socket);
    socket.emit('get_tickets');

    while (true) {
      const tickets = yield take(socketChannel);
      yield put(getTicketsSuccess(tickets));
    }
    
  } catch (error) {
    yield put(getTicketsFailure(error.toString()));
  }
}

export function* watchFetchTicketsSaga() {
  yield takeEvery(GET_TICKETS_REQUEST, fetchTicketsSaga);
}