import React, { useEffect, useState } from 'react';
import { initAudioContext } from './AudioUtils';

const AudioInitializer = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  const initializeAudio = async () => {
    if (!isInitialized) {
      await initAudioContext();
      setIsInitialized(true);
    }
  };

  useEffect(() => {
    // Attempt to initialize on mount, but this may fail due to autoplay policies
    initializeAudio();
  }, []);

  return null;

  // return (
  //   <button onClick={initializeAudio} disabled={isInitialized}>
  //     {isInitialized ? 'Audio Initialized' : 'Initialize Audio'}
  //   </button>
  // );
};

export default AudioInitializer;