export const GET_INSTRUCTION_REQUEST = 'GET_INSTRUCTION_REQUEST';
export const GET_INSTRUCTION_SUCCESS = 'GET_INSTRUCTION_SUCCESS';
export const GET_INSTRUCTION_FAILURE = 'GET_INSTRUCTION_FAILURE';

export const SAVE_INSTRUCTION_REQUEST = 'SAVE_INSTRUCTION_REQUEST';
export const SAVE_INSTRUCTION_SUCCESS = 'SAVE_INSTRUCTION_SUCCESS';
export const SAVE_INSTRUCTION_FAILURE = 'SAVE_INSTRUCTION_FAILURE';

export const DELETE_INSTRUCTION_REQUEST = 'DELETE_INSTRUCTION_REQUEST';
export const DELETE_INSTRUCTION_SUCCESS = 'DELETE_INSTRUCTION_SUCCESS';
export const DELETE_INSTRUCTION_FAILURE = 'DELETE_INSTRUCTION_FAILURE';

export const getInstructionRequest = (organizationId) => ({
    type: GET_INSTRUCTION_REQUEST,
    payload: { organizationId }
});

export const getInstructionSuccess = instruction => ({ type: GET_INSTRUCTION_SUCCESS, payload: instruction });
export const getInstructionFailure = error => ({ type: GET_INSTRUCTION_FAILURE, payload: error });

export const saveInstructionRequest = instruction => ({ type: SAVE_INSTRUCTION_REQUEST, payload: instruction });
export const saveInstructionSuccess = () => ({ type: SAVE_INSTRUCTION_SUCCESS });
export const saveInstructionFailure = error => ({ type: SAVE_INSTRUCTION_FAILURE, payload: error });

export const deleteInstructionRequest = instruction => ({ type: DELETE_INSTRUCTION_REQUEST, payload: instruction });
export const deleteInstructionSuccess = () => ({ type: DELETE_INSTRUCTION_SUCCESS });
export const deleteInstructionFailure = error => ({ type: DELETE_INSTRUCTION_FAILURE, payload: error });