export const sendMessage = message => ({
  type: 'SEND_MESSAGE',
  payload: message,
});

export const setThread = thread => ({
  type: 'SET_THREAD',
  payload: thread,
});

export const setThreadStatus = status => ({
  type: 'SET_THREAD_STATUS',
  payload: status,
});

export const sendMessageReply = message => ({
  type: 'SEND_MESSAGE_REPLY',
  payload: message,
});

export const clearChat = message => ({
  type: 'CLEAR_CHAT',
  payload: message,
});

export const stopChat = message => ({
  type: 'STOP_CHAT',
  payload: message,
});

export const resetTasks = () => ({
  type: 'RESET_TASKS',
});

//sendSubscription
export const sendSubscription = () => ({
  type: 'SEND_SUBSCRIPTION'
});

export const fetchMessages = () => ({
  type: 'FETCH_MESSAGES'
});

export const clearNotification = notificationId => ({
  type: 'CLEAR_NOTIFICATION',
  payload: notificationId,
});

export const clearTicketNotification = notificationId => ({
  type: 'CLEAR_TICKET_NOTIFICATION',
  payload: notificationId,
});

export const setDelta = delta => ({
  type: 'SET_DELTA',
  payload: delta,
});

export const setActivity = activity => ({
  type: 'SET_ACTIVITY',
  payload: activity,
});

export const resetRuns = () => ({
  type: 'RESET_RUNS',
});