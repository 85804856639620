import axios from 'axios';

// Action types
export const GET_FLOWS_REQUEST = 'GET_FLOWS_REQUEST';
export const GET_FLOWS_SUCCESS = 'GET_FLOWS_SUCCESS';
export const GET_FLOWS_FAILURE = 'GET_FLOWS_FAILURE';
export const SAVE_FLOW_REQUEST = 'SAVE_FLOW_REQUEST';
export const SAVE_FLOW_SUCCESS = 'SAVE_FLOW_SUCCESS';
export const SAVE_FLOW_FAILURE = 'SAVE_FLOW_FAILURE';
export const DELETE_FLOW_REQUEST = 'DELETE_FLOW_REQUEST';
export const DELETE_FLOW_SUCCESS = 'DELETE_FLOW_SUCCESS';
export const DELETE_FLOW_FAILURE = 'DELETE_FLOW_FAILURE';
export const RUN_FLOW_REQUEST = 'RUN_FLOW_REQUEST';
export const RUN_FLOW_SUCCESS = 'RUN_FLOW_SUCCESS';
export const RUN_FLOW_FAILURE = 'RUN_FLOW_FAILURE';
export const DEBUG_FLOW_REQUEST = 'DEBUG_FLOW_REQUEST';
export const DEBUG_FLOW_SUCCESS = 'DEBUG_FLOW_SUCCESS';
export const DEBUG_FLOW_FAILURE = 'DEBUG_FLOW_FAILURE';
export const GET_RUNS_REQUEST = 'GET_RUNS_REQUEST';
export const GET_RUNS_SUCCESS = 'GET_RUNS_SUCCESS';
export const GET_RUNS_FAILURE = 'GET_RUNS_FAILURE';
export const SAVE_FLOW = 'SAVE_FLOW';
export const DELETE_FLOW = 'DELETE_FLOW';
export const RUN_FLOW = 'RUN_FLOW';
export const DEBUG_FLOW = 'DEBUG_FLOW';
export const GET_FLOWS = 'GET_FLOWS';
export const GET_RUNS = 'GET_RUNS';

// Simplified action creators
export const getFlows = () => ({
    type: GET_FLOWS,
});

export const getFlowsSuccess = (data) => ({
    type: GET_FLOWS_SUCCESS,
    payload: data,
});

export const getFlowsFailure = (error) => ({
    type: GET_FLOWS_FAILURE,
    error,
});

export const saveFlow = (flow) => ({
    type: SAVE_FLOW,
    payload: flow,
});

export const saveFlowSuccess = (data) => ({
    type: SAVE_FLOW_SUCCESS,
    payload: data,
});

export const saveFlowFailure = (error) => ({
    type: SAVE_FLOW_FAILURE,
    error,
});

export const deleteFlow = (flow) => ({
    type: DELETE_FLOW,
    payload: flow,
});

export const deleteFlowSuccess = (flowId) => ({
    type: DELETE_FLOW_SUCCESS,
    payload: flowId,
});

export const deleteFlowFailure = (error) => ({
    type: DELETE_FLOW_FAILURE,
    error,
});

export const runFlow = (flow) => ({
    type: RUN_FLOW,
    payload: flow,
});

export const runFlowSuccess = (data) => ({
    type: RUN_FLOW_SUCCESS,
    payload: data,
});

export const runFlowFailure = (error) => ({
    type: RUN_FLOW_FAILURE,
    error,
});

export const debugFlow = (flow) => ({
    type: DEBUG_FLOW,
    payload: flow,
});

export const debugFlowSuccess = (data) => ({
    type: DEBUG_FLOW_SUCCESS,
    payload: data,
});

export const debugFlowFailure = (error) => ({
    type: DEBUG_FLOW_FAILURE,
    error,
});

export const getRuns = () => ({
    type: GET_RUNS,
});

export const getRunsSuccess = (data) => ({
    type: GET_RUNS_SUCCESS,
    payload: data,
});

export const getRunsFailure = (error) => ({
    type: GET_RUNS_FAILURE,
    error,
});