import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import Assistants from './Assistants';
//import Agents from './Agents';
import Knowledge from './Knowledge';
import Instructions from './Instructions';
import { getAssistantsRequest } from '../../sagas/assistants/actions';
//import { getAgentsRequest } from '../../sagas/agents/actions';
import { getKnowledgeRequest } from '../../sagas/knowledge/actions';
import { getInstructionRequest } from '../../sagas/instructions/actions';
import { getFlows } from '../../sagas/flows/actions';
import Flows from './flows/Flows';

const Management = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        switch (location.pathname) {
            case '/management/assistants':
                dispatch(getAssistantsRequest());
                break;

            case '/management/flows':
                dispatch(getFlows());
                break;
            // case '/management/agents':
            //     dispatch(getAgentsRequest());
            //     break;
            case '/management/knowledge':
                dispatch(getKnowledgeRequest());
                break;
            case '/management/instructions':
                dispatch(getInstructionRequest());
                break;
            default:
                break;
        }
    }, [dispatch, location]);

    return (
        <div>
            <nav>
                <ul>
                    <li><Link to="/management/assistants">Assistants</Link></li>
                    <li><Link to="/management/flows">Flows</Link></li>
                    <li><Link to="/management/knowledge">Knowledge</Link></li>
                    <li><Link to="/management/instructions">Instructions</Link></li>
                </ul>
            </nav>

            <Routes>
                <Route path="assistants" element={<Assistants />} />
                <Route path="flows" element={<Flows />} />
                <Route path="knowledge" element={<Knowledge />} />
                <Route path="instructions" element={<Instructions />} />
            </Routes>
        </div>
    );
}

export default Management;