export function timeSince(date) {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    const seconds = Math.floor((new Date() - date) / 1000);
    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
        return interval + " jaren geleden";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return interval + " maanden geleden";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + " dagen geleden";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + " uur geleden";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + " minuten geleden";
    }
    //return Math.floor(seconds) + " seconden geleden";
    return "zojuist";
}

export function getBackendUrl() {

    if (process.env.NODE_ENV === 'development' || window.location.origin.includes('localhost')) {
        return 'http://localhost:8081';
        //socketUrl = 'https://demo.evolv2.ai'
    } else {
        return window.location.origin + '/backend';
    }
}


export function syntaxHighlight(json) {
    if (typeof json !== 'string') {
        json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        let cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}
