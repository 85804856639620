import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../assets/styles/EvaInput.css';
import FileIcon from '@mui/icons-material/AttachmentOutlined';
import PhotoIcon from '@mui/icons-material/PhotoCameraOutlined';
import { sendMessage, stopChat, setActivity } from './Actions';
import StreamingAIResponse from './StreamingAIResponse';
import { TextareaAutosize } from '@mui/material';
import AIResponseParser from './AIResponseParser';

const EVAinput = ({ placeholder = "Typ hier je reactie" }) => {
    const activity = useSelector(state => state.main.activity);
    const thread = useSelector(state => state.main.thread);
    const running_threads = useSelector(state => state.main.running_threads);
    const [activeChat, setActiveChat] = useState(false);
    const [activeActivity, setActiveActivity] = useState(null);
    const delta = useSelector(state => state.main.delta);
    const [busy, setBusy] = useState(false);
    const tasks = useSelector(state => state.main.tasks);
    //const [messages, setMessages] = useState([]);

    // useEffect(() => {
    //     //check if a tasks has a message key
    //     if (tasks?.length > 0) {
    //         setMessages(prevMessages => [...prevMessages, ...tasks.filter(task => task.message).map(task => ({ type: 'bot', text: task.message }))]);
    //     }
    // }, [tasks]);

    useEffect(() => {

        if(activeActivity?._id != activity?._id) { 
            if (activeChat) {
                dispatch(stopChat());
                setActiveChat(false);
            }
            setActiveActivity(activity);
        }


    }, [activity]);

    //on dismount, reset the chat
    useEffect(() => {
        return () => {
            if (activeChat) {
                dispatch(stopChat());
            }
        }
    }, []);

    const dispatch = useDispatch();
    // const botMessages = messages.filter(message => message.type === 'bot');
    // const lastBotMessage = botMessages[botMessages.length - 1];

    const handleSubmit = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {

            let message = {};

            if (activity === null) {
                message = {
                    type: 'user',
                    message: `${e.target.value}`,
                    thread_id: thread,
                    route: null
                }
            }
            else {
                message = {
                    type: 'user',
                    //message: `The user has activity open and visible: ${activity._id} which is a ${activity.type} with ${activity.type} id: ${activity.object_id}, and about: ${activity.content}. Only if needed you can get the payload of the activity. Try to do a follow-up. The chat of the user: ${e.target.value}.`,
                    message: `Frondend: User has opened activity: ${activity.title} with id ${activity._id}. Which is of type ${activity.type}. The type payload: ${JSON.stringify(activity.payload)}. Help the user with his message about the payload, the user message: ${e.target.value}`,
                    thread_id: thread,
                    route: null
                }
            }

            dispatch(sendMessage(message));
            setActiveChat(true);
            e.target.value = "";
        }
    }

    const resetChat = () => {
        setActiveChat(false);
        setBusy(false);
        dispatch(stopChat());
    }

    const handleDataSubmit = (data) => {

        let thread_data = running_threads.find(t => t.thread_id === thread);


        let message = {
            type: 'user',
            message: data,
            thread_id: thread,
            route: thread_data?.route
        }

        dispatch(sendMessage(message));
    }

    const textAreaRef = React.createRef();
    const [textAreaValue, setTextAreaValue] = useState("");

    const handleTextareaChange = (e) => {
        setTextAreaValue(e.target.value);
    }

    const doLoading = (loading) => {
        setBusy(loading);
    }

    return (
        <div className="eva-circle">
            <div class="icon-circle-small">
                <div class="icon-small">
                    {busy && <div className="loader"></div>}
                    {!busy && (
                        <React.Fragment>
                            <div class="icon-inner-circle-small">
                                <div class="icon-triangle-small"></div>
                            </div>
                            <div class="icon-label-small gradient-text ">EVA</div>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className="eva-reaction">
                {delta && activeChat && (
                    <StreamingAIResponse
                        delta={delta}
                        doLoading={doLoading}
                        onSubmit={handleDataSubmit}
                        onStop={resetChat}
                        inLine={true}
                    />)
                }

                {/* {lastBotMessage && activeChat && (
                    <AIResponseParser
                        aiResponse={lastBotMessage?.text}
                        loading={busy}
                        typing={true}
                        //onAction={onAction}
                        onSubmit={handleDataSubmit}
                        onStop={resetChat}
                        //onChange={setInterface}
                        //submitSimpleOption={submitSimpleOption}
                    />
                )} */}

                {!activeChat && (
                    <div className={`eva-input`}>

                        <TextareaAutosize
                            type="text"
                            ref={textAreaRef}
                            value={textAreaValue}
                            onChange={handleTextareaChange}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter' && !event.shiftKey) {
                                    event.preventDefault();
                                    handleSubmit(event);
                                }
                            }}
                            placeholder={placeholder}
                            style={{ minHeight: "34px" }} />
                        <div className="icons">
                            {/* <FileIcon /> */}
                            <PhotoIcon />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EVAinput;