import { call, put, take, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { GET_INSTRUCTION_REQUEST, getInstructionSuccess, getInstructionFailure, SAVE_INSTRUCTION_REQUEST, saveInstructionSuccess, saveInstructionFailure, DELETE_INSTRUCTION_REQUEST, deleteInstructionSuccess, deleteInstructionFailure } from './actions';
import { getSocket } from '../../components/Socket';
import { all } from 'redux-saga/effects';

function createSocketChannel(socket) {
  return eventChannel(emit => {
    const handler = (data) => {
      emit(data);
    };
    socket.on('instruction', handler);
    return () => {
      socket.off('instruction', handler);
    };
  });
}

function* fetchInstructionSaga(action) {
  try {
    const socket = getSocket();
    if (!socket) {
      console.log('Socket is not initialized');
      return;
    }

    const socketChannel = yield call(createSocketChannel, socket);
    const organizationId = action.payload.organizationId;
    socket.emit('get_instruction', organizationId);

    while (true) {
      const instruction = yield take(socketChannel);
      yield put(getInstructionSuccess(instruction));
    }

  } catch (error) {
    yield put(getInstructionFailure(error.toString()));
  }
}

function* saveInstructionSaga(action) {
  try {
    const socket = getSocket();
    if (!socket) {
      console.log('Socket is not initialized');
      return;
    }

    socket.emit('save_instruction', action.payload);
    yield put(saveInstructionSuccess());

  } catch (error) {
    yield put(saveInstructionFailure(error.toString()));
  }
}

function* deleteInstructionSaga(action) {
  try {
    const socket = getSocket();
    if (!socket) {
      console.log('Socket is not initialized');
      return;
    }

    socket.emit('delete_instruction', action.payload);
    yield put(deleteInstructionSuccess());

  } catch (error) {
    yield put(deleteInstructionFailure(error.toString()));
  }
}

export function* watchInstructionSagas() {
  yield takeEvery(GET_INSTRUCTION_REQUEST, fetchInstructionSaga);
  yield takeEvery(SAVE_INSTRUCTION_REQUEST, saveInstructionSaga);
  yield takeEvery(DELETE_INSTRUCTION_REQUEST, deleteInstructionSaga);
}