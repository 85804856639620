import { GET_TICKETS_REQUEST, GET_TICKETS_SUCCESS, GET_TICKETS_FAILURE } from './actions';

const initialState = {
  tickets: [],
  loading: false,
  error: null,
};

export default function ticketsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TICKETS_REQUEST:
      return { ...state, loading: true };
    case GET_TICKETS_SUCCESS:
      return { ...state, loading: false, tickets: action.payload };
    case GET_TICKETS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}