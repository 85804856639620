export const GET_ASSISTANTS_REQUEST = 'GET_ASSISTANTS_REQUEST';
export const GET_ASSISTANTS_SUCCESS = 'GET_ASSISTANTS_SUCCESS';
export const GET_ASSISTANTS_FAILURE = 'GET_ASSISTANTS_FAILURE';

export const SAVE_ASSISTANTS_REQUEST = 'SAVE_ASSISTANTS_REQUEST';
export const SAVE_ASSISTANTS_SUCCESS = 'SAVE_ASSISTANTS_SUCCESS';
export const SAVE_ASSISTANTS_FAILURE = 'SAVE_ASSISTANTS_FAILURE';

export const DELETE_ASSISTANT_REQUEST = 'DELETE_ASSISTANT_REQUEST';
export const DELETE_ASSISTANT_SUCCESS = 'DELETE_ASSISTANT_SUCCESS';
export const DELETE_ASSISTANT_FAILURE = 'DELETE_ASSISTANT_FAILURE';

export const getAssistantsRequest = () => ({ type: GET_ASSISTANTS_REQUEST });
export const getAssistantsSuccess = assistants => ({ type: GET_ASSISTANTS_SUCCESS, payload: assistants });
export const getAssistantsFailure = error => ({ type: GET_ASSISTANTS_FAILURE, payload: error });

export const saveAssistantsRequest = assistants => ({ type: SAVE_ASSISTANTS_REQUEST, payload: assistants });
export const saveAssistantsSuccess = () => ({ type: SAVE_ASSISTANTS_SUCCESS });
export const saveAssistantsFailure = error => ({ type: SAVE_ASSISTANTS_FAILURE, payload: error });

export const deleteAssistantRequest = assistant => ({ type: DELETE_ASSISTANT_REQUEST, payload: assistant });
export const deleteAssistantSuccess = () => ({ type: DELETE_ASSISTANT_SUCCESS });
export const deleteAssistantFailure = error => ({ type: DELETE_ASSISTANT_FAILURE, payload: error });