import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { combineReducers } from 'redux';
import ticketsReducer from '../sagas/tickets/reducer';
import rootReducer from './Reducer';
import rootSaga from './Saga'; // importeer je root saga
import { assistantsReducer } from '../sagas/assistants/reducer';
import { agentsReducer } from '../sagas/agents/reducer';
import { knowledgeReducer } from '../sagas/knowledge/reducer';
import { instructionsReducer } from '../sagas/instructions/reducer';
import activitiesReducer from '../sagas/activities/reducer';
import flowReducer from '../sagas/flows/reducer';
import tasksReducer from '../sagas/tasks/reducer';

// Maak de saga middleware aan
const sagaMiddleware = createSagaMiddleware();

// Combineer je reducers
const reducer = combineReducers({
  tickets: ticketsReducer,
  main: rootReducer,
  assistants: assistantsReducer,
  agents: agentsReducer,
  knowledge: knowledgeReducer,
  activities: activitiesReducer,
  instructions: instructionsReducer,
  flows: flowReducer,
  tasks: tasksReducer,
  // voeg hier andere reducers toe...
});

// Maak de store aan en voeg de saga middleware toe
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

// Run de root saga
sagaMiddleware.run(rootSaga);

export default store;