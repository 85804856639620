// TicketDetail.js
import React, { useState, useEffect } from 'react';
import '../../assets/styles/details/TicketDetail.css';
import { timeSince } from '../GeneralFunctions';

function TicketDetail({ ticket, short }) {
  const [selectedTicket, setSelectedTicket] = useState();
  const [actions, setActions] = useState();

  useEffect(() => {
    setSelectedTicket(ticket);
    setActions(
      ticket?.actions?.map((t, index) => {
        return (
          <div key={index} className="card action">
            <p>{t.content}</p>
            <span>{timeSince(new Date(t.entryDate))}</span>
          </div>
        );
      })
    );
  }, [ticket]);

  if (selectedTicket && !short)
    return (
      <div>
        <div className="task-info">
          <h2>#{selectedTicket.number}: {selectedTicket.title}</h2>
          <div className="card">
            <p>{selectedTicket.description}</p>
          </div>
        </div>

        <div className="actions-container">
          <h2>Acties</h2>
          {actions}
        </div>
      </div>
    );

  if (selectedTicket && short)
    return (
      <div style={{ flex: 1 }}>
        <div className="task-info">
          <div className='task-title'>
            <h2>#{selectedTicket.number}</h2>
            <h2>{selectedTicket.short_title}</h2>
          </div>
          <div className="task-shorts">
            <div>{timeSince(new Date(selectedTicket.modificationDate))}</div>
            {selectedTicket.type?.call && (<div>{selectedTicket.type?.call}</div>)}
            {/* <div>{selectedTicket.status?.content}</div> */}
            {selectedTicket.action_status != "none" && (<div>{selectedTicket.action_status}</div>)}
          </div>

        </div>
        {/* <div>{selectedTicket.action_status}</div> */}
      </div>
    );
}

export default TicketDetail;
