import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
    GET_FLOWS_REQUEST, GET_FLOWS_SUCCESS, GET_FLOWS_FAILURE,
    SAVE_FLOW_REQUEST, SAVE_FLOW_SUCCESS, SAVE_FLOW_FAILURE,
    DELETE_FLOW_REQUEST, DELETE_FLOW_SUCCESS, DELETE_FLOW_FAILURE,
    RUN_FLOW_REQUEST, RUN_FLOW_SUCCESS, RUN_FLOW_FAILURE,
    DEBUG_FLOW_REQUEST, DEBUG_FLOW_SUCCESS, DEBUG_FLOW_FAILURE,
    GET_RUNS_REQUEST, GET_RUNS_SUCCESS, GET_RUNS_FAILURE,
    GET_FLOWS, SAVE_FLOW, DELETE_FLOW, RUN_FLOW, DEBUG_FLOW, GET_RUNS
} from './actions';


if (process.env.NODE_ENV === 'development' || window.location.origin.includes('localhost')) {
    axios.defaults.baseURL = 'http://localhost:8081';
}

// Updated sagas using axios directly
function* getFlowsSaga() {
    yield put({ type: GET_FLOWS_REQUEST });
    try {
        const response = yield call(axios.get, `/flows`);
        yield put({ type: GET_FLOWS_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: GET_FLOWS_FAILURE, error });
    }
}

function* saveFlowSaga(action) {
    yield put({ type: SAVE_FLOW_REQUEST });
    try {
        let response;
        if (action.payload._id) {
            response = yield call(axios.put, `/flows/${action.payload._id}`, action.payload);
        } else {
            response = yield call(axios.post, `/flows`, action.payload);
        }
        yield put({ type: SAVE_FLOW_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: SAVE_FLOW_FAILURE, error });
    }
}

function* deleteFlowSaga(action) {
    yield put({ type: DELETE_FLOW_REQUEST });
    try {
        yield call(axios.delete, `/flows/${action.payload}`);
        yield put({ type: DELETE_FLOW_SUCCESS, payload: action.payload });
    } catch (error) {
        yield put({ type: DELETE_FLOW_FAILURE, error });
    }
}

function* runFlowSaga(action) {
    yield put({ type: RUN_FLOW_REQUEST });
    try {
        const response = yield call(axios.post, `/flows/${action.payload}/run`);
        yield put({ type: RUN_FLOW_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: RUN_FLOW_FAILURE, error });
    }
}

function* debugFlowSaga(action) {
    yield put({ type: DEBUG_FLOW_REQUEST });
    try {
        const response = yield call(axios.post, `/flows/${action.payload}/debug`);
        yield put({ type: DEBUG_FLOW_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: DEBUG_FLOW_FAILURE, error });
    }
}

function* getRunsSaga(action) {
    yield put({ type: GET_RUNS_REQUEST });
    try {
        const response = yield call(axios.get, `/flows/runs`, { params: action.payload });
        yield put({ type: GET_RUNS_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: GET_RUNS_FAILURE, error });
    }
}

export function* flowSaga() {
    yield takeEvery(GET_FLOWS, getFlowsSaga);
    yield takeEvery(SAVE_FLOW, saveFlowSaga);
    yield takeEvery(DELETE_FLOW, deleteFlowSaga);
    yield takeEvery(RUN_FLOW, runFlowSaga);
    yield takeEvery(DEBUG_FLOW, debugFlowSaga);
    yield takeEvery(GET_RUNS, getRunsSaga);
}