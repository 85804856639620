import {
    GET_KNOWLEDGE_REQUEST, GET_KNOWLEDGE_SUCCESS, GET_KNOWLEDGE_FAILURE,
    SAVE_KNOWLEDGE_REQUEST, SAVE_KNOWLEDGE_SUCCESS, SAVE_KNOWLEDGE_FAILURE,
    DELETE_KNOWLEDGE_REQUEST, DELETE_KNOWLEDGE_SUCCESS, DELETE_KNOWLEDGE_FAILURE
} from './actions';

const initialState = {
    knowledge: [],
    loading: false,
    error: null,
};

export function knowledgeReducer(state = initialState, action) {
    switch (action.type) {
        case GET_KNOWLEDGE_REQUEST:
            return { ...state, loading: true, error: null };
        case GET_KNOWLEDGE_SUCCESS:
            return { ...state, loading: false, knowledge: action.payload };
        case GET_KNOWLEDGE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case SAVE_KNOWLEDGE_REQUEST:
            return { ...state, loading: true, error: null };
        case SAVE_KNOWLEDGE_SUCCESS:
            return { ...state, loading: false };
        case SAVE_KNOWLEDGE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case DELETE_KNOWLEDGE_REQUEST:
            return { ...state, loading: true, error: null };
        case DELETE_KNOWLEDGE_SUCCESS:
            return { ...state, loading: false };
        case DELETE_KNOWLEDGE_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
}