// socket.js
import io from 'socket.io-client';

let socket;

export const connectSocket = (accessToken) => {
  let socketUrl = null;
  if (process.env.NODE_ENV === 'development' || window.location.origin.includes('localhost')) {
    socketUrl = 'http://localhost:8081';
    //socketUrl = 'https://demo.evolv2.ai'
  } else {
    socketUrl = window.location.origin;
  }

  const newSocket = io(socketUrl, {
    query: { token: accessToken },
    //path: process.env.NODE_ENV === 'development' || window.location.origin.includes('localhost') ? '/backend/socket.io' : '/backend/socket.io',
    //path: '/socket.io/',
    secure: true,
    reconnection: true, // Enable automatic reconnection
    reconnectionAttempts: Infinity, // Don't give up trying to reconnect
    reconnectionDelay: 1000, // Start with 1 second delay, then increase
    reconnectionDelayMax: 5000, // But don't wait more than 5 seconds
    timeout: 20000, // If the server doesn't respond within 20 seconds, consider it disconnected
    //transports: ["websocket", "polling"]
  });
  socket = newSocket;
  return newSocket;
};

export const getSocket = () => socket;