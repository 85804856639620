import React from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';

const CodeEditor = ({ value, onChange, readOnly }) => {
    return (
        <AceEditor
            mode="python"
            theme="monokai"
            onChange={onChange}
            value={value}
            name="UNIQUE_ID_OF_DIV"
            editorProps={{ $blockScrolling: true }}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 4,
            }}
            readOnly={readOnly}
            style={{ width: '100%', height: '400px' }}
        />
    );
};

export default CodeEditor;
