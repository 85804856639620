import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CheckCircleOutlineOutlined, EventBusyOutlined } from '@mui/icons-material';
import { timeSince } from './GeneralFunctions';
import { Player } from '@lordicon/react';

const DECIDING = require('../assets/system-regular-197-calculate.json');
const CREATING = require('../assets/system-regular-22-build.json');
const SEARCHING = require('../assets/system-regular-42-search.json');
const COMPLETED = require('../assets/system-regular-31-check.json');
const LOADER = require('../assets/system-regular-716-spinner-three-dots.json');
const ERROR = require('../assets/system-regular-55-error.json');

const DisplayRuns = () => {
    const runs = useSelector(state => state.main.runs);
    const [currentRun, setCurrentRun] = useState(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const [runQueue, setRunQueue] = useState([]);
    const playerRef = useRef(null);
    const thread = useSelector(state => state.main.thread);

    useEffect(() => {
        playerRef.current?.playFromBeginning();
    }, []);

    useEffect(() => {
        if (currentRun) {
            //playerRef.current?.load(); // Zorg ervoor dat de nieuwe icon geladen is
            playerRef.current?.playFromBeginning(); // Start de animatie
        }
    }, [currentRun]);

    useEffect(() => {
        // Add new runs to the queue
        if (runs.length > 0) {
            const latestRun = runs[runs.length - 1];
            setRunQueue(prevQueue => [...prevQueue, latestRun]);
        } else {
            setRunQueue([]); // Clear the queue
            currentRun && setCurrentRun(null); // Clear the current run
        }
    }, [runs]);

    useEffect(() => {
        if (runQueue.length > 0) { //&& !isAnimating) {
            //setIsAnimating(true); // Start animation
            setCurrentRun(runQueue[0]);

            // Remove the displayed run from the queue after 4 seconds
            const timer = setTimeout(() => {
                setRunQueue(prevQueue => prevQueue.slice(1));
                //setIsAnimating(false); // End animation
            }, 4000); // Ensure each run is displayed for at least 4 seconds

            //return () => clearTimeout(timer); // Cleanup on unmount or before the next update
        }
    }, [runQueue, isAnimating]);

    //states: starting, searching, creating, removing, changing, deciding, completed, error

    return (
        <React.Fragment>
            {(currentRun && currentRun["thread_id"] == thread) && (
                <div className={`eva-runs ${isAnimating ? 'animate' : ''}`}>

                    <div className="card eva-run">
                        <div className="eva-run-icon">
                            {currentRun.run_state === 'completed' && (

                                <Player
                                    ref={playerRef}
                                    size={28}
                                    icon={COMPLETED}
                                    colorize='#FFFFFF'
                                />

                            )}
                            {currentRun.run_state === 'running' && (
                                <Player
                                    ref={playerRef}
                                    size={28}
                                    icon={
                                        currentRun.state === 'deciding' ? DECIDING :
                                            currentRun.state === 'creating' ? CREATING :
                                                currentRun.state === 'searching' ? SEARCHING :
                                                    LOADER // Default or fallback icon
                                    }
                                    colorize='#FFFFFF'
                                    onComplete={() => playerRef.current?.playFromBeginning()}
                                />
                            )}
                            {currentRun.run_state === 'error' && (
                                <Player
                                    ref={playerRef}
                                    size={28}
                                    icon={ERROR}
                                    colorize='#FFFFFF'
                                    onComplete={() => playerRef.current?.playFromBeginning()}
                                />
                            )}
                        </div>
                        <div className="eva-run-info">
                            <span className="eva-run-status">{currentRun.status}</span>
                            <span className='eva-run-time'>{timeSince(new Date(currentRun.updated_at).toLocaleString())}</span>
                        </div>
                    </div>

                </div>
            )}
        </React.Fragment>
    );
};

export default DisplayRuns;