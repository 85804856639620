import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getAudioContext, processAudioChunk, pausePlayback, resetAudioPlayback, isPlayingAudio, resumePlayback, isActiveAudio } from './AudioUtils';
import { useSelector } from 'react-redux';
import { useAccount, useMsal } from "@azure/msal-react";

const AudioCapture = ({ isListening, onEnd, onTranscribing, currentThread, currentRoute }) => {
  const threadRef = useRef(currentThread);
  const routeRef = useRef(currentRoute);
  const isSessionActive = useRef(false);
  const isInterruptionActive = useRef(false);
  const isInterrupted = useRef(null);
  const transcript = useRef('');
  const mediaRecorderRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const lowPassFilterRef = useRef(null);
  const socketRef = useRef(null);
  const silenceStartRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  const streamRef = useRef(null);
  const latestTranscriptRef = useRef('');
  const audioPlayerRef = useRef(null);
  const latestSpeechRef = useRef(null);
  const isStartedRef = useRef(false);
  const hasSendAudioRef = useRef(false);
  const [isDucking, setIsDucking] = useState(false);
  const duckingRef = useRef(null);
  const threadStatus = useSelector((state) => state.main.status);
  const threadId = useSelector((state) => state.main.thread);
  const [threadStatusLocal, setThreadStatusLocal] = useState('idle');
  const { accounts, instance, inProgress } = useMsal();
  const account = useAccount(accounts[0] || null);
  const sessionId = useRef(null);

  const isVoiceDetectedRef = useRef(false);
  const voiceBufferRef = useRef([]);
  const lastVoiceActivityRef = useRef(null);

  // VAD constants
  const SAMPLE_RATE = 16000;
  const FFT_SIZE = 512;
  const SMOOTHING_TIME_CONSTANT = 0.5;
  const ZCR_THRESHOLD = 0.1;
  const VOICE_PADDING_MS = 100; // padding before and after voice activity

  const SHORT_SILENCE_DURATION = 300; // 200ms for short silence
  const LONG_SILENCE_DURATION = 1200; // 700ms for long silence
  const VOICE_THRESHOLD = -80; // in dB, adjusted for quicker silence detection
  const SILENCE_THRESHOLD = -75; // in dB, threshold for silence
  const SPEECH_FREQ_MIN = 200;
  const SPEECH_FREQ_MAX = 3500;
  const VOICE_HOLD_TIME = 100; // ms to hold voice detection state
  const SILENCE_HOLD_TIME = 50; // ms to hold silence detection state

  const voiceThresholdRef = useRef(-80);
  const silenceThresholdRef = useRef(-75);

  const transcriptionChunksRef = useRef([]);
  const lastTranscriptionTimeRef = useRef(null);
  const longSilenceTimeoutRef = useRef(null);
  const isWaitingForTranscriptionRef = useRef(false);
  const lastVoiceDetectionRef = useRef(0);
  const lastSilenceDetectionRef = useRef(0);
  const shortSilenceTimeoutRef = useRef(null);

  useEffect(() => {
    threadRef.current = currentThread;
    console.log('Updated threadRef in AudioCapture:', threadRef.current);
  }, [currentThread]);

  useEffect(() => {
    routeRef.current = currentRoute;
    console.log('Updated routeRef in AudioCapture:', routeRef.current);
  }, [currentRoute]);

  useEffect(() => {
    if (threadStatus === 'idle' && threadStatusLocal === 'active') {
      setThreadStatusLocal('idle');
      endSession();
    }
  }, [threadStatus, threadStatusLocal]);

  const initializeWebSocket = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (socketRef.current) {
        socketRef.current.close();
      }

      //if (process.env.NODE_ENV === 'development' || window.location.origin.includes('localhost')) {
      socketRef.current = new WebSocket('ws://localhost:8000/ws');
      //} else {
      // Extract the hostname and port from the origin
      //  const url = new URL(window.location.origin);
      //  const socketUrl = url.hostname + (url.port ? `:${url.port}` : '');
      //   socketRef.current = new WebSocket(`wss://${socketUrl}/ws`);
      // }   

      socketRef.current.onopen = () => {
        console.log('WebSocket connected');
        resolve();
      };

      socketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log('Received data:', data);

        if (data.enhanced) {
          data.text = data.enhanced;
        }

        if (data.thread_id) {
          threadRef.current = data.thread_id;
        }

        if (data.audio) {
          resetAudioPlayback();
          processAudioChunk(data.audio, 0, 44100, true);
        }

        if ((data.text || data.text == '') && !data.gibberish && (data.relevant == null || data.relevant)) {
          handleTranscription(data.text);
        }

        //if (data.didnt_understand) {
        if (data.gibberish) {
          console.log("Think its gibberish, resuming");
          resumePlayback();
        }

        if (data.text == "" && isActiveAudio()) {
          resumePlayback();
        }
      };

      socketRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        reject(error);
      };

      socketRef.current.onclose = () => {
        console.log('WebSocket closed, attempting to reconnect...');
        setTimeout(() => {
          initializeWebSocket().catch((error) => {
            console.error('Reconnection failed:', error);
          });
        }, 5000); // Attempt to reconnect after 5 seconds
      };

      socketRef.current.onclose = (event) => {
        console.log('WebSocket closed:', event);
      };
    });
  }, []);

  const startDucking = () => {
    setIsDucking(true);
    if (analyserRef.current) {
      duckingRef.current = analyserRef.current.minDecibels;
      analyserRef.current.minDecibels = -50; // Adjust this value as needed
    }
  };

  const stopDucking = () => {
    setIsDucking(false);
    if (analyserRef.current && duckingRef.current !== null) {
      analyserRef.current.minDecibels = duckingRef.current;
    }
  };

  useEffect(() => {
    if (isPlayingAudio()) {
      startDucking();
    } else {
      stopDucking();
    }
  }, [isPlayingAudio]);

  const initAudioContext = useCallback(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({ sampleRate: SAMPLE_RATE });
    analyserRef.current = audioContextRef.current.createAnalyser();
    analyserRef.current.fftSize = FFT_SIZE;
    analyserRef.current.smoothingTimeConstant = SMOOTHING_TIME_CONSTANT;

    lowPassFilterRef.current = audioContextRef.current.createBiquadFilter();
    lowPassFilterRef.current.type = 'lowpass';
    lowPassFilterRef.current.frequency.value = SPEECH_FREQ_MAX;
  }, []);

  const calibrateThresholds = useCallback(() => {
    return new Promise((resolve) => {
      console.log("Starting calibration...");
      let samples = 0;
      let totalEnergy = 0;

      const calibrationInterval = setInterval(() => {
        if (!analyserRef.current) return;

        const bufferLength = analyserRef.current.frequencyBinCount;
        const dataArray = new Float32Array(bufferLength);
        analyserRef.current.getFloatFrequencyData(dataArray);

        const speechStart = Math.floor(SPEECH_FREQ_MIN / (audioContextRef.current.sampleRate / analyserRef.current.fftSize));
        const speechEnd = Math.floor(SPEECH_FREQ_MAX / (audioContextRef.current.sampleRate / analyserRef.current.fftSize));

        let sum = 0;
        let count = 0;
        for (let i = speechStart; i < speechEnd; i++) {
          sum += dataArray[i];
          count++;
        }

        const averageEnergy = sum / count;
        totalEnergy += averageEnergy;
        samples++;

        if (samples >= 10) { // Take 10 samples over 1 second
          clearInterval(calibrationInterval);
          const ambientLevel = totalEnergy / samples;

          // Set thresholds based on the ambient level
          silenceThresholdRef.current = ambientLevel + 20; // 10 dB above ambient
          voiceThresholdRef.current = ambientLevel + 20; // 20 dB above ambient

          //check if -Infinity
          if (!Number.isFinite(ambientLevel)) {
            //ambientLevel = -80; // Ensure it doesn't go below -100 dB
            silenceThresholdRef.current = -80; // Set a default value
            voiceThresholdRef.current = -70; // Set a default value
          }

          console.log(`Calibration complete. Ambient level: ${ambientLevel}, Silence threshold: ${silenceThresholdRef.current}, Voice threshold: ${voiceThresholdRef.current}`);
          resolve();
        }
      }, 100); // Sample every 100ms
    });
  }, []);

  const startSession = useCallback(async () => {
    if (streamRef.current && !isInterruptionActive.current) {
      console.log('Session already active, not starting a new one');
      return;
    }

    sessionId.current = Date.now();

    console.log('Starting new session');
    transcript.current = '';
    latestSpeechRef.current = null;

    try {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
      streamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        }
      });
      console.log('Audio stream obtained');

      initAudioContext();
      const source = audioContextRef.current.createMediaStreamSource(streamRef.current);
      source.connect(lowPassFilterRef.current);
      lowPassFilterRef.current.connect(analyserRef.current);
      console.log('Audio context and analyser set up');

      await initializeWebSocket();
      await calibrateThresholds();
      isStartedRef.current = false;
      await startRecording();
      isSessionActive.current = true;
      setThreadStatusLocal('active');

    } catch (error) {
      console.error('Error starting session:', error);
    }
  }, [initializeWebSocket, initAudioContext]);

  const startRecording = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current = null;
      }

      voiceBufferRef.current = [];

      if (!streamRef.current) {
        console.error('No audio stream available');
        reject(new Error('No audio stream available'));
        return;
      }

      console.log('Starting MediaRecorder');
      mediaRecorderRef.current = new MediaRecorder(streamRef.current, {
        mimeType: 'audio/webm; codecs=opus'
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          //voiceBufferRef.current.push(event.data);

          if (voiceBufferRef.current.length < 16) { //make sure we have enough data to send
            voiceBufferRef.current.push(event.data);
          } else if (isVoiceDetectedRef.current) {
            voiceBufferRef.current.push(event.data);
            lastVoiceActivityRef.current = Date.now();
          } else if (lastVoiceActivityRef.current && Date.now() - lastVoiceActivityRef.current < VOICE_PADDING_MS) {
            voiceBufferRef.current.push(event.data);
          }
        }
      };

      mediaRecorderRef.current.onstart = () => {
        console.log('MediaRecorder started');
        resolve();
      };

      mediaRecorderRef.current.onerror = (event) => {
        console.error('MediaRecorder error:', event.error);
        reject(event.error);
      };

      mediaRecorderRef.current.start(100); // Capture in 100ms chunks
    });
  }, []);

  const audioSendTimeoutRef = useRef(null);
  const sendAudioToServer = useCallback((isPartialTranscription) => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.onstop = () => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) { //&& voiceBufferRef.current.length > 16) {
          const audioBlob = new Blob(voiceBufferRef.current, { type: 'audio/webm' });
          const reader = new FileReader();

          reader.onloadend = () => {
            const base64Audio = reader.result.split(',')[1];
            const data = {
              thread_id: threadRef.current,
              upn: account.username,
              audio_data: base64Audio,
              is_partial: isPartialTranscription,
              session_id: sessionId.current
            };

            console.log('Sending audio, size:', audioBlob.size, 'Partial:', isPartialTranscription);
            socketRef.current.send(JSON.stringify(data));
            voiceBufferRef.current = [];
            hasSendAudioRef.current = true;
            isWaitingForTranscriptionRef.current = true;
            startRecording();
          };

          reader.readAsDataURL(audioBlob);
          //resetAudioPlayback();
        } else {
          console.log('Unable to send audio:', {
            socketOpen: socketRef.current && socketRef.current.readyState === WebSocket.OPEN,
            audioChunksLength: voiceBufferRef.current.length
          });

          // if (voiceBufferRef.current.length > 0) {
          //   if (audioSendTimeoutRef.current) {
          //     clearTimeout(audioSendTimeoutRef.current);
          //     audioSendTimeoutRef.current = null;
          //   }

          //   audioSendTimeoutRef.current = setTimeout(() => {
          //     sendAudioToServer();
          //   }, 100);
          // }
        }
      };
    }
  }, [startRecording, threadId]);

  const endSession = useCallback(() => {
    if (!mediaRecorderRef.current) return;
    if (!isStartedRef.current) return;

    console.log('Ending session');
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state === "recording") {
        mediaRecorderRef.current.stop();
      }
      mediaRecorderRef.current = null;
    }
    isSessionActive.current = false;
    setThreadStatusLocal('idle');

    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    if (sessionTimeoutRef.current) {
      clearTimeout(sessionTimeoutRef.current);
      sessionTimeoutRef.current = null;
    }

    analyserRef.current = null;
    voiceBufferRef.current = [];

    if (onEnd && transcript.current.trim() !== '') {
      resetAudioPlayback();
      console.log('Sending onEnd with transcript:', transcript.current);
      onEnd(transcript.current, threadRef.current, routeRef.current);

      if (isActiveAudio()) {
        resetAudioPlayback();
      }
    }

    transcript.current = '';
    latestTranscriptRef.current = '';
    latestSpeechRef.current = null;

  }, [onEnd]);

  const handleTranscription = useCallback((text, isPartial = false) => {
    isWaitingForTranscriptionRef.current = false;

    if (isPartial) {
      transcriptionChunksRef.current.push(text);
      latestTranscriptRef.current = transcriptionChunksRef.current.join(' ');
    } else {
      latestTranscriptRef.current = text;
      transcript.current = transcript.current + " " + text;
      transcriptionChunksRef.current = []; // Clear chunks after full transcription
    }

    if (onTranscribing && text !== '') {
      text = text.replace(/\*/g, '').trim();
      onTranscribing(text);
    }

    // Check if we should end the session
    if (!isVoiceDetectedRef.current && Date.now() - silenceStartRef.current >= LONG_SILENCE_DURATION) {
      if (!isInterruptionActive.current) {
        check_for_gibberish();
        endSession();
        checkForInterruption();
      } else if (isInterruptionActive.current) {
        if (text == "") {
          console.log("resuming playback");
          resumePlayback();
        } else {
          console.log("end session, check for new interruption");
          check_for_gibberish();
          endSession();
          checkForInterruption();
        }
      }
    }
  }, [onTranscribing, sendAudioToServer]);


  const check_for_gibberish = () => {
    socketRef.current.send(
      JSON.stringify({
        thread_id: threadRef.current,
        upn: account.username,
        check_gibberish: true,
        sessionId: sessionId.current
      }));
  };

  const calculateZeroCrossingRate = (buffer) => {
    let zeroCrossings = 0;
    for (let i = 1; i < buffer.length; i++) {
      if ((buffer[i - 1] >= 0 && buffer[i] < 0) || (buffer[i - 1] < 0 && buffer[i] >= 0)) {
        zeroCrossings++;
      }
    }
    return zeroCrossings / buffer.length;
  };

  const detectVoiceActivity = useCallback(() => {
    if (!analyserRef.current) return;

    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Float32Array(bufferLength);
    analyserRef.current.getFloatFrequencyData(dataArray);

    const speechStart = Math.floor(SPEECH_FREQ_MIN / (audioContextRef.current.sampleRate / analyserRef.current.fftSize));
    const speechEnd = Math.floor(SPEECH_FREQ_MAX / (audioContextRef.current.sampleRate / analyserRef.current.fftSize));

    let sum = 0;
    let count = 0;
    for (let i = speechStart; i < speechEnd; i++) {
      sum += dataArray[i];
      count++;
    }

    const averageEnergy = sum / count;
    const now = Date.now();

    let voiceDetected;
    if (averageEnergy > voiceThresholdRef.current) {
      voiceDetected = true;
      lastVoiceDetectionRef.current = now;
    } else if (averageEnergy < silenceThresholdRef.current) {
      voiceDetected = false;
      lastSilenceDetectionRef.current = now;
    } else {
      // In between thresholds, maintain previous state for a short time
      if (now - lastVoiceDetectionRef.current < VOICE_HOLD_TIME) {
        voiceDetected = true;
      } else if (now - lastSilenceDetectionRef.current < SILENCE_HOLD_TIME) {
        voiceDetected = false;
      } else {
        voiceDetected = isVoiceDetectedRef.current;
      }
    }

    // console.log('Voice detected:', voiceDetected, 'Energy:', averageEnergy);

    if (voiceDetected) {
      isVoiceDetectedRef.current = true;
      silenceStartRef.current = null;
      isStartedRef.current = true;
      lastVoiceActivityRef.current = now;

      // Clear any existing timeouts
      if (longSilenceTimeoutRef.current) {
        clearTimeout(longSilenceTimeoutRef.current);
        longSilenceTimeoutRef.current = null;
      }

      if (shortSilenceTimeoutRef.current) {
        clearTimeout(shortSilenceTimeoutRef.current);
        shortSilenceTimeoutRef.current = null;
      }

      if (isInterruptionActive.current && isPlayingAudio()) {
        if (!isInterrupted.current) isInterrupted.current = Date.now();

        if (Date.now() - isInterrupted.current > 200) {
          isInterrupted.current = null;
          pausePlayback();
        }
      }

    } else {

      if (isStartedRef.current && !silenceStartRef.current) {

        // Transition from voice to silence
        silenceStartRef.current = now;

        // Send partial transcription immediately after SHORT_SILENCE_DURATION
        shortSilenceTimeoutRef.current = setTimeout(() => {
          if (isVoiceDetectedRef.current && !isWaitingForTranscriptionRef.current) {
            isVoiceDetectedRef.current = false;
            console.log("Short silence detected, sending partial transcription");
            sendAudioToServer(true);
          }
        }, SHORT_SILENCE_DURATION);

        // Set up long silence timeout
        longSilenceTimeoutRef.current = setTimeout(() => {
          if (!isVoiceDetectedRef.current && !isWaitingForTranscriptionRef.current) {
            console.log("Long silence detected, sending full transcription");
            check_for_gibberish();
            if (!isWaitingForTranscriptionRef.current) {
              endSession();
              checkForInterruption();
            }
          }
        }, LONG_SILENCE_DURATION);
      }
    }

    if (isSessionActive.current) {
      requestAnimationFrame(detectVoiceActivity);
    }
  }, [isSessionActive.current, sendAudioToServer]);

  const checkForInterruption = useCallback(() => {
    startSession();
    isInterruptionActive.current = true;
  }, [startSession]);

  useEffect(() => {
    if (isListening && !isStartedRef.current) {
      console.log("STARTING SESSION");
      startSession();
    } else {
      console.log("ENDING SESSION");
      endSession();
    }
  }, [isListening]);

  useEffect(() => {
    if (isSessionActive.current || isInterruptionActive.current) {
      requestAnimationFrame(detectVoiceActivity);
    }

    return () => {
      if (sessionTimeoutRef.current) {
        clearTimeout(sessionTimeoutRef.current);
      }
    };
  }, [isSessionActive.current, detectVoiceActivity]);

  return null;
};

export default AudioCapture; 