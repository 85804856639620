
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendMessage, resetTasks, clearChat, stopChat, setThread } from './Actions';
import { useAccount, useMsal } from "@azure/msal-react";
import { useNavigate, useParams } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { nl } from 'date-fns/locale';
import { ReactComponent as Elevate } from '../assets/icons/elevate_symbol_brightcare.svg';

import '../assets/styles/Chatbot.css';
import TypingText from './TypingText';
import HelpOutline from '@mui/icons-material/HelpOutline';
import ProblemOutline from '@mui/icons-material/ErrorOutline';
import RequestOutline from '@mui/icons-material/TaskAltOutlined';
import ChangeOutline from '@mui/icons-material/ManageAccountsOutlined';
import SupportAgent from '@mui/icons-material/SupportAgentOutlined';
import Play from '@mui/icons-material/PlayCircleOutlineOutlined';
import Camera from '@mui/icons-material/Camera';
import AttachFile from '@mui/icons-material/AttachFile';
import Close from '@mui/icons-material/CloseOutlined';
import Face from '@mui/icons-material/FaceOutlined';
import CloudOff from '@mui/icons-material/CloudOff';
import Operator from '@mui/icons-material/SupportAgent';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Verified from '@mui/icons-material/VerifiedOutlined';

import AIResponseParser from './AIResponseParser';
import StreamingAIResponse from './StreamingAIResponse';
import TextareaAutosize from 'react-textarea-autosize';
import { use } from 'marked';
import { AdjustOutlined, CancelOutlined, DeleteOutlineRounded, LocalActivityOutlined, PauseCircleOutline, PendingOutlined, RemoveCircleOutline } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
// import { act } from 'react-dom/test-utils';
// import { generatePath, json } from 'react-router-dom';
// import { use } from 'marked';
// import { AttachFile, Camera } from '@mui/icons-material';

function Loader() {
  return <div className="loader"></div>;
}


function typeEffect(element, delay) {
  let i = 0;
  let message = '...';
  let interval = setInterval(function () {
    if (i < message.length) {
      element.append(message.charAt(i));
      i++;
    } else {
      element.innerHTML = '';
      i = 0;
    }
  }, delay);
}

function Chatbot({ modal = false }) {
  const connectionStatus = useSelector(state => state.main.connectionStatus);
  const prevConnectionStatus = useRef();
  const task = useSelector(state => state.main.lastTask);
  const thread_status = useSelector(state => state.main.status);
  const thread = useSelector(state => state.main.thread);
  const running_threads = useSelector(state => state.main.running_threads);
  const tasks = useSelector(state => state.main.tasks);
  const preOptions = useSelector(state => state.main.preOptions);
  const delta = useSelector(state => state.main.delta);
  //const socket = useSelector(state => state.main.socket);
  const dispatch = useDispatch();

  const { accounts, instance, inProgress } = useMsal();
  const account = useAccount(accounts[0] || null);
  const [submittedData, setSubmittedData] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [needInput, setNeedInput] = useState(true);
  const [needSubmit, setNeedSubmit] = useState(false);
  const [messages, setMessages] = useState([]);
  const [title, setTitle] = useState('');
  const [options, setOptions] = useState([]);
  const [aiOptions, setAiOptions] = useState([]);
  const [aiLearning, setAIlearning] = useState(false);
  const [busy, setBusy] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  //const [thread, setThread] = useState(null);
  const [actionMessages, setActionMessages] = useState([]);
  const [threadData, setThreadData] = useState(null);
  const [extraMessage, setExtraMessage] = useState('');

  // Camera state variables
  const [videoStream, setVideoStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [useFrontCamera, setUseFrontCamera] = useState(true);

  const thinkingRef = useRef(null);
  const [route, setRoute] = useState(null);
  const defaultOptions = [{ text: 'Ik wil iets wijzigen', image: 'HelpOutline' },
  { text: 'Ik heb een vraag', 'label': 'stellen', image: 'HelpOutline' },
  { text: 'Ik heb een verzoek', 'label': 'melden', image: 'HelpOutline' },
  { text: 'Ik heb een probleem', 'label': 'indienen', image: 'HelpOutline' }];

  let navigate = useNavigate();
  let { thread_id } = useParams();

  const updateThread = (id) => {
    //setThread(id);
    //if (thread == id) return;
    if (id == null) id = "";
    //strip thread_ from id 
    const data = id.replace('thread_', '');
    if (!modal) navigate(`/gesprek/${data}`);
  }

  useEffect(() => {
    if (thread_id) {
      // add thread_ to id again
      const id = 'thread_' + thread_id;

      if (thread != id) {

        //get from running_threads
        const thread = running_threads.find(t => t.thread_id == id);
        if (thread) {
          console.log('thread found: ', thread);
          setThreadById(thread);
        }
      }
      //setThread(id);
      //dispatch(setThread(id));
    }
  }, [thread_id]);


  useEffect(() => {
    if (thread) {
      const current_thread = running_threads.find(t => t.thread_id == thread);
      if (current_thread) {
        console.log('thread found: ', current_thread);
        setThreadById(current_thread, false);
      }
    }

  }, [thread, running_threads]);



  useEffect(() => {
    //check if a tasks has a message key
    if (tasks?.length > 0) {
      setTitle(tasks.filter(task => task.title).map(task => task.title).join(' '))
      setMessages(prevMessages => [...prevMessages, ...tasks.filter(task => task.message).map(task => ({ type: 'bot', text: task.message }))]);
    }
  }, []);


  useEffect(() => {
    if (thread_status == "running" || thread_status == "started") {
      setBusy(true);
    } else {
      setBusy(false);
    }
  }, [thread_status]);

  useEffect(() => {
    if (prevConnectionStatus.current == "disconnect" && connectionStatus == "connected") {
      console.log(`reconnected`);
      setBusy(false);
    }

    if (tasks?.length == 0 && !prevConnectionStatus.current && connectionStatus == "connected") {
      if (aiOptions.length === 0) {
        generateDefaultOptions();
      }
    }

    // Store current connection status in ref for next comparison
    prevConnectionStatus.current = connectionStatus;
  }, [connectionStatus]);


  useEffect(() => {
    //socket?.on('task', (task) => {
    if (task) {
      console.log('Message from server: ', task);

      if (task.status == "from_memory") {
        if (task.thread_data?.update_date && new Date(task.thread_data?.update_date) > new Date(new Date().getTime() - 60 * 60 * 1000)) {
          console.log("fresh thread data: ", task);
          updateThread(task.thread_id);
          setThreadData(task);
          let response = { type: 'bot', text: task.message };
          setActionMessages([]); //reset
          setMessages(prevMessages => [...prevMessages, response]);
          setOptions([]);
          setBusy(false);

        } else {
          // add to queue!
          console.log('need to add to queue: ', task);
        }
        return;
      }

      //starting different thread
      if (thread && task.thread_id && task.thread_id != thread) {
        console.log('changing thread: ', task.thread_id);
        updateThread(task.thread_id);
        setMessages([]);
        setOptions([]);
        setTitle('bezig met wijziging van gesprek..');
        setActionMessages([]);
      } else if (thread && task.thread_id && task.thread_id == thread) {
        console.log('continue thread: ', task.thread_id);
      } else if (!thread && task.thread_id) {
        console.log('starting thread: ', task.thread_id);
        updateThread(task.thread_id);
      }

      //add task to tasks, but in front of the array
      //setTasks(prevTasks => [task, ...prevTasks]);
      console.log('tasks: ', tasks)

      if (task.message) {
        let response = { type: 'bot', text: task.message };
        setActionMessages([]); //reset
        setMessages(prevMessages => [...prevMessages, response]);
        setOptions([]);
        setBusy(false);
      }

      if (task.title) {
        console.log('title: ', task.title)
        setTitle(task.title);
      }

      if (task.options) {
        setLoadingOptions(false);
        setOptions(task.options);
      }

      if (task.ailearnings) {
        setAIlearning(JSON.parse(task.ailearnings));
      }

      if (task.route) {
        setRoute(task.route);
      }

      if (task.status == 'completed') {
        setBusy(false);
      }

      if (task.thread_data) {
        //const data = JSON.parse(task.thread_data);
        const data = task.thread_data;

        //check if data is not older than 1 hour
        if (data && data.update_date && new Date(data.update_date) > new Date(new Date().getTime() - 60 * 60 * 1000))
          console.log("fresh thread data: ", data);
        setThreadData(data);
      }

      if (task.action == 'loading_options') {
        console.log('loading options..');
        setLoadingOptions(true);
      }

      if (task.status == "routing") {
        console.log('routing..');
        //setActionMessages(prevMessages => [...prevMessages, "doorzetten naar " + task.route]);
        setBusy(true);
      }

      if (task.action == 'action_message') {
        setActionMessages(prevMessages => [...prevMessages, task.action_message]);
      }
    }
    //});

    //socket?.on('ai-options', (message) => {
    if (preOptions && typeof preOptions === 'string') {
      var newOptions = JSON.parse(preOptions);
      setAiOptions(newOptions.options);
    }
    //});

    // return () => {
    //   console.log('Chatbot unmounting');
    //   socket?.off('task');
    //   socket?.off('ai-options');  // Voeg deze regel toe
    // };

  }, [task, preOptions]); // when socket is updated, the effect runs again

  useEffect(() => {
    return () => {
      console.log('Chatbot unmounting');
    };
  }, []);


  // // one time only effect here
  // useEffect(() => {
  //   if (options.length === 0) {
  //     //decide count options based on screen size
  //     let count = 4;

  //     if (window.innerHeight < 700) {
  //       count = 4;
  //     } else if (window.innerHeight < 1000) {
  //       count = 4;
  //     } else {
  //       count = 8;
  //     }

  //     //socket?.emit('get_options', count);
  //     dispatch(sendMessage(['get_options', count]));
  //   }
  // }, [connectionStatus]); // Lege array betekent dat het effect alleen bij de eerste render wordt uitgevoerd

  useEffect(() => {
    if (thinkingRef.current) {
      typeEffect(thinkingRef.current, 1000);
    }
  }, [thinkingRef]); // Voeg thinkingRef toe aan de afhankelijkheden van de hook

  useEffect(() => {
    const buttons = document.querySelectorAll('.default-ai-options button');
    buttons.forEach((button, index) => {
      button.style.setProperty('--i', index);
    });
  }, [aiOptions]);

  useEffect(() => {
    if (busy) {
      const buttons = document.querySelectorAll('.default-ai-options button');
      buttons.forEach(button => {
        button.classList.add('removing');
      });
      setTimeout(() => {
        setAiOptions([]);
      }, 500); // Wacht 500ms (de duur van de animatie) voordat je de opties verwijdert
    }
  }, [busy]);

  const generateDefaultOptions = () => {
    //decide count options based on screen size
    let count = 4;

    if (window.innerHeight < 700) {
      count = 4;
    } else if (window.innerHeight < 1000) {
      count = 4;
    } else {
      count = 8;
    }

    //socket?.emit('get_options', count);
    dispatch(sendMessage(['get_options', count]));
  }

  const fileInput = React.useRef();

  // Modify the handleCameraClick function to start the camera
  const handleCameraClick = async () => {
    setIsCameraButtonClicked(true);
    setTimeout(() => {
      setIsCameraButtonClicked(false);
    }, 2000); // 2000ms = 2s

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: useFrontCamera ? 'user' : 'environment' }
      });
      setVideoStream(stream);
      setIsCameraActive(true);
    } catch (err) {
      console.error("Failed to access camera:", err);
    }
  };


  const switchCamera = () => {
    setUseFrontCamera(!useFrontCamera);

    // Stop the current video stream
    if (videoStream) {
      videoStream.getTracks().forEach(track => track.stop());
      setVideoStream(null);
    }

    // Start the new video stream
    handleCameraClick();
  };

  // Add a new function to take the picture
  const takePicture = async () => {
    if (!videoStream) {
      console.error("No video stream available");
      return;
    }

    const video = document.createElement('video');
    video.srcObject = videoStream;
    await video.play();

    // Create a canvas and draw the current frame from the video onto it
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0);

    // Now you have an image as a data URL
    const imageUrl = canvas.toDataURL('image/png');

    // Set the captured image
    setCapturedImage(imageUrl);
  };

  const sendPicture = () => {
    if (!capturedImage) {
      console.error("No captured image available");
      return;
    }

    // Send the image URL and the extra message as a message
    let message = {
      type: 'user',
      message: "image: " + capturedImage + "\n" + extraMessage,
      thread_id: thread,
      route: route
    }

    setBusy(true);
    dispatch(sendMessage(JSON.stringify(message)));

    // Reset the camera, captured image, and extra message
    setCapturedImage(null);
    setIsCameraActive(false);
    setExtraMessage('');
    if (videoStream) {
      videoStream.getTracks().forEach(track => track.stop());
      setVideoStream(null);
    }
  };

  // Add a new function to stop the camera
  const stopCamera = () => {
    if (videoStream) {
      videoStream.getTracks().forEach(track => track.stop());
      setVideoStream(null);
    }
    setIsCameraActive(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      // The file's content will be available here as a data URL
      const fileUrl = reader.result;

      // Send the file URL as a message
      let message = {
        type: 'user',
        message: "file: " + file.name + " contents: " + fileUrl + "\n User message: " + inputValue,
        thread_id: thread,
        route: route
      }

      setBusy(true);
      dispatch(sendMessage(JSON.stringify(message)));
    };
    reader.readAsDataURL(file);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);

    if (e.target.value.length > 0) {
      setNeedSubmit(true);
    } else {
      setNeedSubmit(false);
    }
  };

  // Add a new method to handle option submission
  const submitSimpleOption = (option) => {
    if (busy) return;
    setBusy(true);
    const newMessage = { type: 'user', text: option };
    setMessages([...messages, newMessage]);
    //setOptions([]);

    let message = {
      type: 'user',
      message: "user selected option: " + option,
      thread_id: thread,
      route: route
    }

    dispatch(sendMessage(JSON.stringify(message)));
    //socket.emit('chat_message', JSON.stringify(message));
  }

  // const closeOverlay = () => {
  //   console.log('closeOverlay called');
  //   const newMessage = { type: 'user', text: 'Nee, help mij op een andere manier' };
  //   setMessages([...messages, newMessage]);
  //   //setInputValue('');


  //   let message = {
  //     type: 'user',
  //     message: newMessage.text,
  //     thread_id: thread,
  //     route: route
  //   }

  //   //socket.emit('chat_message', JSON.stringify(message));
  //   dispatch(sendMessage(JSON.stringify(message)));
  // }

  // Add new state variables for whether the modals are closing
  const [isCameraClosing, setIsCameraClosing] = useState(false);
  const [isImageClosing, setIsImageClosing] = useState(false);

  // Modify the closeOverlay function to set isCameraClosing or isImageClosing to true
  const closeOverlay = () => {
    if (isCameraActive) {
      setIsCameraClosing(true);
      setTimeout(() => {
        console.log('videoStream: ', videoStream);
        if (videoStream) {
          videoStream.getTracks().forEach(track => track.stop());
          setVideoStream(null);
        }
        setIsCameraActive(false);
        setIsCameraClosing(false);
      }, 500);
    }
    if (capturedImage) {
      setIsImageClosing(true);
      setTimeout(() => {
        setCapturedImage(null);
        setIsImageClosing(false);
      }, 500);
    }
  };

  const [isCameraButtonClicked, setIsCameraButtonClicked] = useState(false);


  const handleDataSubmit = (data) => {
    console.log('Verzamelde Data:', data); // Of doe iets anders met deze data
    setSubmittedData(data);
    setBusy(true);
    setOptions([]);

    // Voeg logica toe om berichten te verwerken
    const newMessage = { type: 'user', text: data };
    setMessages([...messages, newMessage]);
    setInputValue('');

    let message = {
      type: 'user',
      message: data,
      thread_id: thread,
      route: route
    }

    //socket.emit('chat_message', JSON.stringify(message));
    dispatch(sendMessage(JSON.stringify(message)));

  };

  const stopRunningChat = (running_thread) => {
    console.log('stopChat called');
    const thread_id = running_thread.thread_id;
    const current_thread = thread == thread_id;

    if (current_thread) {
      dispatch(stopChat(running_thread.thread_id));
      updateThread(null);
      setThreadData(null);
      setMessages([]);
      setTitle('');
      setBusy(false);
      setOptions([]);
    }
  }

  const removeChat = (running_thread, confirmed = false) => {
    console.log('removeChat called');
    const thread_id = running_thread.thread_id;
    const current_thread = thread == thread_id;

    if (confirmed) {

      if (current_thread) {
        resetChat();
      } else {
        dispatch(clearChat(running_thread.thread_id))
      }
    }
  }

  const resetChat = () => {
    console.log('resetChat called');

    setMessages([]);
    //setTasks([]);
    setOptions([]);
    setAIlearning(false);
    setActionMessages([]); //reset
    setTitle('');
    setNeedSubmit(false);
    setBusy(false);
    updateThread(null);
    setNeedInput(true);
    setRoute(null);
    setThreadData(null);
    setLoadingOptions(false);
    generateDefaultOptions();
    dispatch(clearChat(thread))
    dispatch(resetTasks());
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Voeg logica toe om berichten te verwerken
    const newMessage = { type: 'user', text: inputValue };
    setMessages([...messages, newMessage]);
    setInputValue('');
    setBusy(true);
    setOptions([]);

    let message = {
      type: 'user',
      message: inputValue,
      thread_id: thread,
      route: route
    }

    //socket.emit('chat_message', JSON.stringify(message));
    dispatch(sendMessage(JSON.stringify(message)));
  };

  const setThreadById = (new_thread, dispatchAction = true) => {
    //resetChat();
    if (dispatchAction) dispatch(setThread(new_thread.thread_id));
    //setThread(new_thread.thread_id);
    setTitle(new_thread.metadata.title ? new_thread.metadata.title : "vorig gesprek");
    setThreadData(new_thread);
    updateThread(new_thread.thread_id);

    // Map the messages to the new format
    let newMessages = new_thread.messages.map(msg => {
      return {
        type: msg.sender === 'ai' ? 'bot' : 'user',
        text: msg.message
      };
    });

    setMessages(newMessages);
  }

  const setInterface = (needInput, needOptions) => {
    if (needInput) {
      setNeedInput(true);
    }
    // if(needOptions) {
    //   setLoadingOptions(true);
    // }
  }

  // Create a ref for the video element
  const videoRef = useRef(null);

  // Use an effect to assign the video stream to the video element
  useEffect(() => {
    if (videoRef.current && videoStream) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream]);

  // Filter the messages array to only include messages of type 'bot'
  const botMessages = messages.filter(message => message.type === 'bot');
  const userMessages = messages.filter(message => message.type === 'user');

  // Check if the bot has started
  const isStarted = messages?.length > 0 || busy || thread_status == "starting";

  // Get the last bot message
  const lastBotMessage = botMessages[botMessages.length - 1];
  const lastUserMessage = userMessages[userMessages.length - 1];

  // Get viewport height
  const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

  // console.log('viewportHeight: ', viewportHeight);
  // Calculate adjuster based on text length and viewport height
  let adjuster = (lastBotMessage?.text.length / 1.5) / (viewportHeight / 1.5) * 1.6;
  // console.log('adjuster: ', adjuster);
  // console.log('lastBotMessage: ', lastBotMessage?.text.length)

  // Calculate font size based on adjuster
  //let fontSize = 26 - (10 * adjuster);
  let fontSize = lastBotMessage ? 20 / (adjuster) : 16;

  // Ensure font size is not less than 14px and not more than 36px
  fontSize = Math.max(Math.min(fontSize, 20), 18);

  const filteredThreads = running_threads;//.filter(thread => !thread.metadata.probably_ended && !thread.metadata.solved);

  return (
    <div className="chatbot">
      {modal && (
        <div className={`chatbot-top elevate ${thread && (thread_status == "running" || thread_status == "started" || thread_status == "starting") ? 'elevate-shadow-animating-large' : (thread ? 'elevate-shadow-large' : '')}`} onClick={() => navigate('/gesprek')}>
          <Elevate />
        </div>
      )}
      <div className="left-column">
        {(isCameraActive || isCameraClosing) && (
          <div className={`overlay ${isCameraClosing ? 'closing' : ''}`} onClick={closeOverlay}>
            <div className="modal" onClick={e => e.stopPropagation()}>
              <video ref={videoRef} autoPlay></video>
              <button onClick={takePicture}>Take Picture</button>
              <button onClick={switchCamera}>Switch Camera</button>
              <button onClick={stopCamera}>Stop Camera</button>
            </div>
          </div>
        )}
        {(capturedImage || isImageClosing) && (
          <div className={`overlay ${isImageClosing ? 'closing' : ''}`} onClick={closeOverlay}>
            <div className="modal" onClick={e => e.stopPropagation()}>
              <img src={capturedImage} alt="Captured" />
              <textarea
                value={extraMessage}
                onChange={e => setExtraMessage(e.target.value)}
                placeholder="Add an extra message (optional)"
              />
              <button onClick={sendPicture}>Send Picture</button>
              <button onClick={() => { setCapturedImage(null); setIsCameraActive(true); }}>Take Another Picture</button>
            </div>
          </div>
        )}

        {(busy) && (
          <Loader />
        )}

        <div className='card chatbot-title'>
          {connectionStatus == "connected" && (
            <React.Fragment>
              {!isStarted && (
                <React.Fragment>
                  <span>nieuw gesprek</span>
                  <div className="circle"></div>
                  <TypingText speed={50} html={<span>Waarmee kan ik je helpen?</span>} />
                </React.Fragment>

              )}
              {isStarted && (
                <React.Fragment>
                  <h1>{title}</h1>
                </React.Fragment>
              )}

            </React.Fragment>
          )}

          {(connectionStatus != "connected") && (
            <div className="chatbot-offline">
              <CloudOff />
              <p>Geen verbinding</p>
            </div>
          )}

        </div>

        <div className="card chatbot-close">
          <button onClick={resetChat}>stop</button>
        </div>

        {(isStarted && connectionStatus == "connected") && (
          <div className="chatbot-container">
            <div className='chatbot-inner'>
              {/* {running_threads.map((running_thread, index) => (
                <div
                  key={index}
                  onClick={() => { setThreadById(running_thread) }}
                  className={`thread-card ${running_thread.thread_id == thread ? 'active' : ''}`}
                >{running_thread.metadata?.title}</div>
              ))} */}

              {((connectionStatus == "connected" || connectionStatus == "disconnected") && thread) && (
                <div className="ai-bot" style={{ fontSize: `${fontSize}px` }}>

                  {thread_status == "running" && delta ?
                    <StreamingAIResponse
                      delta={delta}
                      onSubmit={handleDataSubmit}
                      onStop={resetChat}
                    />
                    :
                    <AIResponseParser
                      actionMessages={actionMessages}
                      aiResponse={lastBotMessage?.text}
                      aiLearning={aiLearning}
                      loadingOptions={loadingOptions}
                      options={options}
                      typing={false}
                      loading={busy}
                      onSubmit={handleDataSubmit}
                      onStop={resetChat}
                      onChange={setInterface}
                      submitSimpleOption={submitSimpleOption}
                    />
                  }
                </div>
              )}

              {(connectionStatus && connectionStatus != "connected") && (
                <div className="ai-bot-start">
                  <div className={`mainMessage`}>
                    {connectionStatus == "disconnect" && (
                      <React.Fragment>
                        <TypingText speed={75} html={<div><h1>Verbinding met de AI portaal verloren...</h1><p>ik blijf het proberen, moment!</p></div>} />
                        <div style={{ paddingTop: 60 }}><Loader /></div>
                      </React.Fragment>
                    )}

                    {connectionStatus == "connect_error" && (
                      <React.Fragment>
                        <TypingText speed={75} html={<div><h1>Kan niet verbinden met de AI portaal...</h1><p>ik blijf het proberen, moment!</p></div>} />
                        <div style={{ paddingTop: 60 }}><Loader /></div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}

              {/* {(!isStarted && connectionStatus == "connected") && (
              <div className="ai-bot-start">
                <div className={`mainMessage`}>
                  <React.Fragment>
                    {busy ? <div className="thinkLoading"><Loader /><p>aan het nadenken<span ref={thinkingRef}></span></p></div> :
                      <TypingText speed={30} html={<p>Wil je iets weten, snap je iets niet, heb je hulp nodig of andere vraag/probleem? Laat het mij weten! Typ wat je denkt</p>} />
                    }
                  </React.Fragment>
                </div>
              </div>
            )} */}

            </div>
          </div>
        )}

        {!isStarted && !busy && ( // || (isStarted && needInput)) && (
          <div className="card chatbot-input-container">

            <form onSubmit={handleSubmit}>
              <TextareaAutosize
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    handleSubmit(event);
                  }
                }}
                placeholder={isStarted ? "Of typ hier je reactie" : "Je bericht"}
              />
              {/* <button type="submit">{busy ? <Loader /> : <Play />}</button> */}
            </form>

          </div>
        )}

        {(!isStarted && needSubmit && !busy) && (
          <div className="card chatbot-submit">
            <button onClick={handleSubmit}>{busy ? <Loader /> : "verstuur"}</button>
          </div>
        )}

        {(!isStarted && !needSubmit && !busy && connectionStatus == "connected") && (
          <div className='card default-ai-options'>
            {aiOptions.map((option, index) => (
              <button key={index} onClick={() => submitSimpleOption(option)}>{option}</button>
            ))}
          </div>
        )}

      </div>
      {!modal && (
        <div className="right-column">
          {filteredThreads && filteredThreads.length > 0 && (
            <div className='running-threads'>
              {filteredThreads.map((running_thread, index) => (
                <div
                  key={index}
                  onClick={() => { setThreadById(running_thread) }}
                  className={`thread-card ${running_thread.thread_id == thread ? 'active' : ''}`}
                >
                  <span className="thread-type">{running_thread.metadata?.type}</span>
                  <span className="thread-subject">{running_thread.metadata?.subject}</span>
                  <span className="thread-route">{running_thread.route}</span>
                  <span className="thread-summary">{running_thread.metadata?.user_summary}</span>
                  {running_thread.status && running_thread.status == "running" ?
                    <span className="thread-status"><CircularProgress /></span>
                    : (
                      <React.Fragment>
                        <span className="thread-update-date">{(running_thread.thread_id == thread ? "geopend" : formatDistanceToNow(new Date(running_thread.update_date), { locale: nl }) + " geleden")}</span>
                        <span className="thread-status-icons">
                          {running_thread.metadata?.probably_ended && <Tooltip title="gesprek klaar"><CheckCircleOutlineIcon /></Tooltip>}
                          {running_thread.metadata?.solved && <Tooltip title="opgelost"><Verified /></Tooltip>}

                          {thread == running_thread.thread_id && !running_thread.metadata?.probably_ended && !running_thread.metadata?.solved && (
                            <Tooltip title={"pauzeer gesprek"} onClick={(e) => { e.stopPropagation(); stopRunningChat(running_thread); }}>
                              {<PauseCircleOutline />}
                            </Tooltip>
                          )}
                          <Tooltip title={"verwijder gesprek"} onClick={(e) => { e.stopPropagation(); removeChat(thread); }}>
                            {<CancelOutlined />}
                          </Tooltip>
                        </span>
                      </React.Fragment>
                    )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Chatbot;
