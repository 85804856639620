// Header.js
import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { sendMessage } from './Actions';
import FlagIcon from '@mui/icons-material/FlagCircleOutlined';
import SchoolIcon from '@mui/icons-material/SchoolOutlined';
import FitnessCenterIcon from '@mui/icons-material/SportsGymnasticsOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationImportantOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import UserIcon from '@mui/icons-material/AccountCircleOutlined';
import GearIcon from '@mui/icons-material/SettingsOutlined';
import TicketIcon from '@mui/icons-material/AssignmentOutlined';
import '../assets/styles/Header.css';
import { ReactComponent as Elevate } from '../assets/icons/elevate_symbol_brightcare.svg';
import Logo from '../assets/icons/eva-logo.svg';
// import ElevateInput from './ElevateInput';
import Modal from './Modal';
import StartCard from './cards/StartCard';
import Chatbot from './Chatbot';
import Toast from './Toast';
import { clearNotification, clearTicketNotification } from './Actions'; // Import your action creators

function Header({ account }) {
  const firstName = account?.name.split(' ')[0];
  const thread = useSelector(state => state.main.thread);
  const thread_status = useSelector(state => state.main.status);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // Nieuwe state voor het beheren van de modal
  const dispatch = useDispatch();

  // const notifications = useSelector(state => state.main.notifications);
  const ticketNotifications = useSelector(state => state.main.ticketNotifications); // Add this line if you have separate ticket notifications
  // const [showNotification, setShowNotification] = useState(false);
  // const [latestNotification, setLatestNotification] = useState(null);

  // const handleNotificationClick = (id, action) => {
  //   if (action) {
  //     if (action.type === 'navigate' && action.to) {
  //       navigate(action.to);
  //     }
  //   }

  //   dispatch(clearNotification(id));
  // };

  // useEffect(() => {
  //   if (notifications.length > 0 && notifications[notifications.length - 1].type === 'notification') {
  //     setLatestNotification(notifications[notifications.length - 1]);
  //     setShowNotification(true);
  //     setTimeout(() => setShowNotification(false), 60000);
  //   } else {
  //     setShowNotification(false);
  //   }
  // }, [notifications]);

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleFloatingMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  // const getGreeting = () => {
  //   const hour = new Date().getHours();
  //   if (hour < 12) {
  //     return "goedemorgen";
  //   } else if (hour < 18) {
  //     return "goedemiddag";
  //   } else {
  //     return "goedenavond";
  //   }
  // };

  return (
    <React.Fragment>

      <header className="big-screen">


        {/* <div class="welcome" onClick={() => navigate('/')}>
          <h1>Hi {firstName},</h1>
          <h2>{getGreeting()}!</h2>
        </div> */}
        {/* <div className={`elevate ${thread && (thread_status == "running" || thread_status == "started" || thread_status == "starting") ? 'elevate-shadow-animating' : (thread ? 'elevate-shadow' : '')}`}
        onClick={() => setIsModalOpen(true)}>
        <Elevate />
      </div> */}
        <nav>
          <ul>
            {/* <li><NavLink activeClassName="active-link" to="/kennis"><span>Kennis</span></NavLink></li>
            <li><NavLink activeClassName="active-link" to="/training"><span>Trainingen</span></NavLink></li>
            <li>
              <NavLink activeClassName="active-link" to="/meldingen">
                {ticketNotifications.length > 0 ? <span className="notification-count">{ticketNotifications.length}</span> : ''}
                <span>Meldingen</span>
              </NavLink>
            </li> */}
            <li><NavLink activeClassName="active-link" to="/"><span>Dashboard</span></NavLink></li>
            <li><NavLink activeClassName="active-link" to="/activiteiten"><span>Activiteiten</span></NavLink></li>
          </ul>
        </nav>

        <div className='header-logo'>
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        {/* <ElevateInput /> */}

        {/* <div className="logo">
        {showNotification ? (
          <div className="notification">
            <Toast onClick={() => { handleNotificationClick(latestNotification.id, latestNotification?.action); }} message={latestNotification?.content ? latestNotification.content : latestNotification.title} />
          </div>
        ) : (
          <img src={Logo} alt="Logo" />
        )}
      </div> */}


        <div className="header-buttons">
          <button className="button-menu"><Link to="/profiel"><UserIcon /></Link></button>
          <button className="button-menu"><Link to="/settings"><GearIcon /></Link></button>
        </div>

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          {thread ?
            <Chatbot modal={true} />
            :
            <StartCard modal={true} />
          }
        </Modal>
      </header >

      <header className="small-screen">
        <nav>
          <ul>
            <li><NavLink activeClassName="active-link" to="/"><span>Dashboard</span></NavLink></li>
            {/* <li><NavLink activeClassName="active-link" to="/kennis"><span>Kennis</span></NavLink></li>
            <li><NavLink activeClassName="active-link" to="/training"><span>Training</span></NavLink></li>
            <li>
              <NavLink activeClassName="active-link" to="/meldingen">
                {ticketNotifications.length > 0 ? <span className="notification-count">{ticketNotifications.length}</span> : ''}
                <span>Melding</span>
              </NavLink>
            </li> */}
            <li><NavLink activeClassName="active-link" to="/activiteiten"><span>Activiteit</span></NavLink></li>
            <li><NavLink activeClassName="active-link" to="/profiel"><span><GearIcon /></span></NavLink></li>

          </ul>
        </nav>
      </header>

    </React.Fragment>

  );
}

export default Header;