import { call, put, takeEvery, take, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { GET_ACTIVITIES_REQUEST, GET_ACTIVITY, getActivitiesSuccess, getActivitiesFailure, SAVE_ACTIVITY, saveActivity, GET_ACTIVITIES_FAILURE, GET_ACTIVITIES_SUCCESS } from './actions';
import { connectSocket, getSocket } from '../../components/Socket';
import axios from 'axios';

function createSocketChannel(socket) {
  return eventChannel(emit => {
    const handler = (data) => {
      emit(data);
    };
    socket.on('activities', handler);
    return () => {
      socket.off('activities', handler);
    };
  });
}

function* fetchActivitiesSaga() {
  try {
    const socket = getSocket();
    if (!socket) {
      console.log('Socket is not initialized');
      return;
    }

    const socketChannel = yield call(createSocketChannel, socket);
    socket.emit('get_activities');

    while (true) {
      const activities = yield take(socketChannel);
      yield put(getActivitiesSuccess(activities));
    }

  } catch (error) {
    yield put(getActivitiesFailure(error.toString()));
  }
}

function* saveActivitySaga(action) {
  try {
    const socket = getSocket();
    if (!socket) {
      console.log('Socket is not initialized');
      return;
    }

    socket.emit('save_activity', action.payload);

    // get the current activities from saga
    const activities = yield select(state => state.activities.activities);

    //replace the old activity in the list with the new one
    const newActivities = activities.map(activity => {
      if (activity._id === action.payload._id) {
        return action.payload;
      }
      return activity;
    });

    console.log('newActivities:', newActivities);

    yield put(getActivitiesSuccess(newActivities));
  } catch (error) {
    console.log('Error saving activity:', error);
  }
}

// Updated sagas using axios directly
function* getActivitySaga(action) {
  yield put({ type: GET_ACTIVITIES_REQUEST });

  //try {
  const accessToken = yield select((state) => state.main.accessToken);
  const response = yield call(axios.get, `/api/activity/${action.payload}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const activities = yield select((state) => state.activities.activities);

  if (response.status !== 200) {
    yield put({ type: GET_ACTIVITIES_FAILURE, error: response.statusText });
  }

  const activity = response.data;

  // Update the task in the tasks array
  const update = activities.map((t) => {
    if (t._id === activity._id) {
      return activity;
    }
    return t;
  });

  yield put({ type: GET_ACTIVITIES_SUCCESS, payload: update });
  // } catch (error) {
  //  yield put({ type: GET_ACTIVITIES_FAILURE, error });
  //}
}


export function* watchFetchActivitiesSaga() {
  yield takeEvery(GET_ACTIVITIES_REQUEST, fetchActivitiesSaga);
  yield takeEvery(SAVE_ACTIVITY, saveActivitySaga);
  yield takeEvery(GET_ACTIVITY, getActivitySaga);
}