import React from 'react';
import {
    useMsalAuthentication,
    useMsal,
} from '@azure/msal-react';
import {
    InteractionType,
    InteractionRequiredAuthError,
} from '@azure/msal-browser';
import './assets/styles/loginScreen.css';

const LoginScreen = ({ needsSignIn, inProgress = false}) => {
    const request = {
        //loginHint: "name@example.com",
        scopes: ['api://7ae91f63-d4bb-4bff-980f-bd0c0fead9e8/Api'],
        extraScopesToConsent: ['https://graph.microsoft.com/User.Read'],
    }

    const { login, result, error } = useMsalAuthentication(InteractionType.Silent, request);
    const { instance } = useMsal();

    const handleLogin = () => {
        // instance.loginRedirect({
        //     scopes: ['api://7ae91f63-d4bb-4bff-980f-bd0c0fead9e8/Api'],
        //     extraScopesToConsent: ['https://graph.microsoft.com/User.Read'],
        // });
        login(InteractionType.Redirect, request).catch(e => {
            console.error('Error: ', e);
        });
    };

    return (
        <div className="login-screen">
            <div className="login-content">
                {!needsSignIn && inProgress ? <p>moment, bezig met inloggen..</p> : (
                    <React.Fragment>
                        <img src="./EVA_dark.svg" />
                        <img src="./brightcare_logo.png" />
                        <p>Graag inloggen met je Microsoft 365 account</p>
                        <button className="elevate" onClick={handleLogin}>Login</button>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default LoginScreen;