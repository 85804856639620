import { GET_ACTIVITIES_REQUEST, GET_ACTIVITIES_SUCCESS, GET_ACTIVITIES_FAILURE } from './actions';

const initialState = {
  activities: null,
  loading: false,
  error: null,
};

export default function activitiesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVITIES_REQUEST:
      return { ...state, loading: true };
    case GET_ACTIVITIES_SUCCESS:
      return { ...state, loading: false, activities: action.payload };
    case GET_ACTIVITIES_FAILURE:
      return { ...state, loading: false, activities: [], error: action.payload };
    default:
      return state;
  }
}