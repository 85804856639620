import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveInstructionRequest, getInstructionRequest, deleteInstructionRequest } from '../../sagas/instructions/actions';
import '../../assets/styles/management/instructions.css';
import TextareaAutosize from 'react-textarea-autosize';
import Modal from '../Modal'; // Adjust the path to match your file structure
import { getBackendUrl, syntaxHighlight } from '../GeneralFunctions'; // Adjust the path to match your file structure

const Instructions = () => {
    const dispatch = useDispatch();
    const instructionItems = useSelector(state => state.instructions.instructions || []);
    const notifications = useSelector(state => state.main.notifications);

    const [selectedInstruction, setSelectedInstruction] = useState(null);
    const [jsonError, setJsonError] = useState(null);
    const [jsonInput, setJsonInput] = useState('');
    const [highlightedJson, setHighlightedJson] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteInstructionModalOpen, setIsDeleteInstructionModalOpen] = useState(false);
    const [isOrganizationsModalOpen, setIsOrganizationsModalOpen] = useState(false);
    const [statusMessages, setStatusMessages] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [allOrganizations, setAllOrganizations] = useState([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const accessToken = useSelector(state => state.main.accessToken);

    useEffect(() => {
        const url = getBackendUrl();

        // Fetch organizations when component mounts
        fetch(url + '/organizations', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(response => response.json())
            .then(data => setAllOrganizations(data))
            .catch(error => console.error('Error fetching organizations:', error));

        if (!instructionItems.length) {
            dispatch(getInstructionRequest());
        }
    }, [dispatch, instructionItems.length]);

    useEffect(() => {
        if (selectedOrganization !== null) {
            dispatch(getInstructionRequest(selectedOrganization));
        } else {
            dispatch(getInstructionRequest());
        }
    }, [dispatch, selectedOrganization]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSaving(true);
        const updatedInstruction = { ...selectedInstruction, organizations: [selectedOrganization] };
        dispatch(saveInstructionRequest(updatedInstruction));
    };

    const handleSelectInstruction = (instruction) => {
        setSelectedInstruction(instruction);
        setStatusMessages([]);
        setSelectedOrganizations(instruction.organizations || []);
    };

    const handleAddInstruction = () => {
        setSelectedInstruction({
            title: '',
            content: '',
            relevantForWhom: '',
            purpose: '',
            organizations: []
        });
    };

    const handleDeleteInstruction = () => {
        setIsDeleteInstructionModalOpen(true);
    };

    useEffect(() => {
        setHighlightedJson(syntaxHighlight(jsonInput));
    }, [jsonInput]);

    useEffect(() => {
        setJsonInput(JSON.stringify(selectedInstruction, null, 2));
    }, [selectedInstruction]);

    useEffect(() => {
        notifications.forEach(notification => {
            if (notification.payload.source === 'instruction'
                && notification.payload.type === 'status'
                && notification.payload.status === "success") {

                const instructionId = notification.payload.id;
                const message = notification.payload.message;

                dispatch(getInstructionRequest());

                setTimeout(() => {
                    // Reset loading states based on notification
                    setIsSaving(false);
                    setIsDeleting(false);

                    setStatusMessages(prevState => ({
                        ...prevState,
                        [instructionId]: message
                    }));

                    setTimeout(() => {
                        setStatusMessages(prevState => {
                            const { [instructionId]: _, ...rest } = prevState;
                            return rest;
                        });
                    }, 10000);

                }, 1500);
            } else {
                // handle error here
            }
        });
    }, [notifications, dispatch]);

    const handleJsonChange = (e) => {
        setJsonInput(e.target.value);
        try {
            const value = JSON.parse(e.target.value);
            setSelectedInstruction(value);
            setJsonError(null);
        } catch (err) {
            setJsonError('Invalid JSON');
        }
    };

    const handleToggleOrganization = (organizationId) => {
        setSelectedOrganizations(prevState =>
            prevState.includes(organizationId)
                ? prevState.filter(id => id !== organizationId)
                : [...prevState, organizationId]
        );
        setSelectedInstruction(null);
    };

    const handleSelectOrganization = (organizationId) => {
        setSelectedOrganization(organizationId);
        setSelectedInstruction(null);
    };

    const handleDeselectOrganization = () => {
        setSelectedOrganization(null);
    };

    const filteredInstructionItems = selectedOrganization
        ? instructionItems?.filter(item => item.organization?.includes(selectedOrganization))
        : instructionItems?.filter(item => !item.organizations?.length);

    return (
        <div>
            <div className="instructions">
                <div className="item-list">
                    <h1>Organisaties</h1>
                    <div className={`item-button ${selectedOrganization === null ? 'selected' : ''}`} key="all" onClick={handleDeselectOrganization}>Alle organisaties</div>
                    {allOrganizations.map(org => (
                        <div
                            key={org._id}
                            className={`item-button ${selectedOrganization === org._id ? 'selected' : ''}`}
                            onClick={() => handleSelectOrganization(org._id)}
                        >
                            {org.profile.name}
                        </div>
                    ))}
                </div>
                <div className="item-list">
                    <h1>Instructions Items</h1>
                    <div className="item-button" onClick={handleAddInstruction}>Add New Instruction Item</div>
                    {filteredInstructionItems.map(item => (
                        <div key={item.id} className={`item-button ${selectedInstruction === item ? 'selected' : ''}`} onClick={() => handleSelectInstruction(item)}>
                            {item.title}
                            {statusMessages[item.id] && <span className="status-message"> - {statusMessages[item.id]}</span>}
                        </div>
                    ))}
                </div>
                {selectedInstruction && (
                    <div className="instructions-preview">
                        <div className="instructions-preview-header">
                            <h1>{selectedInstruction.id ? `Edit ${selectedInstruction.title} Instruction` : 'Create a new Instruction Item'}</h1>
                            <div className="instructions-preview-header-actions">
                                {statusMessages[selectedInstruction.id] && <span className="status-message">{statusMessages[selectedInstruction.id]}</span>}
                                {selectedInstruction.id && <button type="button" onClick={handleDeleteInstruction}>{isDeleting ? 'Deleting...' : 'Delete Instruction'}</button>}
                                <button type="submit" onClick={handleSubmit}>{isSaving ? 'Saving...' : 'Save'}</button>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="instructions-form">
                            <label>
                                <span>Title</span>
                                <input type="text" value={selectedInstruction.title} onChange={e => setSelectedInstruction({ ...selectedInstruction, title: e.target.value })} />
                            </label>
                            <label>
                                <span>Purpose</span>
                                <input type="text" value={selectedInstruction.purpose} onChange={e => setSelectedInstruction({ ...selectedInstruction, purpose: e.target.value })} />
                            </label>
                            <label>
                                <span>Relevant For Whom</span>
                                <input type="text" value={selectedInstruction.relevantForWhom} onChange={e => setSelectedInstruction({ ...selectedInstruction, relevantForWhom: e.target.value })} />
                            </label>
                            <label>
                                <span>Content</span>
                                <TextareaAutosize style={{ height: 300 }} value={selectedInstruction.content} onChange={e => setSelectedInstruction({ ...selectedInstruction, content: e.target.value })} />
                            </label>
                        </form>
                    </div>
                )}
            </div>
            <Modal isOpen={isDeleteInstructionModalOpen} onClose={() => setIsDeleteInstructionModalOpen(false)}>
                <h2>Confirm Delete</h2>
                <p>Are you sure you want to delete this instruction item?</p>
                <button onClick={() => {
                    dispatch(deleteInstructionRequest(selectedInstruction.id)); // replace with actual action
                    setSelectedInstruction(null);
                    setIsDeleteInstructionModalOpen(false);
                    dispatch(getInstructionRequest()); // replace with actual action
                }}>Yes</button>
                <button onClick={() => setIsDeleteInstructionModalOpen(false)}>No</button>
            </Modal>
            <Modal isOpen={isOrganizationsModalOpen} onClose={() => setIsOrganizationsModalOpen(false)}>
                <h2>Select Organizations</h2>
                <div className="organizations-list">
                    {allOrganizations.map(org => (
                        <button
                            key={org._id}
                            className={`organization-item ${selectedOrganizations.includes(org._id) ? 'selected' : ''}`}
                            onClick={() => handleToggleOrganization(org._id)}
                        >
                            {org.profile.name}
                        </button>
                    ))}
                </div>
                <button onClick={() => setIsOrganizationsModalOpen(false)}>Close</button>
            </Modal>
        </div>
    );
};

export default Instructions
