// TypingText.js
import React, { useState, useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import ReactDOMServer from 'react-dom/server';

function TypingText({ html, speed = 10, onComplete, typing }) {
  const [displayedText, setDisplayedText] = useState('');
  const [animationDone, setAnimationDone] = useState(false);
  const htmlRef = useRef(html);

  useEffect(() => {
    if (animationDone || (typing != null && !typing)) {
      let htmlString = htmlRef.current;
      if (React.isValidElement(htmlRef.current)) {
        htmlString = ReactDOMServer.renderToStaticMarkup(htmlRef.current);
      }
      setDisplayedText(htmlString);
      if (onComplete) {
        onComplete();
      }
      return;
    }

    let i = 0;
    let currentText = '';
    let isTag = false;

    // Check if html is a React component and convert it to a string if it is
    let htmlString = htmlRef.current;
    if (React.isValidElement(htmlRef.current)) {
      htmlString = ReactDOMServer.renderToStaticMarkup(htmlRef.current);
    } else {
      htmlString = htmlRef.current;
    }

    const interval = setInterval(() => {
      if (i < htmlString?.length) {
        currentText += htmlString[i];
        if (htmlString[i] === '<') isTag = true;
        if (htmlString[i] === '>') isTag = false;
        // Only update displayedText when a tag is complete or when isTag is false
        if (!isTag || htmlString[i] === '>') {
          setDisplayedText(currentText);
        }
        i++;
      } else {
        clearInterval(interval);
        setAnimationDone(true);
        if (onComplete) {
          console.log('TypingText: onComplete');
          onComplete();
        }
      }
    }, speed);
    return () => clearInterval(interval);
  }, [speed, onComplete, animationDone]); // Removed html from the dependency array

  if(!displayedText) return null;
  return <>{parse(displayedText)}</>;
}

export default React.memo(TypingText);