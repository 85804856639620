import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getActivitiesRequest } from '../sagas/activities/actions';
import { getTicketsRequest } from '../sagas/tickets/actions';
import { getTasksRequest } from '../sagas/tasks/actions';
import { saveActivity } from '../sagas/activities/actions';
import AIResponseParser from './AIResponseParser';
import "../assets/styles/Activities.css";
import { timeSince } from './GeneralFunctions';
import ActivityDetail from './details/ActivityDetail';
import { sendMessage, stopChat, setActivity } from './Actions';
import { CheckCircleOutline, InfoOutlined, LockClockOutlined, Assignment, MessageOutlined, Email, MarkEmailUnread, MarkEmailUnreadOutlined, AssignmentOutlined, EmailOutlined, MarkEmailReadOutlined, CloseOutlined, CancelOutlined } from '@mui/icons-material';
import { Player } from '@lordicon/react';
import TaskDetail from './details/TaskDetail';

const DECIDING = require('../assets/system-regular-197-calculate.json');

const Activities = ({ card = false }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const connectionStatus = useSelector(state => state.main.connectionStatus);
    const thread = useSelector(state => state.main.thread);
    const activities = useSelector(state => state.activities.activities) || [];
    const { activityId } = useParams();
    //const storedActivity = useSelector(state => state.main.activity);
    const [selectedActivity, setActivityLocal] = useState(null);
    const selectedActivitySaga = useSelector(state => state.main.activity);
    const [showInbox, setShowInbox] = useState(false);
    const firstRender = useRef(true);
    const [animationStatus, setAnimationStatus] = useState(false);
    const [currentFilter, setCurrentFilter] = useState('all');
    const [listVisible, setListVisible] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [waitingForSendMessage, setWaitingForSendMessage] = useState(false); // New state for waiting animation
    const sendMessageDelayRef = useRef(null); // Ref to manage sendMessage delay
    const [eva, setEva] = useState(false); // New state for EVA status


    useEffect(() => {
        if (connectionStatus === 'connected') {
            dispatch(getActivitiesRequest());
            dispatch(getTicketsRequest());
            dispatch(getTasksRequest());
        }
    }, [connectionStatus, dispatch]);

    useEffect(() => {
        setActivityLocal(null);
    }, [currentFilter]);

    useEffect(() => {
        if (selectedActivitySaga) {
            setActivityLocal(selectedActivitySaga);
            setEva(false);
        } else {
            setActivityLocal(null);
            setEva(false);
        }
    }, [selectedActivitySaga]);

    //on dismount
    useEffect(() => {
        if (!card && activities.length > 0 && !selectedActivity) {
            dispatch(setActivity(activities[0]));
            setActivityLocal(activities[0]);
        }

        return () => {
            //  if (selectedActivity) {
            dispatch(setActivity(null));
            //      }
        }
    }, []);

    useEffect(() => {
        if (activityId) {
            const activity = activities.find(activity => activity._id === activityId);
            dispatch(setActivity(activity));
            console.log('activity', activity);

            if (firstRender.current) {
                setShowInbox(false);
                firstRender.current = false;
            } else if (!card) {
                setShowInbox(true);
            }
        } else {

            if (!card) setShowInbox(true);
        }
    }, [activityId, activities]);


    const handleUpdateTask = (updatedTask) => {
        const updatedActivity = {
            ...selectedActivity,
            payload: updatedTask,
        };
        dispatch(saveActivity(updatedActivity));
    };

    const handleFilterChange = (filterType) => {
        setListVisible(false); // Hide the list
        setTimeout(() => {
            setCurrentFilter(filterType);
            setListVisible(true); // Show the list with new items after a delay
        }, 350); // This delay should match the CSS transition duration
    };

    const filteredActivities = activities?.filter(activity => {
        // Step 1: Filter by type if currentFilter is not 'all'
        return currentFilter === 'all' || activity.type === currentFilter;
    }).reduce((acc, activity) => {
        // Step 2: Further filter to ensure object_id is not null and unique
        if (currentFilter == "all" || !activity.object_id || activity.object_id != null && !acc.some(accActivity => accActivity.object_id === activity.object_id)) {
            acc.push(activity);
        }
        return acc;
    }, []);


    const askEva = (activity) => {

        if (!activity) return;

        // Cancel any ongoing delay
        clearTimeout(sendMessageDelayRef.current);
        setWaitingForSendMessage(true); // Start waiting animation

        // Start a new delay before sending the message
        sendMessageDelayRef.current = setTimeout(() => {
            let message = {
                type: 'user',
                message: `Frondend: User has opened an history activity: ${activity.title} with id ${activity._id}. Which is of type ${activity.type}. The type payload: ${JSON.stringify(activity.payload)}. Check if you can be of futher assistance on the payload, no need to display all the contents again.`,
                thread_id: null,
                route: null
            }

            dispatch(sendMessage(message));
            setWaitingForSendMessage(false); // Stop waiting animation
            setEva(true); // Set EVA status to true
        }, 2000); // Delay of 2 seconds
    };

    const resetEva = () => {
        clearTimeout(sendMessageDelayRef.current);
        setWaitingForSendMessage(false);
        setEva(false);
    };

    const updateActivityStatus = async (activity) => {
        if (activity.hasRead) return;
        dispatch(saveActivity({ ...activity, hasRead: true }));
    };

    const handleSelectActivity = (activity) => {
        if (activity && activity._id != selectedActivity?._id) {
            dispatch(setActivity(activity));
            updateActivityStatus(activity); // Update activity status
        } else {
            dispatch(stopChat());
            dispatch(setActivity(null));
            clearTimeout(sendMessageDelayRef.current);
            setWaitingForSendMessage(false);
        }
    };

    // const handleSubmit = (response) => {
    //     if (response === "") return;
    //     console.log('handleSubmit', response);

    //     const newActivity = {
    //         answer: response,
    //         ...selectedActivity,
    //         status: 'done', // set status to 'done'
    //     };

    //     //dispatch(saveActivityReply(newActivity));
    //     setAnimationStatus(true); // start the animation

    //     // remove the activity after the animation
    //     setTimeout(() => {
    //         setActivity(null);
    //         setAnimationStatus(false);
    //     }, 1000); // adjust the delay to match your animation duration
    // };

    // Helper function to determine if a date is within the last 24 hours
    const isWithinLast24Hours = (date) => {
        const now = new Date();
        const activityDate = new Date(date);
        const differenceInHours = Math.abs(now - activityDate) / 36e5; // Convert difference in milliseconds to hours
        return differenceInHours <= 24;
    };

    const reset = () => {
        dispatch(stopChat());
        dispatch(setActivity(null));
        clearTimeout(sendMessageDelayRef.current);
        setWaitingForSendMessage(false);
    };

    const countUnreadActivities = (type) => {
        const uniqueObjectIds = new Set();
        activities.forEach(activity => {
            if (activity.type === type && activity.type == "task" && activity.status == "active" && activity.payload.task_state == "active") {
                uniqueObjectIds.add(activity.object_id);
            }

            if (activity.type === type && activity.type == "ticket" && activity.status == "active" && activity.payload.ticket_status == "active") {
                uniqueObjectIds.add(activity.object_id);
            }

            if (activity.type === type && activity.type == "message" && !activity.hasRead) {
                uniqueObjectIds.add(activity.object_id);
            }
        });
        return uniqueObjectIds.size > 0 ? uniqueObjectIds.size : "";
    };
    return (
        <React.Fragment>
            {!card && (
                <div className="activities">

                    {//!isMobile && (
                        <div className="activities-list">

                            <div className="activities-header">
                                <button className={`activity-button ${currentFilter == 'all' && "active"}`} onClick={() => handleFilterChange('all')}>
                                    alles
                                </button>
                                <button className={`activity-button ${currentFilter == 'ticket' && "active"}`} onClick={() => handleFilterChange('ticket')}>
                                    {countUnreadActivities('ticket')} meldingen
                                </button>
                                <button className={`activity-button ${currentFilter == 'task' && "active"}`} onClick={() => handleFilterChange('task')}>
                                    {countUnreadActivities('task')} taken
                                </button>
                                <button className={`activity-button ${currentFilter == 'message' && "active"}`} onClick={() => handleFilterChange('message')}>
                                    {countUnreadActivities('message')} berichten
                                </button>
                            </div>

                            <div className={`activities-items ${listVisible ? 'fadeIn' : 'fadeOut'}`}>
                                {filteredActivities?.filter(activity => activity.status == 'active').map((activity) => (
                                    activity.status === 'active' && (
                                        <div className={`card activity-item ${!activity?.hasRead ? "unread" : ""} ${(selectedActivity && selectedActivity._id == activity._id) ? 'active' : ''}`}
                                            onClick={() => handleSelectActivity(activity)}>
                                            {activity.type == "info" && <InfoOutlined className={`icon icon-info ${(selectedActivity && selectedActivity._id == activity._id) ? 'active-icon' : ''}`} />}
                                            {activity.type == "ticket" && activity.payload?.ticket_status == "active" && <Assignment className={`icon icon-ticket ${(selectedActivity && selectedActivity._id == activity._id) ? 'active-icon' : ''}`} />}
                                            {activity.type == "ticket" && activity.payload?.ticket_status != "active" && <Assignment className={`icon icon-task ${(selectedActivity && selectedActivity._id == activity._id) ? 'active-icon' : ''}`} />}
                                            {activity.type == "task" && (
                                                <React.Fragment>
                                                    {activity.payload.task_state == "active" && <AssignmentOutlined className={`icon ${(selectedActivity && selectedActivity._id == activity._id) ? '' : ''}`} />}
                                                    {activity.payload.task_state == "approved" && <CheckCircleOutline className={`icon icon-task ${(selectedActivity && selectedActivity._id == activity._id) ? '' : ''}`} />}
                                                    {activity.payload.task_state == "rejected" && <CancelOutlined className={`icon icon-rejected ${(selectedActivity && selectedActivity._id == activity._id) ? '' : ''}`} />}
                                                </React.Fragment>
                                            )}
                                            {activity.type == "message" && !activity.hasRead && <MarkEmailUnreadOutlined  className={`icon icon-message ${(selectedActivity && selectedActivity._id == activity._id) ? '' : ''}`} />}
                                            {activity.type == "message" && activity.hasRead && <MarkEmailReadOutlined  className={`icon icon-task ${(selectedActivity && selectedActivity._id == activity._id) ? '' : ''}`} />}

                                            <span className="truncate">{currentFilter != "all" && activity.payload?.short_title ? activity.payload?.short_title : activity.title}</span>
                                            {/* Display "nieuw" or "bijgewerkt" based on created_date and updated_date */}
                                            {(currentFilter != "all" && activity.payload && !activity.hasRead) && (
                                                <React.Fragment>
                                                    {isWithinLast24Hours(activity.payload.creationDate) && <span className="status-label">nieuw</span>}
                                                    {!isWithinLast24Hours(activity.payload.creationDate) && isWithinLast24Hours(activity.updated_at) && <span className="status-label">bijgewerkt</span>}
                                                </React.Fragment>
                                            )}
                                            {((currentFilter == "all" || !activity.payload) && !activity.hasRead) && (
                                                <React.Fragment>
                                                    {isWithinLast24Hours(activity.created_at) && <span className="status-label">nieuw</span>}
                                                    {!isWithinLast24Hours(activity.created_at) && isWithinLast24Hours(activity.updated_at) && <span className="status-label">bijgewerkt</span>}
                                                </React.Fragment>
                                            )}
                                            <span className="info">{timeSince(new Date(activity.updated_at).toLocaleString())}</span>
                                        </div>
                                    )))
                                }
                            </div>
                        </div>
                        //)
                    }


                    {selectedActivity && (
                        <div className={`activity`}>
                            <div className='activity-header'>
                                <span className="activity-title">

                                    {!isMobile && currentFilter == "all" && (
                                        <React.Fragment>
                                            {selectedActivity.type == "ticket" && "Melding"}
                                            {selectedActivity.type == "task" && "Taak"}
                                            {selectedActivity.type == "message" && "Bericht"}
                                            {/* {selectedActivity.type == "run" && "Flow"} */}
                                        </React.Fragment>
                                    )}

                                    {isMobile && selectedActivity.type == "ticket" && (
                                        <button className='activity-button' onClick={() => { dispatch(setActivity(null)); }}>terug</button>
                                    )}

                                    {currentFilter == "ticket" && ("#" + selectedActivity.payload?.number)}
                                </span>
                                <span>{currentFilter != "all" && selectedActivity.payload?.short_title ? selectedActivity.payload?.short_title : selectedActivity.title}</span>
                                <div style={{ textAlign: "right" }}>
                                    {selectedActivity.type == "ticket" && (
                                        <button className='activity-button'>{isMobile ? "annuleer" : "annuleer melding"}</button>
                                    )}
                                </div>
                            </div>
                            <div className="activity-details">
                                {selectedActivity.type === "task" ? (
                                    <TaskDetail task={selectedActivity.payload} onUpdateTask={handleUpdateTask} />
                                ) : (
                                    <ActivityDetail activity={selectedActivity} />
                                )}
                            </div>
                        </div>
                    )}

                </div>
            )}


            {card && (
                <React.Fragment>
                    {!selectedActivity && activities?.slice(0, 5).map((activity) => (
                        activity.status === 'active' && (
                            <div className={`card activity-item ${!activity?.hasRead ? "unread" : ""} ${(selectedActivity && selectedActivity._id == activity._id) ? 'active' : ''}`}
                                onClick={() => handleSelectActivity(activity)}>
                                 {activity.type == "info" && <InfoOutlined className={`icon icon-info ${(selectedActivity && selectedActivity._id == activity._id) ? 'active-icon' : ''}`} />}
                                            {activity.type == "ticket" && <Assignment className={`icon icon-ticket ${(selectedActivity && selectedActivity._id == activity._id) ? 'active-icon' : ''}`} />}
                                            {activity.type == "task" && (
                                                <React.Fragment>
                                                    {activity.payload.task_state == "active" && <AssignmentOutlined className={`icon ${(selectedActivity && selectedActivity._id == activity._id) ? '' : ''}`} />}
                                                    {activity.payload.task_state == "approved" && <CheckCircleOutline className={`icon icon-task ${(selectedActivity && selectedActivity._id == activity._id) ? '' : ''}`} />}
                                                    {activity.payload.task_state == "rejected" && <CancelOutlined className={`icon icon-rejected ${(selectedActivity && selectedActivity._id == activity._id) ? '' : ''}`} />}
                                                </React.Fragment>
                                            )}
                                            {activity.type == "message" && !activity.hasRead && <MarkEmailUnreadOutlined  className={`icon icon-message ${(selectedActivity && selectedActivity._id == activity._id) ? '' : ''}`} />}
                                            {activity.type == "message" && activity.hasRead && <MarkEmailReadOutlined  className={`icon icon-task ${(selectedActivity && selectedActivity._id == activity._id) ? '' : ''}`} />}

                                <span className="truncate">{currentFilter != "all" && activity.payload?.short_title ? activity.payload?.short_title : activity.title}</span>
                                <span className="info" style={{ width: "auto !important" }}>{timeSince(new Date(activity.updated_at).toLocaleString())}</span>
                            </div>
                        )))
                    }

                    {selectedActivity && (
                        <div className={`${isMobile ? 'activity small' : 'activity small'}`}>
                            <div className='card activity-header-incard'>
                                <span>{currentFilter != "all" && selectedActivity.payload?.short_title ? selectedActivity.payload?.short_title : selectedActivity.title}</span>
                                <button className='activity-button' onClick={() => { reset(); }}>sluit</button>

                                {(waitingForSendMessage || eva) && (
                                    <React.Fragment>
                                        <div className={`activity-loading-info ${(thread && selectedActivity) ? 'active' : ''}`}>{(thread && selectedActivity) ? 'EVA actief' : 'Inschakelen EVA'}</div>
                                        <div className="activity-loading-bar" style={{ width: 'calc(100% + 0px);' }}></div>
                                    </React.Fragment>
                                )}

                            </div>
                            <div className="activity-details">
                                {/* <div className="ai-close" onClick={() => { handleSelectActivity(null); }}>x</div> */}
                                {selectedActivity.type === "task" ? (
                                    <TaskDetail doClose={reset} task={selectedActivity.payload} inCard={true} onUpdateTask={handleUpdateTask} />
                                ) : (
                                    <ActivityDetail activity={selectedActivity} inCard={true} />
                                )}

                                {/* {isMobile && ( */}
                                {!thread && !waitingForSendMessage && (
                                    <div className="activity-buttons">
                                        <button className='activity-button' style={{ width: '100%' }} onClick={() => { askEva(selectedActivity); }}>
                                            {(selectedActivity.type == "ticket" && 'melding openen')}
                                            {(selectedActivity.type == "info" && 'info openen')}
                                            {(selectedActivity.type == "task" && 'taak openen')}
                                            {(selectedActivity.type == "message" && 'bericht openen')}
                                        </button>
                                    </div>
                                )}

                                {waitingForSendMessage && (
                                    <div className="activity-buttons">
                                        <button className='activity-button' style={{ width: '100%' }} onClick={() => { resetEva(); }}>annuleer</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                </React.Fragment>
            )}


        </React.Fragment>
    );
};

export default Activities;
