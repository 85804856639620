import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveKnowledgeRequest, getKnowledgeRequest, deleteKnowledgeRequest } from '../../sagas/knowledge/actions';
import '../../assets/styles/management/knowledge.css';
import TextareaAutosize from 'react-textarea-autosize';
import Modal from '../Modal'; // Adjust the path to match your file structure
import { getBackendUrl, syntaxHighlight } from '../GeneralFunctions'; // Adjust the path to match your file structure

const Knowledge = () => {
    const dispatch = useDispatch();
    const knowledgeItems = useSelector(state => state.knowledge.knowledge || []);
    const notifications = useSelector(state => state.main.notifications);

    const [selectedKnowledge, setSelectedKnowledge] = useState(null);
    const [jsonError, setJsonError] = useState(null);
    const [jsonInput, setJsonInput] = useState('');
    const [highlightedJson, setHighlightedJson] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteKnowledgeModalOpen, setIsDeleteKnowledgeModalOpen] = useState(false);
    const [isOrganizationsModalOpen, setIsOrganizationsModalOpen] = useState(false);
    const [statusMessages, setStatusMessages] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [allOrganizations, setAllOrganizations] = useState([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const accessToken = useSelector(state => state.main.accessToken);

    useEffect(() => {
        const url = getBackendUrl();

        // Fetch organizations when component mounts
        fetch(url + '/organizations', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(response => response.json())
            .then(data => setAllOrganizations(data))
            .catch(error => console.error('Error fetching organizations:', error));

        if (!knowledgeItems.length) {
            dispatch(getKnowledgeRequest());
        }
    }, [dispatch, knowledgeItems.length]);

    useEffect(() => {
        if (selectedOrganization !== null) {
            dispatch(getKnowledgeRequest(selectedOrganization));
        } else {
            dispatch(getKnowledgeRequest());
        }
    }, [dispatch, selectedOrganization]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSaving(true);
        const updatedKnowledge = { ...selectedKnowledge, organizations: [selectedOrganization] };
        dispatch(saveKnowledgeRequest(updatedKnowledge));
    };

    const handleSelectKnowledge = (knowledge) => {
        setSelectedKnowledge(knowledge);
        setStatusMessages([]);
        setSelectedOrganizations(knowledge.organizations || []);
    };

    const handleAddKnowledge = () => {
        setSelectedKnowledge({
            title: '',
            content: '',
            relevantForWhom: '',
            purpose: '',
            organizations: []
        });
    };

    const handleDeleteKnowledge = () => {
        setIsDeleteKnowledgeModalOpen(true);
    };

    useEffect(() => {
        setHighlightedJson(syntaxHighlight(jsonInput));
    }, [jsonInput]);

    useEffect(() => {
        setJsonInput(JSON.stringify(selectedKnowledge, null, 2));
    }, [selectedKnowledge]);

    useEffect(() => {
        notifications.forEach(notification => {
            if (notification.payload.source === 'knowledge'
                && notification.payload.type === 'status'
                && notification.payload.status === "success") {

                const knowledgeId = notification.payload.id;
                const message = notification.payload.message;

                dispatch(getKnowledgeRequest());

                setTimeout(() => {
                    // Reset loading states based on notification
                    setIsSaving(false);
                    setIsDeleting(false);

                    setStatusMessages(prevState => ({
                        ...prevState,
                        [knowledgeId]: message
                    }));

                    setTimeout(() => {
                        setStatusMessages(prevState => {
                            const { [knowledgeId]: _, ...rest } = prevState;
                            return rest;
                        });
                    }, 10000);

                }, 1500);
            } else {
                // handle error here
            }
        });
    }, [notifications, dispatch]);

    const handleJsonChange = (e) => {
        setJsonInput(e.target.value);
        try {
            const value = JSON.parse(e.target.value);
            setSelectedKnowledge(value);
            setJsonError(null);
        } catch (err) {
            setJsonError('Invalid JSON');
        }
    };

    const handleToggleOrganization = (organizationId) => {
        setSelectedOrganizations(prevState =>
            prevState.includes(organizationId)
                ? prevState.filter(id => id !== organizationId)
                : [...prevState, organizationId]
        );
        setSelectedKnowledge(null);
    };


    const handleSelectOrganization = (organizationId) => {
        setSelectedOrganization(organizationId);
        setSelectedKnowledge(null);
    };

    const handleDeselectOrganization = () => {
        setSelectedOrganization(null);
    };

    const filteredKnowledgeItems = selectedOrganization
        ? knowledgeItems?.filter(item => item.organization?.includes(selectedOrganization))
        : knowledgeItems?.filter(item => !item.organizations?.length);

    return (
        <div>
            <div className="knowledge">
                <div className="item-list">
                    <h1>Organisaties</h1>
                    <div className={`item-button ${selectedOrganization === null ? 'selected' : ''}`} key="all" onClick={handleDeselectOrganization}>Alle organisaties</div>
                    {allOrganizations.map(org => (
                        <div
                            key={org._id}
                            className={`item-button ${selectedOrganization === org._id ? 'selected' : ''}`}
                            onClick={() => handleSelectOrganization(org._id)}
                        >
                            {org.profile.name}
                        </div>
                    ))}
                </div>
                <div className="item-list">
                    <h1>Knowledge Items</h1>
                    <div className="item-button" onClick={handleAddKnowledge}>Add New Knowledge Item</div>
                    {filteredKnowledgeItems.map(item => (
                        <div key={item.id} className={`item-button ${selectedKnowledge === item ? 'selected' : ''}`} onClick={() => handleSelectKnowledge(item)}>
                            {item.title}
                            {statusMessages[item.id] && <span className="status-message"> - {statusMessages[item.id]}</span>}
                        </div>
                    ))}
                </div>
                {selectedKnowledge && (
                    <div className="knowledge-preview">
                        <div className="knowledge-preview-header">
                            <h1>{selectedKnowledge.id ? `Edit ${selectedKnowledge.title} Knowledge` : 'Create a new Knowledge Item'}</h1>
                            <div className="knowledge-preview-header-actions">
                                {statusMessages[selectedKnowledge.id] && <span className="status-message">{statusMessages[selectedKnowledge.id]}</span>}
                                {selectedKnowledge.id && <button type="button" onClick={handleDeleteKnowledge}>{isDeleting ? 'Deleting...' : 'Delete Knowledge'}</button>}
                                <button type="submit" onClick={handleSubmit}>{isSaving ? 'Saving...' : 'Save'}</button>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="knowledge-form">
                            <label>
                                <span>Title</span>
                                <input type="text" value={selectedKnowledge.title} onChange={e => setSelectedKnowledge({ ...selectedKnowledge, title: e.target.value })} />
                            </label>
                            <label>
                                <span>Purpose</span>
                                <input type="text" value={selectedKnowledge.purpose} onChange={e => setSelectedKnowledge({ ...selectedKnowledge, purpose: e.target.value })} />
                            </label>
                            <label>
                                <span>Relevant For Whom</span>
                                <input type="text" value={selectedKnowledge.relevantForWhom} onChange={e => setSelectedKnowledge({ ...selectedKnowledge, relevantForWhom: e.target.value })} />
                            </label>
                            <label>
                                <span>Content</span>
                                <TextareaAutosize style={{ height: 300 }} value={selectedKnowledge.content} onChange={e => setSelectedKnowledge({ ...selectedKnowledge, content: e.target.value })} />
                            </label>
                            {/* <label>
                                <span>Organizations</span>
                                <div>
                                    <button type="button" onClick={() => setIsOrganizationsModalOpen(true)}>Select Organizations</button>
                                    <ul>
                                        {selectedOrganizations.map(orgId => {
                                            const org = allOrganizations.find(o => o._id === orgId);
                                            return <li key={orgId}>{org?.profile.name || orgId}</li>;
                                        })}
                                    </ul>
                                </div>
                            </label> */}
                        </form>
                    </div>
                )}
            </div>
            <Modal isOpen={isDeleteKnowledgeModalOpen} onClose={() => setIsDeleteKnowledgeModalOpen(false)}>
                <h2>Confirm Delete</h2>
                <p>Are you sure you want to delete this knowledge item?</p>
                <button onClick={() => {
                    dispatch(deleteKnowledgeRequest(selectedKnowledge.id)); // replace with actual action
                    setSelectedKnowledge(null);
                    setIsDeleteKnowledgeModalOpen(false);
                    dispatch(getKnowledgeRequest()); // replace with actual action
                }}>Yes</button>
                <button onClick={() => setIsDeleteKnowledgeModalOpen(false)}>No</button>
            </Modal>
            <Modal isOpen={isOrganizationsModalOpen} onClose={() => setIsOrganizationsModalOpen(false)}>
                <h2>Select Organizations</h2>
                <div className="organizations-list">
                    {allOrganizations.map(org => (
                        <button
                            key={org._id}
                            className={`organization-item ${selectedOrganizations.includes(org._id) ? 'selected' : ''}`}
                            onClick={() => handleToggleOrganization(org._id)}
                        >
                            {org.profile.name}
                        </button>
                    ))}
                </div>
                <button onClick={() => setIsOrganizationsModalOpen(false)}>Close</button>
            </Modal>
        </div>
    );
};

export default Knowledge;
