
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sendMessage, resetTasks, clearChat, stopChat, setThread, setActivity, setThreadStatus, resetRuns } from './Actions';
import { useAccount, useMsal } from "@azure/msal-react";
import { useNavigate, useParams } from 'react-router-dom';
import AudioCapture from './AudioCapture';
import DisplayRuns from './DisplayRuns';

import '../assets/styles/Assistant.css';
import TypingText from './TypingText';

import AIResponseParser from './AIResponseParser';
import StreamingAIResponse from './StreamingAIResponse';
import TextareaAutosize from 'react-textarea-autosize';

import { AccountCircleOutlined, CameraAltOutlined, CameraswitchOutlined, CancelOutlined, CloudOffOutlined, FlipCameraAndroidOutlined, FlipCameraIosOutlined, NoPhotographyOutlined, PauseCircleOutline, SupervisedUserCircleOutlined, VerifiedUserOutlined } from '@mui/icons-material';


function Loader() {
  return <div className="loader"></div>;
}


function typeEffect(element, delay) {
  let i = 0;
  let message = '...';
  let interval = setInterval(function () {
    if (i < message.length) {
      element.append(message.charAt(i));
      i++;
    } else {
      element.innerHTML = '';
      i = 0;
    }
  }, delay);
}

function Assistant({ modal = false }) {
  const connectionStatus = useSelector(state => state.main.connectionStatus);
  const prevConnectionStatus = useRef();
  const task = useSelector(state => state.main.lastTask);
  const thread_status = useSelector(state => state.main.status);
  const thread = useSelector(state => state.main.thread);
  const running_threads = useSelector(state => state.main.running_threads);
  const tasks = useSelector(state => state.main.tasks);
  const preOptions = useSelector(state => state.main.preOptions);
  const delta = useSelector(state => state.main.delta);
  const dispatch = useDispatch();

  const { accounts, instance, inProgress } = useMsal();
  const account = useAccount(accounts[0] || null);
  const firstName = account?.name.split(' ')[0];
  const [submittedData, setSubmittedData] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [needInput, setNeedInput] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [needSubmit, setNeedSubmit] = useState(false);
  const [messages, setMessages] = useState([]);
  const [title, setTitle] = useState('');
  const [options, setOptions] = useState([]);
  const [aiOptions, setAiOptions] = useState([]);
  const [aiLearning, setAIlearning] = useState(false);
  const [busy, setBusy] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);

  //const [thread, setThread] = useState(null);
  const [actionMessages, setActionMessages] = useState([]);
  const [threadData, setThreadData] = useState(null);
  const [extraMessage, setExtraMessage] = useState('');
  const [evaListening, setEvaListening] = useState(false);
  const [evaActive, setEvaActive] = useState(false);
  const [transcribing, setTranscribing] = useState('');

  // Camera state variables
  const [videoStream, setVideoStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [useFrontCamera, setUseFrontCamera] = useState(true);

  const thinkingRef = useRef(null);
  const [route, setRoute] = useState(null);

  let thread_id;

  // let navigate = useNavigate();
  // let { thread_id } = useParams();

  // const updateThread = (id) => {
  //   //setThread(id);
  //   //if (thread == id) return;
  //   if (id == null) id = "";
  //   //strip thread_ from id 
  //   const data = id.replace('thread_', '');
  //   if (!modal) navigate(`/gesprek/${data}`);
  // }

  useEffect(() => {
    if (thread_id) {
      // add thread_ to id again
      const id = 'thread_' + thread_id;

      if (thread != id) {

        //get from running_threads
        const t = running_threads.find(t => t.thread_id == id);
        if (t) {
          console.log('thread found: ', t);
          setThreadById(t);
        }
      }
      //setThread(id);
      //dispatch(setThread(id));
    }
  }, [thread_id]);


  useEffect(() => {
    if (thread) {
      const current_thread = running_threads.find(t => t.thread_id == thread);
      if (current_thread) {
        console.log('thread found: ', current_thread);
        if (current_thread?.messages != [])
          setThreadById(current_thread, false);
      }
    } else {
      //resetChat();
    }

  }, [thread, running_threads]);



  useEffect(() => {
    //check if a tasks has a message key
    if (tasks?.length > 0) {
      setTitle(tasks.filter(task => task.title).map(task => task.title).join(' '))
      setMessages(prevMessages => [...(prevMessages || []), ...tasks.filter(task => task.message).map(task => ({ type: 'bot', text: task.message }))]);
    }
  }, []);


  useEffect(() => {
    if (thread_status == "running" || thread_status == "started") {
      setBusy(true);
    } else {
      setBusy(false);
    }
  }, [thread_status]);

  useEffect(() => {
    if (prevConnectionStatus.current == "disconnect" && connectionStatus == "connected") {
      console.log(`reconnected`);
      setBusy(false);
    }

    if (tasks?.length == 0 && !prevConnectionStatus.current && connectionStatus == "connected") {
      // if (aiOptions.length === 0) {
      //   generateDefaultOptions();
      // }
    }

    // Store current connection status in ref for next comparison
    prevConnectionStatus.current = connectionStatus;
  }, [connectionStatus]);

  useEffect(() => {
    console.log('Thread updated:', thread);
  }, [thread]);

  useEffect(() => {
    //socket?.on('task', (task) => {
    if (task) {
      console.log('Message from server: ', task);

      if (task.status == "error") {
        let response = { type: 'bot', text: "Sorry, er gaat iets mis.. Probeer het nog eens?" };
        setMessages(prevMessages => [...(prevMessages || []), response]);
      }

      if (task.status == "from_memory") {
        if (task.thread_data?.update_date && new Date(task.thread_data?.update_date) > new Date(new Date().getTime() - 60 * 60 * 1000)) {
          console.log("fresh thread data: ", task);
          //updateThread(task.thread_id);

          setThreadData(task.thread_data);
          dispatch(setThread(task.thread_data.thread_id));
          dispatch(setThreadStatus("active"));
          let response = { type: 'bot', text: task.message };
          setActionMessages([]); //reset
          setMessages(prevMessages => [...(prevMessages || []), response]);
          setOptions([]);
          setBusy(false);

        } else {
          // add to queue!
          console.log('need to add to queue: ', task);
        }
        return;
      }

      //starting different thread
      if (thread && task.thread_id && task.thread_id != thread) {
        console.log('changing thread: ', task.thread_id);
        //updateThread(task.thread_id);
        setMessages([]);
        setOptions([]);
        setTitle('bezig met wijziging van gesprek..');
        setActionMessages([]);
      } else if (thread && task.thread_id && task.thread_id == thread) {
        console.log('continue thread: ', task.thread_id);
      } else if (!thread && task.thread_id) {
        console.log('starting thread: ', task.thread_id);
        //updateThread(task.thread_id);
      }

      if (thread_status == "idle") {
        console.log('assistant idle, skipping messages');
        return;
      }

      //add task to tasks, but in front of the array
      //setTasks(prevTasks => [task, ...prevTasks]);
      console.log('tasks: ', tasks)

      if (task.message) {
        let response = { type: 'bot', text: task.message };
        setActionMessages([]); //reset
        setMessages(prevMessages => [...(prevMessages || []), response]);
        setOptions([]);
        setBusy(false);
        //dispatch(resetRuns());
      }

      if (task.title) {
        console.log('title: ', task.title)
        setTitle(task.title);
      }

      if (task.options) {
        setLoadingOptions(false);
        setOptions(task.options);
      }

      if (task.ailearnings) {
        setAIlearning(JSON.parse(task.ailearnings));
      }

      if (task.route) {
        setRoute(task.route);
      }

      if (task.status == 'completed') {
        setBusy(false);
      }

      if (task.thread_data && task.thread_data.is_active) {
        //const data = JSON.parse(task.thread_data);
        let data = task.thread_data;

        //check if data is not older than 1 hour
        if (data && data.update_date && new Date(data.update_date) > new Date(new Date().getTime() - 60 * 60 * 1000))
          console.log("fresh thread data: ", data);

        if (typeof data === 'string') {
          data = JSON.parse(data);
        }
        setThreadData(data);
      }

      if (task.action == 'loading_options') {
        console.log('loading options..');
        setLoadingOptions(true);
      }

      if (task.status == "routing") {
        console.log('routing..');
        //setActionMessages(prevMessages => [...prevMessages, "doorzetten naar " + task.route]);
        setBusy(true);
      }

      if (task.action == 'action_message') {
        setActionMessages(prevMessages => [...(prevMessages || []), task.action_message]);
      }
    }
    //});

    //socket?.on('ai-options', (message) => {
    if (preOptions && typeof preOptions === 'string') {
      var newOptions = JSON.parse(preOptions);
      setAiOptions(newOptions.options);
    }
    //});

    // return () => {
    //   console.log('assistant unmounting');
    //   socket?.off('task');
    //   socket?.off('ai-options');  // Voeg deze regel toe
    // };

  }, [task, preOptions]); // when socket is updated, the effect runs again

  useEffect(() => {
    return () => {
      console.log('assistant unmounting');
    };
  }, []);


  // // one time only effect here
  // useEffect(() => {
  //   if (options.length === 0) {
  //     //decide count options based on screen size
  //     let count = 4;

  //     if (window.innerHeight < 700) {
  //       count = 4;
  //     } else if (window.innerHeight < 1000) {
  //       count = 4;
  //     } else {
  //       count = 8;
  //     }

  //     //socket?.emit('get_options', count);
  //     dispatch(sendMessage(['get_options', count]));
  //   }
  // }, [connectionStatus]); // Lege array betekent dat het effect alleen bij de eerste render wordt uitgevoerd

  useEffect(() => {
    if (thinkingRef.current) {
      typeEffect(thinkingRef.current, 1000);
    }
  }, [thinkingRef]); // Voeg thinkingRef toe aan de afhankelijkheden van de hook

  useEffect(() => {
    const buttons = document.querySelectorAll('.default-ai-options button');
    buttons.forEach((button, index) => {
      button.style.setProperty('--i', index);
    });
  }, [aiOptions]);

  useEffect(() => {
    if (busy) {
      const buttons = document.querySelectorAll('.default-ai-options button');
      buttons.forEach(button => {
        button.classList.add('removing');
      });
      setTimeout(() => {
        setAiOptions([]);
      }, 500); // Wacht 500ms (de duur van de animatie) voordat je de opties verwijdert
    }
  }, [busy]);

  const fileInput = React.useRef();

  // Modify the handleCameraClick function to start the camera
  const handleCameraClick = async () => {
    setUseFrontCamera(false); // Always start with the back camera
    setIsCameraActive(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { ideal: "environment" } } // Prefer back camera
      });
      setVideoStream(stream);
    } catch (error) {
      console.error("Error accessing camera: ", error);
    }
  };

  const switchCamera = async () => {
    setUseFrontCamera(prevState => !prevState); // Toggle camera
    if (videoStream) {
      videoStream.getTracks().forEach(track => track.stop());
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: useFrontCamera ? "user" : { ideal: "environment" } } // Toggle between front and back
      });
      setVideoStream(stream);
    } catch (error) {
      console.error("Error switching camera: ", error);
    }
  };

  // Add a new function to take the picture
  const takePicture = async () => {
    if (!videoStream) {
      console.error("No video stream available");
      return;
    }

    const video = document.createElement('video');
    video.srcObject = videoStream;
    await video.play();

    // Create a canvas and draw the current frame from the video onto it
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0);

    // Now you have an image as a data URL
    const imageUrl = canvas.toDataURL('image/png');

    // Set the captured image
    setCapturedImage(imageUrl);
  };

  const sendPicture = () => {
    if (!capturedImage) {
      console.error("No captured image available");
      return;
    }

    // Send the image URL and the extra message as a message
    let message = {
      type: 'user',
      message: "image: " + capturedImage + "\n" + extraMessage,
      thread_id: thread,
      route: route
    }

    setBusy(true);
    dispatch(resetRuns());
    dispatch(sendMessage(message));

    // Reset the camera, captured image, and extra message
    setCapturedImage(null);
    setIsCameraActive(false);
    setExtraMessage('');
    if (videoStream) {
      videoStream.getTracks().forEach(track => track.stop());
      setVideoStream(null);
    }
  };

  // Add a new function to stop the camera
  const stopCamera = () => {
    if (videoStream) {
      videoStream.getTracks().forEach(track => track.stop());
      setVideoStream(null);
    }
    setIsCameraActive(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      // The file's content will be available here as a data URL
      const fileUrl = reader.result;

      // Send the file URL as a message
      let message = {
        type: 'user',
        message: "file: " + file.name + " contents: " + fileUrl + "\n User message: " + inputValue,
        thread_id: thread,
        route: route
      }

      setBusy(true);
      dispatch(sendMessage(message));
    };
    reader.readAsDataURL(file);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);

    if (e.target.value.length > 0) {
      setNeedSubmit(true);
    } else {
      setNeedSubmit(false);
    }
  };

  // Add a new method to handle option submission
  const submitSimpleOption = (option) => {
    if (busy) return;
    setBusy(true);
    const newMessage = { type: 'user', text: option };
    setMessages([...messages, newMessage]);
    //setOptions([]);

    let message = {
      type: 'user',
      message: "user selected option: " + option,
      thread_id: thread,
      route: route
    }

    dispatch(sendMessage(JSON(message)));
    //socket.emit('chat_message', JSON.stringify(message));
  }

  // Add new state variables for whether the modals are closing
  const [isCameraClosing, setIsCameraClosing] = useState(false);
  const [isImageClosing, setIsImageClosing] = useState(false);

  // Modify the closeOverlay function to set isCameraClosing or isImageClosing to true
  const closeOverlay = () => {
    if (isCameraActive) {
      setIsCameraClosing(true);
      setTimeout(() => {
        console.log('videoStream: ', videoStream);
        if (videoStream) {
          videoStream.getTracks().forEach(track => track.stop());
          setVideoStream(null);
        }
        setIsCameraActive(false);
        setIsCameraClosing(false);
      }, 500);
    }
    if (capturedImage) {
      setIsImageClosing(true);
      setTimeout(() => {
        setCapturedImage(null);
        setIsImageClosing(false);
      }, 500);
    }
  };

  const [isCameraButtonClicked, setIsCameraButtonClicked] = useState(false);


  const handleDataSubmit = (data) => {
    console.log('Verzamelde Data:', data); // Of doe iets anders met deze data
    setSubmittedData(data);
    setBusy(true);
    setOptions([]);

    // Voeg logica toe om berichten te verwerken
    const newMessage = { type: 'user', text: data };
    setMessages([...(messages || []), newMessage]);
    setInputValue('');

    let message = {
      type: 'user',
      message: data,
      thread_id: thread,
      route: threadData?.route
    }

    dispatch(resetRuns());
    //socket.emit('chat_message', JSON.stringify(message));
    dispatch(sendMessage(message));

  };

  const stopRunningChat = (running_thread) => {
    console.log('stopChat called');
    const thread_id = running_thread.thread_id;
    const current_thread = thread == thread_id;

    if (current_thread) {
      dispatch(stopChat(running_thread.thread_id));
      //updateThread(null);
      setThreadData(null);
      setMessages([]);
      setTitle('');
      setBusy(false);
      setOptions([]);
    }
  }

  const removeChat = (running_thread, confirmed = false) => {
    console.log('removeChat called');
    const thread_id = running_thread.thread_id;
    const current_thread = thread == thread_id;

    if (confirmed) {

      if (current_thread) {
        resetChat();
      } else {
        dispatch(clearChat(running_thread.thread_id))
      }
    }
  }

  const resetChat = () => {
    console.log('resetChat called');

    setMessages([]);
    setTranscribing('');

    //setTasks([]);
    setOptions([]);
    setAIlearning(false);
    setActionMessages([]); //reset
    setTitle('');
    setNeedSubmit(false);
    setBusy(false);
    //updateThread(null);
    setNeedInput(true);
    setRoute(null);
    setThreadData(null);
    dispatch(resetRuns());
    setLoadingOptions(false);
    setEvaListening(false);

    dispatch(clearChat(thread))
    dispatch(resetTasks());
    dispatch(setActivity(null));
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Voeg logica toe om berichten te verwerken
  //   const newMessage = { type: 'user', text: inputValue };
  //   setMessages([...messages, newMessage]);
  //   setInputValue('');
  //   setBusy(true);
  //   setOptions([]);

  //   let message = {
  //     type: 'user',
  //     message: inputValue,
  //     thread_id: thread,
  //     route: route
  //   }

  //   dispatch(resetRuns());
  //   //socket.emit('chat_message', JSON.stringify(message));
  //   dispatch(sendMessage(JSON.stringify(message)));
  // };

  const setThreadById = (new_thread, dispatchAction = true) => {
    //resetChat();
    if (dispatchAction) dispatch(setThread(new_thread.thread_id));
    //setThread(new_thread.thread_id);
    setTitle(new_thread.metadata?.title ? new_thread.metadata?.title : "vorig gesprek");
    setThreadData(new_thread);
    //updateThread(new_thread.thread_id);

    // Map the messages to the new format
    let newMessages = new_thread.messages?.map(msg => {
      return {
        type: msg.sender === 'ai' ? 'bot' : 'user',
        text: msg.message
      };
    });

    setMessages(newMessages);
  }

  const setInterface = (needInput, needOptions) => {
    if (needInput) {
      setNeedInput(true);
    }
    // if(needOptions) {
    //   setLoadingOptions(true);
    // }
  }

  const evaStartListening = () => {
    setTranscribing('');
    setEvaActive(true)
  }

  const evaStopListening = useCallback((transcript, thread_id, route) => {
    setEvaListening(false);
    setEvaActive(false);
    setTranscribing('');

    if (transcript) {
      console.log('transcript: ', transcript);

      if (transcript.replace(/\s/g, '').length) {
        console.log('transcript!');
        setTranscribing(transcript);
        let message = {
          type: 'user',
          message: transcript,
          thread_id: thread_id,  // Use thread directly here
          route: route,
          voice: true,
          override: true
        }

        dispatch(sendMessage(message));
      }
    } else {
      dispatch(sendMessage({ type: 'user', message: 'hi!', thread_id: null, route: null }));
    }
    dispatch(resetRuns());
  }, [thread, threadData, dispatch, setTranscribing]);

  const checkAlive = () => {

    if (thread) {
      resetChat();
      setEvaListening(false);
    } else {
      //dispatch(sendMessage(['check_alive', '']));
      //dispatch(sendMessage(JSON.stringify({ type: 'user', message: 'hi!', thread_id: null, route: null })));

      setEvaListening(true);
    }
  };

  const onAction = (action) => {
    console.log('onAction: ', action);

    if (action == 'camera') {
      handleCameraClick();
    }

  };

  const doLoading = (loading) => {
    setIsLoading(loading);
  };

  // Create a ref for the video element
  const videoRef = useRef(null);

  // Use an effect to assign the video stream to the video element
  useEffect(() => {
    if (videoRef.current && videoStream) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream]);


  // Filter the messages array to only include messages of type 'bot'
  const botMessages = messages ? messages.filter(message => message.type === 'bot') : [];
  const userMessages = messages ? messages.filter(message => message.type === 'user') : [];

  // Check if the bot has started
  const isStarted = messages?.length > 0 || busy || thread_status == "starting" || thread_status == "running";

  // Get the last bot message
  const lastBotMessage = botMessages[botMessages.length - 1];
  const lastUserMessage = userMessages[userMessages.length - 1];

  const filteredThreads = running_threads;//.filter(thread => !thread.metadata.probably_ended && !thread.metadata.solved);

  return (
    <div className="assistant">

      <div className="eva">
        <AudioCapture isListening={evaListening} currentRoute={route} currentThread={thread} onStart={evaStartListening} onEnd={evaStopListening} onTranscribing={(transcript) => { setTranscribing(transcript); }} />

        <div className={`icon-circle`} onClick={checkAlive}>
          <div className="icon">
            <div className={`icon-inner-circle ${(connectionStatus == "disconnected" || connectionStatus == "connect_error") ? "disconnected" : ""} ${thread_status == "idle" ? '' : 'active'}`}>
              {connectionStatus == "connected" && (<div className={`icon-triangle`} />)}
            </div>
            {connectionStatus == "connected" && (
              <div className={`icon-label gradient-text ${thread_status == "running" ? "loading" : ""}`}>
                {thread_status == "running" && !evaActive && ("bezig")}
                {thread_status != "running" && evaActive && ("Hi!")}
                {thread_status != "running" && !evaActive && ("EVA")}
              </div>
            )}

            {connectionStatus == "disconnected" || connectionStatus == "connect_error" && (
              <div className='icon-off'>
                <CloudOffOutlined />
              </div>
            )}
          </div>
        </div>

        <div className="eva-info">
          <span className={`eva-title ${connectionStatus == "connected" ? "gradient-text" : "disconnected"}`}>
            {connectionStatus == "connected" && thread_status == "idle" && (
              // <TypingText speed={100} html={route ? (route == "default" ? "ASSISTENT" : route) : "ASSISTENT"} />
              <TypingText speed={100} html={"Hi " + firstName} />
            )}
            {(connectionStatus == "disconnected" || connectionStatus == "connect_error") && (
              <TypingText speed={100} html={"EVA OFFLINE"} />
            )}
          </span>
          {(!isLoading && actionMessages?.length == 0 && connectionStatus == "connected" && thread_status == "idle" && !threadData?.metadata?.ai_title) && (
            <span className="eva-subtitle">
              {'Waarmee kan ik je helpen?'}
            </span>
          )}
          {(!isLoading && actionMessages?.length == 0 && connectionStatus == "connected" && thread_status == "active" && threadData?.metadata?.ai_title) && (
            <span className="eva-subtitle">
              {threadData.metadata?.ai_title}
            </span>
          )}
          {(!isLoading && actionMessages?.length > 0 && connectionStatus == "connected" && thread_status == "running" || thread_status == "active") && (
            <span className="eva-subtitle">
              <TypingText speed={100} html={actionMessages[0]} />
            </span>
          )}

          {isLoading && (<span className="eva-subtitle">Even geduld!</span>)}
        </div>
      </div>

      {/* run = {
            "run_id": str(uuid.uuid4()),  # create new run
            "flow_id": flow["flow_id"],
            "thread_id": thread_id,
            "payload": payload,
            "upn": upn,
            "run_status": "queued",  # queued, running, completed, error
            "status": "queued",  # ai status output to use
            "result": None,
            "timeout": timeout_seconds,
            "error": None,
            "created_at": datetime.now().isoformat(),
            "updated_at": datetime.now().isoformat(),
        } */}

      {transcribing != '' && (
        <div className="card eva-transcribing">
          <AccountCircleOutlined />
          <span className="eva-transcribing-text">{transcribing}</span>
        </div>
      )}


      <DisplayRuns />

      {((!isCameraActive && !isCameraClosing) && !capturedImage) && (
        <StreamingAIResponse
          delta={delta}
          onSubmit={handleDataSubmit}
          onStop={resetChat}
          onAction={onAction}
          doLoading={doLoading}
        />
      )}

      {((!isCameraActive && !isCameraClosing) && !capturedImage) && (
        <div className="assistant-render">
          <AIResponseParser
            aiResponse={lastBotMessage?.text}
            options={options}
            loading={busy}
            typing={true}
            onAction={onAction}
            onSubmit={handleDataSubmit}
            onStop={resetChat}
            onChange={setInterface}
            submitSimpleOption={submitSimpleOption}
          />
        </div>
      )}


      {((isCameraActive || isCameraClosing) && !capturedImage) && (
        <React.Fragment>
          <div className="assistant-camera">
            {/* <div className="ai-close" onClick={() => { setCapturedImage(null); stopCamera(); }}>x</div> */}
            <video ref={videoRef} autoPlay></video>
            <div className="assistant-response-buttons">
              <button onClick={takePicture}><CameraAltOutlined alt="Neem foto" /></button>
              <button onClick={switchCamera}><FlipCameraIosOutlined /></button>
            </div>
          </div>
          <button onClick={stopCamera}>Stoppen</button>
        </React.Fragment>
      )}


      {(capturedImage || isImageClosing) && (
        <React.Fragment>
          <div className="assistant-camera">
            {/* <div className="ai-close" onClick={() => { setCapturedImage(null); stopCamera(); }}>x</div> */}
            <img src={capturedImage} alt="Captured" />
            <div className="assistant-response-buttons">
              <TextareaAutosize
                value={extraMessage}
                onChange={e => setExtraMessage(e.target.value)}
                placeholder="Voeg nog een bericht toe (optioneel)"
                onKeyDown={(event) => {
                  if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    sendPicture();
                  }
                }}
              />
              <button onClick={sendPicture}>Verstuur</button>
              <button onClick={() => { setCapturedImage(null); handleCameraClick(); }}>Opnieuw</button>
            </div>
          </div>
          <button onClick={() => { setCapturedImage(null); stopCamera() }}>Stoppen</button>
        </React.Fragment>
      )}


      {(isStarted && !busy && (!isCameraActive && !isCameraClosing && !capturedImage)) && (
        <button onClick={resetChat}>Stoppen</button>
      )}

    </div>
  );
}

export default Assistant;
