import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getRuns } from '../../../sagas/flows/actions';

const FlowRunsOverview = () => {
    const dispatch = useDispatch();
    const runs = useSelector(state => state.flows.runs);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        dispatch(getRuns(filters));
    }, [dispatch, filters]);

    const handleFilterChange = (key, value) => {
        setFilters({ ...filters, [key]: value });
    };

    return (
        <div className="flow-runs-overview">
            <h2>Flow Runs</h2>
            <div className="filters">
                <input 
                    type="text" 
                    placeholder="Filter by flow name"
                    onChange={(e) => handleFilterChange('flow_name', e.target.value)}
                />
                <select onChange={(e) => handleFilterChange('status', e.target.value)}>
                    <option value="">All statuses</option>
                    <option value="running">Running</option>
                    <option value="completed">Completed</option>
                    <option value="failed">Failed</option>
                </select>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Run ID</th>
                        <th>Flow Name</th>
                        <th>Status</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                    </tr>
                </thead>
                <tbody>
                    {runs.map(run => (
                        <tr key={run._id}>
                            <td>{run._id}</td>
                            <td>{run.flow_name}</td>
                            <td>{run.status}</td>
                            <td>{new Date(run.start_time).toLocaleString()}</td>
                            <td>{run.end_time ? new Date(run.end_time).toLocaleString() : 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default FlowRunsOverview;
