import { call, put, takeEvery, take, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { GET_TASKS_REQUEST, getTasksSuccess, GET_TASK, getTasksFailure, GET_TASKS_SUCCESS, GET_TASKS_FAILURE } from './actions';
import { connectSocket, getSocket } from '../../components/Socket';
import axios from 'axios';

function createSocketChannel(socket) {
  return eventChannel(emit => {
    const handler = (data) => {
      emit(data);
    };
    socket.on('tasks', handler);
    return () => {
      socket.off('tasks', handler);
    };
  });
}

function* fetchTasksSaga() {

  const accessToken = yield select((state) => state.main.accessToken);


  const tasks = yield axios.get('/api/tasks', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }
  )

  if (tasks.status === 200) {
    yield put(getTasksSuccess(tasks.data));
  } else {
    yield put(getTasksFailure(tasks.statusText));
  }
}

// Updated sagas using axios directly
function* getTaskSaga(action) {
  yield put({ type: GET_TASKS_REQUEST });

  try {
    const accessToken = yield select((state) => state.main.accessToken);
    const response = yield call(axios.get, `/api/tasks/${action.payload}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const tasks = yield select((state) => state.tasks.tasks);

    if (response.status !== 200) {
      yield put({ type: GET_TASKS_FAILURE, error: response.statusText });
    }

    const task = response.data;

    // Update the task in the tasks array
    const updatedTasks = tasks.map((t) => {
      if (t.task_id === task.task_id) {
        return task;
      }
      return t;
    });


    yield put({ type: GET_TASKS_SUCCESS, payload: updatedTasks });
  } catch (error) {
    yield put({ type: GET_TASKS_FAILURE, error });
  }
}

export function* watchFetchTasksSaga() {
  yield takeEvery(GET_TASKS_REQUEST, fetchTasksSaga);
  yield takeEvery(GET_TASK, getTaskSaga);
}