import { MPEGDecoder } from 'mpg123-decoder';

let audioContext;
let sourceNode;
let rawAudioData = [];
let decoder;
let isPlaying = false;
let isActive = false;
let isPaused = false;
let chunkMap = new Map();
let expectedSequenceNumber = 0;
let currentSampleRate = 24000; // Default sample rate

export function getAudioContext() {
  if (!audioContext) {
    audioContext = new (window.AudioContext || window.webkitAudioContext)({
      latencyHint: 'interactive',
      sampleRate: currentSampleRate
    });
  }
  return audioContext;
}

export async function initAudioContext(sampleRate = 24000) {
  if (!audioContext || audioContext.sampleRate !== sampleRate) {
    if (audioContext) {
      await audioContext.close();
    }
    audioContext = new (window.AudioContext || window.webkitAudioContext)({
      latencyHint: 'interactive',
      sampleRate: sampleRate
    });
    currentSampleRate = sampleRate;
    decoder = new MPEGDecoder();
    await decoder.ready;
  }
}

function base64ToUint8Array(base64) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}


export async function processAudioChunk(audioData, sequenceNumber, sample_rate, isBase64 = false) {
  if (sample_rate !== currentSampleRate) {
    await initAudioContext(sample_rate);
  }

  try {
    let uint8Array;
    if (isBase64) {
      uint8Array = base64ToUint8Array(audioData);
    } else {
      uint8Array = new Uint8Array(audioData);
    }
    const decodedData = decoder.decode(uint8Array);

    if (decodedData && decodedData.channelData.length > 0) {
      const leftChannel = decodedData.channelData[0];
      const rightChannel = decodedData.channelData[1] || leftChannel; // Fallback to mono if no right channel
      const interleavedData = new Float32Array(leftChannel.length * 2);

      for (let i = 0; i < leftChannel.length; i++) {
        interleavedData[i * 2] = leftChannel[i];
        interleavedData[i * 2 + 1] = rightChannel[i];
      }

      chunkMap.set(sequenceNumber, interleavedData);

      // Process chunks in order
      while (chunkMap.has(expectedSequenceNumber)) {
        rawAudioData.push(chunkMap.get(expectedSequenceNumber));
        chunkMap.delete(expectedSequenceNumber);
        expectedSequenceNumber++;
      }

      if (!isPlaying && !isPaused) {
        startPlayback();
      }
    }
  } catch (error) {
    console.error('Error decoding MP3 chunk:', error);
  }
}

function startPlayback() {
  isPlaying = true;
  isActive = true;
  playNextChunk();
}

function playNextChunk() {
  if (rawAudioData.length > 0) {
    const chunk = rawAudioData.shift();

    if (chunk.length === 0) {
      console.error('Empty chunk received');
      playNextChunk();
      return;
    }

    const buffer = audioContext.createBuffer(2, chunk.length / 2, currentSampleRate);
    const leftChannel = buffer.getChannelData(0);
    const rightChannel = buffer.getChannelData(1);

    for (let i = 0; i < chunk.length / 2; i++) {
      leftChannel[i] = chunk[i * 2];
      rightChannel[i] = chunk[i * 2 + 1];
    }

    sourceNode = audioContext.createBufferSource();
    sourceNode.buffer = buffer;
    sourceNode.connect(audioContext.destination);
    sourceNode.onended = () => {
      sourceNode = null;
      if (rawAudioData.length > 0 && !isPaused) {
        playNextChunk();
      } else {
        isPlaying = false;
      }
    };
    sourceNode.start();
  } else {
    isPlaying = false;
    isActive = false;
  }
}

export function pausePlayback() {
  console.log('Pausing playback');
  if (sourceNode) {
    sourceNode.stop();
    isPaused = true;
    isPlaying = false;
  }
}

export function resumePlayback() {
  console.log('Resuming playback');
  if (isPaused) {
    isPaused = false;
    startPlayback();
  }
}

export function isPlayingAudio() {
  return isPlaying;
}

export function isActiveAudio() {
  return isActive;
}

export function resetAudioPlayback() {
  console.log('Resetting audio playback');
  if (sourceNode) {
    sourceNode.stop();
  }
  if (audioContext) {
    audioContext.close();
  }
  audioContext = null;
  sourceNode = null;
  rawAudioData = [];
  isPlaying = false;
  isPaused = false;
  chunkMap.clear();
  expectedSequenceNumber = 0;
  currentSampleRate = 24000; // Reset to default sample rate
}