const DailyWidget = ({ title, data }) => (
    <div className="bg-white shadow rounded-lg p-4">
        <h2 className="text-lg font-semibold mb-2">{title}</h2>
        <div className="flex justify-between items-center mb-4">
            <h3 className="font-semibold text-xl">{data.focus}</h3>
            <div className="text-right">
                <p>{data.weather.condition}</p>
                <p className="text-2xl">{data.weather.temperature}</p>
            </div>
        </div>
        <ul className="list-disc pl-5 mt-2 mb-4">
            {data.tasks.map((task, index) => (
                <li key={index}>{task}</li>
            ))}
        </ul>
        <p className="italic mb-4">"{data.quote}"</p>
        {data.appointment && (
            <div className="bg-blue-100 p-2 rounded">
                <p className="font-semibold">Upcoming: {data.appointment.title}</p>
                <p>Time: {data.appointment.time}</p>
            </div>
        )}
    </div>
);

export default DailyWidget;