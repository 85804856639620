import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import AppWidget from './widgets/AppWidget';
import SmartWidget from './widgets/SmartWidget';
import ActionWidget from './widgets/ActionWidget';
import InfoWidget from './widgets/InfoWidget';
import BannerWidget from './widgets/BannerWidget';
import DailyWidget from './widgets/DailyWidget';

import Assistant from './Assistant';
import Activities from './Activities';

import { Player } from '@lordicon/react';
import '../assets/styles/Dashboard.css';

const view = require('../assets/system-regular-24-view-1-hover-view-1.json');
const news = require('../assets/system-regular-76-newspaper-hover-newspaper.json');

const DynamicDashboard = () => {
    const [appWidgets, setAppWidgets] = useState([]);
    const [personalizedWidgets, setPersonalizedWidgets] = useState([]);
    const [smartRecommendations, setSmartRecommendations] = useState(null);
    const [standardWidgets, setStandardWidgets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const thread_status = useSelector(state => state.main.status);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const accessToken = useSelector(state => state.main.accessToken);
    const ViewPlayerRef = useRef(null);
    const NewsPlayerRef = useRef(null);

    useEffect(() => {
        if (!accessToken) return;
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        const fetchDashboard = async () => {
            try {
                setLoading(true);
                const response = await axios.get('/api/dashboard/widgets', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (response.status !== 200) {
                    //throw new Error('Failed to load dashboard. Please try again later.');
                    console.log('Failed to load dashboard. Please try again later.');
                    return;
                }
                const { app_widgets, personalized_widgets, smart_recommendations, standard_widgets } = response.data;

                setAppWidgets(app_widgets);
                setPersonalizedWidgets(personalized_widgets);
                setSmartRecommendations(smart_recommendations);
                setStandardWidgets(standard_widgets);
                setLoading(false);
            } catch (err) {
                setError('Failed to load dashboard. Please try again later.');
                setLoading(false);
            }
        };

        fetchDashboard();

        return () => window.removeEventListener('resize', handleResize);
    }, [accessToken]);

    useEffect(() => {
        if (loading && ViewPlayerRef.current) {
            ViewPlayerRef.current.playFromBeginning();
        }

        if (loading && NewsPlayerRef.current) {
            NewsPlayerRef.current.playFromBeginning();
        }

    }, [loading, ViewPlayerRef, NewsPlayerRef]);

    const getCardComponent = (type) => {
        switch (type) {
            case 'InfoWidget':
                return InfoWidget;
            case 'ActionWidget':
                return ActionWidget;
            case 'AppWidget':
                return AppWidget;
            case 'SmartRecommendationsWidget':
                return SmartWidget;
            case 'BannerWidget':
                return BannerWidget;
            case 'DailyWidget':
                return DailyWidget;
            // Add other cases as needed
            default:
                return null;
        }
    };

    // if (loading) {
    //     return <div className="flex justify-center items-center h-screen">Loading dashboard...</div>;
    // }

    if (error) {
        return <div className="text-red-500 text-center">{error}</div>;
    }

    return (
        <div className="dashboard">
            {((isMobile && thread_status === "idle" && !loading) || (!isMobile && !loading)) && (
                <div className="left-column">
                    {personalizedWidgets?.filter(w => w.type == "InfoWidget").map((widget, index) => {
                        const CardComponent = getCardComponent(widget.type);
                        if (!CardComponent) return null;
                        return (
                            <CardComponent
                                key={index}
                                title={widget.title}
                                priority={widget.priority}
                                data={widget.data}
                            />
                        );
                    })}
                    {/* {smartRecommendations && (
                        <SmartWidget
                            title={smartRecommendations.title}
                            priority={smartRecommendations.priority}
                            data={smartRecommendations.data}
                        />
                    )}
                    {standardWidgets?.map((widget, index) => {
                        const CardComponent = getCardComponent(widget.type);
                        if (!CardComponent) return null;
                        return (
                            <CardComponent
                                key={index}
                                title={widget.title}
                                priority={widget.priority}
                                data={widget.data}
                            />
                        );
                    })} */}

                    <div className="bottom">
                        <div className="bottom-header">Apps</div>
                        <div className="app-widgets">
                            {appWidgets?.map((widget, index) => {
                                const CardComponent = getCardComponent(widget.type);
                                if (!CardComponent) return null;
                                return (
                                    <CardComponent
                                        key={index}
                                        title={widget.title}
                                        priority={widget.priority}
                                        data={widget.data}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}

            {loading && (
                <div className="left-column">
                    <div className="loading-dashboard">
                        <span>bezig met maken van je dashboard..</span>
                        <Player
                            ref={ViewPlayerRef}
                            icon={view}
                            size={64}
                            onComplete={() => ViewPlayerRef.current?.playFromBeginning()}
                            colorize='#DD91D5'
                        />
                    </div>
                </div>
            )}

            <div className="center-column">
                <Assistant />
            </div>
            {((isMobile && thread_status === "idle" && !loading) || (!isMobile && !loading)) && (
                <div className="right-column">
                    <Activities card={true} />

                    <div className='bottom'>
                        <div className='bottom-header'>Acties</div>
                        <div className='action-widget'>
                            {personalizedWidgets?.filter(w => w.type == "ActionWidget").map((widget, index) => {
                                const CardComponent = getCardComponent(widget.type);
                                if (!CardComponent) return null;
                                return (
                                    <CardComponent
                                        key={index}
                                        title={widget.title}
                                        priority={widget.priority}
                                        data={widget.data}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}

            {loading && (
                <div className="right-column">
                    <div className="loading-dashboard">
                        <span>bezig met maken van je dashboard..</span>
                        <Player
                            ref={NewsPlayerRef}
                            icon={news}
                            size={64}
                            onComplete={() => NewsPlayerRef.current?.playFromBeginning()}
                            colorize='#DD91D5'
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default DynamicDashboard;