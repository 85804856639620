import React from 'react';
import { useDispatch } from 'react-redux';
import { sendMessage } from '../Actions';

const SmartRecommendationsWidget = ({ title, priority, data }) => {
    const dispatch = useDispatch();

    const handleAction = (action) => {
        switch (action.action_type) {
            case 'chat':
                dispatch(sendMessage({
                    type: "user",
                    message: action.action_payload.message,
                    thread_id: null,
                    route: action.action_payload.route
                }));
                break;
            case 'open':
                window.open(action.action_payload.url, '_blank');
                break;
            case 'approve':
                console.log('Approving task:', action.action_payload.task_id);
                // Implement approve action
                break;
            default:
                console.warn('Unknown action type:', action.action_type);
        }
    };

    return (
        <div className="bg-white shadow rounded-lg p-4">
            <h2 className="text-lg font-semibold mb-2">{title}</h2>
            <ul className="space-y-2">
                {data.map((recommendation, index) => (
                    <li key={index} className="border-b pb-2 last:border-b-0">
                        <h3 className="font-semibold">{recommendation.title}</h3>
                        <p className="text-sm text-gray-600">{recommendation.description}</p>
                        <button
                            onClick={() => handleAction(recommendation)}
                            className="text-blue-500 hover:underline mt-1"
                        >
                            {recommendation.action}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SmartRecommendationsWidget;