export const GET_AGENTS_REQUEST = 'GET_AGENTS_REQUEST';
export const GET_AGENTS_SUCCESS = 'GET_AGENTS_SUCCESS';
export const GET_AGENTS_FAILURE = 'GET_AGENTS_FAILURE';

export const SAVE_AGENTS_REQUEST = 'SAVE_AGENTS_REQUEST';
export const SAVE_AGENTS_SUCCESS = 'SAVE_AGENTS_SUCCESS';
export const SAVE_AGENTS_FAILURE = 'SAVE_AGENTS_FAILURE';

export const getAgentsRequest = () => ({ type: GET_AGENTS_REQUEST });
export const getAgentsSuccess = agents => ({ type: GET_AGENTS_SUCCESS, payload: agents });
export const getAgentsFailure = error => ({ type: GET_AGENTS_FAILURE, payload: error });

export const saveAgentsRequest = agents => ({ type: SAVE_AGENTS_REQUEST, payload: agents });
export const saveAgentsSuccess = () => ({ type: SAVE_AGENTS_SUCCESS });
export const saveAgentsFailure = error => ({ type: SAVE_AGENTS_FAILURE, payload: error });
