export const GET_ACTIVITIES_REQUEST = 'GET_ACTIVITIES_REQUEST';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_FAILURE = 'GET_ACTIVITIES_FAILURE';
export const SAVE_ACTIVITY = 'SAVE_ACTIVITY';
export const GET_ACTIVITY = 'GET_ACTIVITY';

export const getActivitiesRequest = () => ({ type: GET_ACTIVITIES_REQUEST });
export const getActivitiesSuccess = activities => ({ type: GET_ACTIVITIES_SUCCESS, payload: activities });
export const getActivitiesFailure = error => ({ type: GET_ACTIVITIES_FAILURE, payload: error });

export const saveActivity = (activity) => ({
    type: SAVE_ACTIVITY,
    payload: activity,
});

export const getActivity = (activity_id) => ({ type: GET_ACTIVITY, payload: activity_id });